









































































































import FilterView from '@simpl/core/components/FilterView.vue'
import StringToColor from '@simpl/core/mixins/utils/StringToColor'
import UserSettings from '@simpl/core/mixins/utils/UserSettings'
import { getTextForTemporaryUserLanguage, stringToDate } from '@simpl/core/utils'

import LanguageTags from '@simpl/core/mixins/apollo/LanguageTags'
import {
  DELETE_TRACKING_VISUALIZATION,
  TRACKING_VISUALIZATIONS
} from '../graphql'
import {
  Query, Run,
  TrackingVisualization
} from '@simpl/core/types/graphql'
import mixins from 'vue-typed-mixins'
import getName from '../utils/get-name'
import { TranslateResult } from 'vue-i18n'

type TrackingVisualizationWithRemappedTags = TrackingVisualization & {
  name: string,
  tag: TranslateResult | string,
  // eslint-disable-next-line camelcase
  created_at: string,
  // eslint-disable-next-line camelcase
  updated_at: string
}

export default mixins(
  StringToColor,
  LanguageTags,
  UserSettings('trackingVisualizationsView', [
    'itemsPerPage'
  ])).extend({
  name: 'TrackingVisualizationsView',

  components: {
    FilterView
  },

  props: {
    id: [Number, String],
    allocatedTrackingVisualizationIds: Array as () => string[],
    evaluationId: [Number, String],
    run: Object as () => Run,
    waveList: Array
  },

  data: () => ({
    visualizations: [] as any[],

    selectedTrackingVisualizationIds: [] as string[],
    currentItems: [] as TrackingVisualization[],
    isInternalSelected: false,

    /** Search and filter */
    searchValue: '',
    count: 1,
    page: 1,
    itemsPerPage: 10,

    loading: 0
  }),

  watch: {
    allocatedTrackingVisualizationIds (v) {
      this.refetch()
    },
    searchValue (v:string) {
      this.page = 1
    },
    selectedTrackingVisualizationIds () {
      this.isSelected = this.testIfAllIdsAreSelected()
    },
    itemsPerPage () {
      this.isSelected = this.testIfAllIdsAreSelected()
    },
    page () {
      this.isSelected = this.testIfAllIdsAreSelected()
    }
  },

  computed: {
    presentedData (): TrackingVisualization[] {
      const remappedTrackingVisualizations = this.visualizations.slice().map(this.remapEntry)
      const filteredTrackingVisualizations = remappedTrackingVisualizations.filter((trackingVisualization: any) => {
        const firstItem = trackingVisualization.items[0]
        return this.searchCaseInsensitive(trackingVisualization.name) ||
          this.searchCaseInsensitive(trackingVisualization.id) ||
          this.searchCaseInsensitive(trackingVisualization.type) ||
          this.searchCaseInsensitive(trackingVisualization.tag)
      })
      return filteredTrackingVisualizations
    },
    cannotAllocate (): boolean {
      return this.selectedTrackingVisualizationIds.length === 0 || this.loading > 0
    },
    headers (): Record<string, any> {
      return [{
        text: '',
        value: 'selected',
        sortable: false
      }, {
        text: this.$t('core.global.name'),
        value: 'name'
      }, {
        text: this.$t('core.global.type'),
        value: 'type'
      }, {
        text: this.$t('core.global.id'),
        value: 'id'
      }, {
        text: this.$t('trackingEvaluation.headers.items'),
        value: 'tag'
      }, {
        text: this.$t('core.global.createdAt'),
        value: 'created_at'
      }, {
        text: this.$t('core.global.updatedAt'),
        value: 'updated_at'
      }, {
        text: '',
        value: 'actions',
        class: 'separate-left',
        width: 50,
        sortable: false
      }]
    },
    queryVariables () {
      return {
        module_ids: this.run.modules.map(module => module.id),
        filter: {},
        first: 9999
      }
    },
    isSelected: {
      get (): boolean {
        return this.isInternalSelected
      },
      set (v: boolean) {
        this.isInternalSelected = v
        return v
      }
    },
    allocatedAndSelectedTrackingVisualizationIds (): string[] {
      return [
        ...this.allocatedTrackingVisualizationIds,
        ...this.selectedTrackingVisualizationIds
      ]
    }
  },

  methods: {
    remapEntry (entry: TrackingVisualization): TrackingVisualizationWithRemappedTags {
      const nameCandidates = [
        entry.properties?.title,
        getTextForTemporaryUserLanguage(entry),
        getTextForTemporaryUserLanguage(entry.items[0]?.content_headline, 'text'),
        entry.identifier
      ]
      const name = getName(nameCandidates)

      const tag = this.getTagOfFirstItem(entry)
      const tagText = tag
        ? this.$t(`cms.names.${tag}`)
        : this.$t('core.global.others')

      return {
        ...entry,
        name: name,
        type: this.$t(`trackingVisualization.visualizationTypes.${entry.type}`) as string,
        tag: tagText,
        created_at: entry.created_at ? stringToDate(entry.created_at) : entry.created_at,
        updated_at: entry.updated_at ? stringToDate(entry.updated_at) : entry.updated_at
      }
    },
    getTagOfFirstItem (item: TrackingVisualization): string | null {
      return item?.items[0]?.content?.data?.tag
    },

    testIfAllIdsAreSelected (): boolean {
      let areAllSelected = true
      const idsOnPage = this.currentItems.map((item: TrackingVisualization) => item.id)
      for (const id of idsOnPage) {
        if (this.allocatedAndSelectedTrackingVisualizationIds.indexOf(id) === -1) {
          areAllSelected = false
        }
      }
      return areAllSelected
    },

    setCurrentItems (items: TrackingVisualization[]) {
      this.currentItems = items
      this.isSelected = this.testIfAllIdsAreSelected()
    },

    async applyAllocations () {
      this.$emit('allocate', this.allocatedAndSelectedTrackingVisualizationIds)
    },
    cancelAllocations () {
      this.selectedTrackingVisualizationIds = []
      this.$emit('canceled')
    },

    getStatus (item: TrackingVisualization): string {
      const allocatedFound = this.allocatedTrackingVisualizationIds.filter(
        id => id === item.id)
      if (allocatedFound.length > 0) {
        return 'selected'
      } else {
        return 'none'
      }
    },
    selectAll (v: boolean) {
      const idsOnPage = this.currentItems.map((item: TrackingVisualization) => item.id)

      if (v) {
        for (const id of idsOnPage) {
          if (this.allocatedAndSelectedTrackingVisualizationIds.indexOf(id) === -1) {
            this.selectedTrackingVisualizationIds.push(id)
          }
        }
      } else {
        for (const id of idsOnPage) {
          const index = this.selectedTrackingVisualizationIds.indexOf(id)
          if (index > -1) {
            this.selectedTrackingVisualizationIds.splice(index, 1)
          }
        }
      }
    },
    searchCaseInsensitive (testString: string): boolean {
      return testString.toLowerCase().includes(this.searchValue.toLowerCase())
    },
    async remove (id: string) {
      const trackingVisualization = this.presentedData.find(data => data.id === id)
      this.$emit('dialog-opened')
      const answer = await this.$confirm({
        color: 'error',
        message: this.$t('core.message.deleteConfirm', [trackingVisualization?.name]),
        buttons: [{
          text: this.$t('core.action.cancel'),
          type: 'outlined',
          answer: false
        }, {
          text: this.$t('core.action.delete'),
          color: 'error',
          answer: true
        }]
      })
      this.$emit('dialog-closed')
      if (!answer) return

      this.loading += 1
      await this.$apollo.mutate({
        mutation: DELETE_TRACKING_VISUALIZATION,
        variables: {
          id
        }
      })

      this.loading -= 1
      this.$emit('removed')
      await this.refetch()
    },
    async refetch () {
      await this.$apollo.queries.visualizations.refetch()
    }
  },

  apollo: {
    visualizations: {
      query: TRACKING_VISUALIZATIONS,

      fetchPolicy: 'cache-and-network',

      variables (): Record<string, any> {
        return this.queryVariables
      },

      update (result: Query): TrackingVisualization[] {
        return result!.trackingVisualizations!.data
      },

      error (error: Error): void {
        console.error(error)
      },

      loadingKey: 'loading'
    }
  }
})
