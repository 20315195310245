import { findParentWithStyle } from './helper'

export function applyAlignment (document: Document, range: Range, el: HTMLElement, root: HTMLElement, formatDetails: any) {
  if (!range.toString().length) {
    const rowParent = el.style?.[formatDetails.styleProperty]
      ? el
      : (el.firstChild as any).style?.[formatDetails.styleProperty]
          ? el.firstChild
          : findParentWithStyle(el, formatDetails.styleProperty)

    if (rowParent) {
      changeAlignment(rowParent, formatDetails.value)
    } else {
      const range = document.createRange()
      range.selectNodeContents(root)

      applyAlignmentToRange(document, range, root, formatDetails.value)
    }
  } else {
    applyAlignmentToRange(document, range, el, formatDetails.value)
  }
}

function applyAlignmentToRange (document: Document, range: Range, el: HTMLElement, value: string) {
  const newElement = document.createElement('div') as any
  [...el.childNodes].forEach((node: Node) => {
    newElement.append(node)
  })
  newElement.style.textAlign = value
  range.deleteContents()
  range.insertNode(newElement)
}

export function changeAlignment (el: any, value: string) {
  el.style.textAlign = value
}
