var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"tvt-8-sheet full-height flex-grow-1"},[_c('v-card-text',[_c('SearchFilterBar',{staticClass:"flex-grow-0 mb-6 mr-1",attrs:{"search-value":_vm.searchValue},on:{"update:searchValue":function($event){_vm.searchValue=$event},"update:search-value":function($event){_vm.searchValue=$event}}}),_c('v-data-table',{attrs:{"headers":_vm.computedHeaders,"items":_vm.presentedData,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"footer-props":{
        'items-per-page-options': [1, 2, 3, 4, 5]
      }},on:{"update:page":function($event){_vm.page=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"current-items":_vm.setCurrentItems},scopedSlots:_vm._u([{key:"header.selected",fn:function(){return [_c('v-checkbox',{directives:[{name:"show",rawName:"v-show",value:(!_vm.TEMPORARILY_DISABLED),expression:"!TEMPORARILY_DISABLED"}],on:{"change":_vm.selectAll},model:{value:(_vm.isSelected),callback:function ($$v) {_vm.isSelected=$$v},expression:"isSelected"}})]},proxy:true},{key:"item.selected",fn:function(ref){
      var item = ref.item;
return [_c('v-radio-group',{model:{value:(_vm.selectedModule),callback:function ($$v) {_vm.selectedModule=$$v},expression:"selectedModule"}},[_c('v-radio',{attrs:{"value":item.id}})],1)]}},{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row justify-space-between align-center"},[_c('div',{staticStyle:{"overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap"}},[_c('span',[_vm._v(_vm._s(item.name))]),(_vm.isFallbackLanguage(item, _vm.temporaryLanguage))?_c('v-chip',{staticClass:"ma-2",attrs:{"x-small":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-translate ")]),_vm._v(" "+_vm._s(_vm.isFallbackLanguage(item, _vm.temporaryLanguage))+" ")],1):_vm._e()],1)])]}},{key:"item.updated_at",fn:function(ref){
      var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.updated_at ? _vm.$d(item.updated_at, 'short') : ''))])]}},{key:"item.languages",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{staticClass:"white--text",attrs:{"color":_vm.stringToColor(item.languagecode)}},[_vm._v(" "+_vm._s(item.languagecode)+" ")]),_vm._l((_vm.getModuleTags(item, 'language')),function(language,index){return (index < 2 && language.identifier !== item.languagecode)?_c('v-chip',{key:index,staticClass:"white--text",style:(index > 0 ? 'margin-left: -10px;' : ''),attrs:{"color":language ? _vm.stringToColor(language.identifier) : ''}},[_vm._v(" "+_vm._s(language.identifier)+" ")]):_vm._e()}),(_vm.getModuleTags(item, 'language').length > 2)?_c('v-chip',{staticStyle:{"margin-left":"-10px"},attrs:{"small":""}},[_vm._v(" +"+_vm._s(_vm.getModuleTags(item, 'language').length - 2)+" ")]):_vm._e()]}},{key:"item.type",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{style:({ color: _vm.getModuleFormat(item) && _vm.getModuleFormat(item).textColor || '#fff' }),attrs:{"outlined":_vm.$vuetify.breakpoint.xsOnly,"small":_vm.$vuetify.breakpoint.xsOnly,"color":_vm.getModuleFormat(item) && _vm.getModuleFormat(item).color}},[_c('v-icon',{staticClass:"ml-0",attrs:{"small":"","left":""}},[_vm._v(" "+_vm._s(_vm.getModuleFormat(item) && _vm.getModuleFormat(item).icon)+" ")]),_c('span',[_vm._v(_vm._s(_vm.$t(("core.contentTypes." + (item.type)))))])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }