import getPropertyValue from './get-property-value'

export function sortByOrderedArray (
  a: Record<string, any>,
  b: Record<string, any>,
  key: string,
  order: String[]
) {
  const indexA = order.indexOf(a[key]) || 0
  const indexB = order.indexOf(b[key]) || 0

  return indexA < indexB
    ? 1
    : indexA > indexB
      ? -1
      : 0
}

export type SortKey = {
  name: string,
  order: 'DESC' | 'ASC'
}

export function sortByNestedKeys (
  a: Record<string, any>,
  b: Record<string, any>,
  keys: SortKey[]
): number {
  if (keys.length === 0) {
    return 0
  }

  const key = keys.shift()
  const indexA = getPropertyValue(a, key!.name) || 0
  const indexB = getPropertyValue(b, key!.name) || 0
  const factor = key!.order === 'DESC' ? 1 : -1

  return indexA < indexB
    ? factor
    : indexA > indexB
      ? -factor
      : sortByNestedKeys(a, b, keys)
}
