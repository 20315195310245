








































































































































































import Vue from 'vue'
import { getTextForLanguage, getTextForTemporaryUserLanguage } from '@simpl/core/utils'
import { Module, Query, Run, RunDashboard, RunRestricted, Session } from '@simpl/core/types/graphql'
import { ISSUE_CERTIFICATE, RUN_DASHBOARD, RUN_DASHBOARD_SESSION } from '../graphql'
import CourseModuleTile from '../components/CourseModuleTile.vue'
import CourseHeadline from '../components/CourseHeadline.vue'
import { getKeyvisual } from '../../utils/keyvisual'
import mixins from 'vue-typed-mixins'
import IssueCertificate from '../mixins/IssueCertificate'

export default mixins(IssueCertificate).extend({
  name: 'CourseView',

  components: {
    CourseModuleTile,
    CourseHeadline
  },

  props: {
    slug: String
  },

  breadcrumbs () {
    const breadcrumbs: any[] = [{
      text: 'run.edit.contents',
      to: null
    }]

    if (this.run) {
      breadcrumbs.unshift({
        title: getTextForTemporaryUserLanguage(this.run),
        to: null
      })
    }

    const featureMode = this.$permission.can(null, 'event-update')
      ? 'run'
      : 'course'
    breadcrumbs.unshift({
      text: `run.global.${featureMode}s`,
      to: `/${featureMode}s`
    })

    return breadcrumbs
  },

  data () {
    return {
      runDashboard: null! as RunDashboard,
      run: null! as RunRestricted,
      sessions: null! as Session[],
      userTrackings: {} as Record<string, any>,
      certUrl: null! as string,

      loading: 0,
      sessionsLoading: 0,
      inactive: false
    }
  },

  computed: {
    runKeyVisual (): string | null | undefined {
      return getKeyvisual(this.run, this.$store.state.auth.user.languagecode)?.url
    },
    consecutive (): boolean {
      return this.run?.properties?.consecutive
    },
    modules (): Module[] {
      return this.run?.properties
        .moduleAgenda?.map((id: string) => this.run.modules.find(module => module.id === id)) || this.run.modules
    }
  },

  apollo: {
    runDashboard: {
      query: RUN_DASHBOARD,

      fetchPolicy: 'cache-and-network',

      variables (): object {
        return {
          slug: this.slug
        }
      },

      update (result: Query): RunDashboard {
        const runDashboard = result.runDashboard!
        this.run = runDashboard.run!

        if (!this.run) {
          this.$router.replace('/404')
          return null!
        }

        this.inactive = !this.run.active

        return runDashboard
      },

      error (error): void {
        console.error(error)
      },

      loadingKey: 'loading'
    },
    sessions: {
      query: RUN_DASHBOARD_SESSION,

      fetchPolicy: 'cache-and-network',

      skip (): boolean {
        return !this.run
      },

      variables (): object {
        return {
          run_id: this.run.id
        }
      },

      update (result: Query): Session[] {
        const sessions = (result.runDashboardSessions || []).filter(Boolean) as Session[]
        this.modules!.forEach(m => {
          if (!m) return

          const session = sessions.find(s => (s?.module?.identifier) === m.identifier)
          if (!session || !session.tracking_status) return

          this.$set(this.userTrackings, m.id, session.tracking_status)

          this.checkForCachedSessions()
        })

        return sessions
      },

      error (error): void {
        console.error(error)
      },

      loadingKey: 'sessionsLoading'
    }
  },

  methods: {
    getTextForLanguage,
    getTextForTemporaryUserLanguage,
    /**
     * This flow of async issuing and presenting another download button was added,
     * because of iOS blocking any `window.open` within async functions.
     */
    async asyncIssueCertificate () {
      this.certUrl = await this.issueCertificate(this.run!.id, this.$store.state.auth.user.id)
    },
    downloadCert () {
      if (this.certUrl) {
        window.open(this.certUrl, '_blank')
      }
    },

    async checkForCachedSessions () {
      await this.$store.dispatch('initStore')
      const sessions = this.$store.getters['module/getSessions'](this.run.id)
      this.run!.modules!.forEach(m => {
        if (!m) {
          return
        }
        const session = sessions.find((s: Session) => (s?.module?.identifier) === m.identifier)
        if (!session || !session.tracking_status) {
          return
        }
        this.$set(this.userTrackings, m.id, session.tracking_status!)
      })
    },
    isPreviousCompleted (modules: any[], index: number): boolean {
      if (!this.consecutive) return true
      if (index < 1) return true

      const previousIncomplete = modules
        .filter((module: any, i: number) =>
          module.active && i < index &&
          module.tracking_type !== 'none' &&
          this.userTrackings[module.id]?.status !== 'completed' &&
          this.userTrackings[module.id]?.status !== 'passed'
        )

      return !modules[index - 1].active || !previousIncomplete.length
    }
  }
})
