import { render, staticRenderFns } from "./RunEditCourseEvaluation.vue?vue&type=template&id=3851b1b0&scoped=true&"
import script from "./RunEditCourseEvaluation.vue?vue&type=script&lang=ts&"
export * from "./RunEditCourseEvaluation.vue?vue&type=script&lang=ts&"
import style0 from "./RunEditCourseEvaluation.vue?vue&type=style&index=0&id=3851b1b0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3851b1b0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAlert,VBtn,VBtnToggle,VChip,VDataTable,VIcon,VProgressCircular,VSheet,VTooltip})
