























import Vue from 'vue'
import TextEditor from '@simpl/core/components/TextEditor.vue'

export default Vue.extend({
  name: 'TextareaEditor',

  components: {
    TextEditor
  },

  props: {
    targetContent: Object,
    languagecode: String,
    identifier: {
      type: String,
      default: 'display_name'
    },
    label: String,
    disabled: Boolean
  }
})
