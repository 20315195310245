





































import Vue from 'vue'
import { Module, SessionTrackingStatus } from '@simpl/core/types/graphql'

export default Vue.extend({
  name: 'ModuleProgressDisplay',

  props: {
    module: Object as () => Module,
    tracking: Object as () => SessionTrackingStatus,
    statusOnly: Boolean,
    progressOnly: Boolean
  },

  computed: {
    status (): string {
      return this.tracking?.status || ''
    },
    isWorking (): boolean {
      return this.status === 'working'
    },
    failed (): boolean {
      return this.status === 'failed'
    },
    showProgress (): boolean {
      return this.isWorking && !!this.tracking.progress && !this.statusOnly
    },
    statusColor (): string {
      if (this.status === 'completed' || this.status === 'passed') {
        return 'success'
      }
      if (this.isWorking || this.failed) {
        return 'warning'
      }
      return 'grey'
    },
    statusText (): string {
      if (this.status === 'completed' || this.status === 'passed') {
        return this.$t(`core.status.${this.status}`) as string
      }
      if (this.isWorking) {
        return this.$t('core.status.working') as string
      }
      if (this.failed) {
        return this.$t('module.testViewer.failed') as string
      }
      return this.$t('core.status.notStarted') as string
    },
    statusIcon (): string {
      if (this.status === 'completed' || this.status === 'passed') {
        return 'mdi-check-circle-outline'
      }
      if (this.isWorking) {
        return 'mdi-adjust'
      }
      if (this.failed) {
        return 'mdi-minus-circle-outline'
      }
      return 'mdi-checkbox-blank-circle-outline'
    }
  }
})
