






































































































































































































































































































































































































import mixins from 'vue-typed-mixins'
import { DELETE_RUN, LIST_COURSES, LIST_EVENTS } from '../graphql'
import ItemTile from '../../components/ItemTile.vue'
import FilterView from '@simpl/core/components/FilterView.vue'
import TagSelectFilter from '@simpl/core/mixins/utils/TagSelectFilter'

import {
  getTextForTemporaryUserLanguage,
  getTextForUserLanguage
} from '@simpl/core/utils/text'
import StringToColor from '@simpl/core/mixins/utils/StringToColor'
import UserSettings from '@simpl/core/mixins/utils/UserSettings'
import { hasOwn, stringToDate } from '@simpl/core/utils/core'
import { MUTATIONS } from '@simpl/auth/store/consts'
import { DomainQuotaItem, DomainQuotas, Query, Run, RunPaginator, Tag } from '@simpl/core/types/graphql'
import { DocumentNode } from 'graphql'
import TopicTags from '@simpl/core/mixins/apollo/TopicTags'
import RunTypes from '../mixins/RunTypes'
import FallbackLanguageCheck from '@simpl/core/mixins/utils/FallbackLanguageCheck'
import { DOMAIN_QUOTAS } from '@simpl/access-control/graphql'
import ImportContentDialog from '@simpl/core/components/dialogs/ImportContentDialog.vue'

const ActionNames = {
  MODULE_VIEW: 'moduleView',
  RUN_UPDATE: 'runUpdate',
  EVALUATION_VIEW: 'evaluationView',
  RUN_DELETE: 'runDelete'
}

type Action = {
  key: string,
  to?: string,
  disabled?: boolean | undefined | null
  on?: any,
  click?: any,
  tooltip: string,
  icon: string
}

type RunExtended = Run & {
  name: string
  updatedAtDate: Date | null
  actions: Action[]
}

export default mixins(
  RunTypes,
  TopicTags,
  StringToColor,
  TagSelectFilter,
  FallbackLanguageCheck,
  UserSettings('runListView', [
    'sortBy',
    'sortDesc',
    'selectedView',
    'itemsPerPage',
    'temporaryLanguage'
  ])).extend({
  name: 'RunListView',

  components: { ItemTile, FilterView, ImportContentDialog },

  breadcrumbs () {
    const featureMode = (this.$route.path.substr(1) === 'courses') ? 'courses' : 'runs'
    return [{
      text: 'run.global.' + featureMode,
      to: null
    }]
  },

  data () {
    const featureMode = (this.$route.path.substr(1) === 'courses') ? 'course' : 'run'

    const listQuery = (featureMode === 'course')
      ? LIST_COURSES
      : LIST_EVENTS

    return {
      featureMode,
      listQuery,
      selectedView: 'list',
      temporaryLanguage: '',
      selectedTopic: '',
      topicFilter: {} as object,

      /** Displayed data */
      runs: [] as Run[],
      loading: 0,

      /** Dialog control flow */
      dialog: false,
      selected: null! as RunExtended,
      editing: false,

      /** Search and filter */
      searchValue: '',
      sortBy: [] as string[],
      sortDesc: false,
      count: 1,
      page: 1,
      itemsPerPage: 10,
      showFilter: false,
      filterBy: [] as any[],

      domainQuotas: {} as DomainQuotas,
      showImportContentDialog: false
    }
  },

  computed: {
    relevantActions (): string[] {
      const actions = []

      this.$permission.can(null, 'module-view') && actions.push(ActionNames.MODULE_VIEW)
      this.$permission.can(null, 'run-update') && actions.push(ActionNames.RUN_UPDATE)
      this.$permission.can(null, 'evaluation-view') && actions.push(ActionNames.EVALUATION_VIEW)
      this.$permission.can(null, 'run-delete') && actions.push(ActionNames.RUN_DELETE)

      return actions
    },
    headlineText (): string {
      return this.$t(`run.global.${this.featureMode}s`) as string
    },
    headers (): any[] {
      return [
        ...[{
          text: this.$t('core.global.name'),
          value: 'name'
        }, {
          text: this.$t('core.global.version'),
          value: 'version'
        }, {
          text: this.$t('core.global.type'),
          value: 'type'
        }],
        ...(this.featureMode !== 'course'
          ? [{
            text: this.$t('wave.global.waves'),
            value: 'waves',
            sortable: false
          }]
          : []),
        ...[{
          text: this.$t('core.global.updatedAt'),
          value: 'updated_at'
        }, {
          text: this.$t('core.global.active'),
          value: 'active',
          sortable: false
        }, {
          text: '',
          value: 'actions',
          class: 'separate-left',
          width: this.relevantActions.length * 48 + 32,
          sortable: false
        }]]
    },
    presentedData (): RunExtended[] {
      return this.runs.slice().map(this.remapEntry)
    },
    computedHeaders (): object[] {
      return this.headers.filter(header => !(this.$vuetify.breakpoint.xs && header.value === 'actions'))
    },
    languageTags (): (Tag & { name: string })[] {
      const filterTags = (tag: Tag) => tag.category!.identifier === 'language'

      return this.$store.state.auth.user.tags
        .filter(filterTags)
        .map((tag: Tag) => { return { ...tag, name: getTextForUserLanguage(tag) } })
    },
    filterOptions (): object[] {
      return [
        ...[{
          type: 'only-true-switch',
          props: {
            headline: 'core.global.status',
            label: 'filter.global.onlyActiveRuns'
          },
          filterColumn: 'values',
          model: 'active'
        }],
        ...(this.featureMode !== 'course'
          ? [{
            type: 'chip-group',
            props: {
              headline: 'core.global.type',
              items: this.typesByMode('run').map((type: any) => {
                return {
                  id: type.id,
                  identifier: type.id,
                  name: type.name
                }
              })
            },
            filterColumn: 'values',
            model: 'type'
          }, {
            type: 'date-range',
            props: {
              headline: 'core.global.start'
            },
            relationColumn: 'starts_at',
            filterColumn: 'between',
            model: 'waves'
          }]
          : []),
        ...(this.featureMode === 'course'
          ? [{
            type: 'date-range',
            props: {
              headline: 'core.global.updatedAt'
            },
            filterColumn: 'between',
            model: 'updated_at'
          }]
          : [])
      ]
    },
    currentQuota (): DomainQuotaItem {
      if (hasOwn(this.domainQuotas, `${this.featureMode}s`) && this.domainQuotas[`${this.featureMode}s`].quota !== -1) {
        return this.domainQuotas[`${this.featureMode}s`]
      }
      return null! as DomainQuotaItem
    },
    createLabel (): string {
      if (this.currentQuota) {
        if (this.currentQuota.available === 0) {
          return this.$t('plans.runs.quotaExceeded', [this.currentQuota.quota, this.$t(`run.global.${this.featureMode}s`)])
        } else if (this.currentQuota.available !== -1) {
          return this.$t(`run.action.${this.featureMode}sCreate`) + ` (${this.currentQuota.available}/${this.currentQuota.quota})`
        }
      }
      return this.$t(`run.action.${this.featureMode}sCreate`)
    }
  },

  watch: {
    selectedTopic (v: number | null): void {
      if (v) {
        this.topicFilter = {
          name: 'tags:tag_id',
          values: [v] as any[]
        }
      } else {
        this.topicFilter = {}
      }
    },
    temporaryLanguage (v: string | null): void {
      this.$store.commit(`auth/${MUTATIONS.SET_TEMPORARY_LANGUAGE}`, { temporaryLanguage: v })
    }
  },

  apollo: {
    runs: {
      query (): DocumentNode {
        return this.listQuery
      },

      fetchPolicy: 'cache-and-network',

      variables (): object {
        return {
          filter: {
            search: {
              query: this.searchValue,
              columns: ['texts:display_name']
            },
            filterBy: [...this.filterBy, this.topicFilter]
          },
          orderBy: this.sortBy.map((key, index) => ({
            column: this.sortBy[index],
            order: this.sortDesc ? 'DESC' : 'ASC'
          })),
          page: this.page,
          first: this.itemsPerPage
        }
      },

      update (result: Query): Run[] {
        const mode = (this.featureMode === 'course') ? 'courses' : 'runs' as keyof Query
        const runs = result[mode]! as RunPaginator
        const paginatorInfo = runs.paginatorInfo!
        const { total, currentPage, perPage } = paginatorInfo
        this.count = total
        this.page = currentPage
        this.itemsPerPage = perPage

        return runs.data
      },

      loadingKey: 'loading'
    },
    domainQuotas: {
      query: DOMAIN_QUOTAS,

      fetchPolicy: 'cache-and-network',

      update (result: Query): any {
        this.domainQuotasLoaded = true
        return result.domainQuotas
      },

      error (error: Error): void {
        console.error(error)
      }
    }
  },

  methods: {
    remapEntry (entry: Run): RunExtended {
      return {
        ...entry,
        name: getTextForTemporaryUserLanguage(entry),
        waves: entry.waves ? entry.waves.map(wave => wave.starts_at) : [],
        updatedAtDate: stringToDate(entry.updated_at),
        actions: this.getPermittedActions(entry)
      }
    },
    getActionsWithRouteChanges (item:RunExtended): Action[] {
      return item.actions.filter(action => action.to)
    },
    getTooltip (item: RunExtended): string {
      const actionsWithRouteChanges = this.getActionsWithRouteChanges(item)
      return actionsWithRouteChanges[0]?.tooltip || ''
    },
    handleRowClick (item: RunExtended): void {
      const actionsWithRouteChanges = this.getActionsWithRouteChanges(item)
      const firstPossibleRoute = actionsWithRouteChanges[0]?.to
      firstPossibleRoute && this.$router.push(firstPossibleRoute)
    },

    getPermittedActions (item:Run): Action[] {
      const actions = []

      if (this.relevantActions.includes(ActionNames.RUN_UPDATE)) {
        actions.push({
          key: ActionNames.RUN_UPDATE,
          to: `/${this.featureMode}/${item.id}/basic`,
          tooltip: this.$t('run.action.openDetails'),
          icon: 'mdi-tune'
        })
      }

      if (this.relevantActions.includes(ActionNames.MODULE_VIEW)) {
        actions.push({
          key: ActionNames.MODULE_VIEW,
          to: `/${item.type}/${item.identifier}`,
          tooltip: this.$t('run.action.showDashboard'),
          icon: 'mdi-television-play'
        })
      }

      if (this.relevantActions.includes(ActionNames.EVALUATION_VIEW)) {
        actions.push({
          key: ActionNames.EVALUATION_VIEW,
          to: `/${this.featureMode}/${item.id}/evaluations`,
          tooltip: this.$t('run.action.showEvaluations'),
          icon: 'mdi-chart-timeline-variant'
        })
      }

      if (this.relevantActions.includes(ActionNames.RUN_DELETE)) {
        actions.push({
          key: ActionNames.RUN_DELETE,
          disabled: item.shareable && !this.$store.state.auth.user.is_super,
          on: () => this.remove(item.id, item.name),
          click: this.remove,
          tooltip: this.$t('run.action.delete'),
          icon: 'mdi-delete'
        })
      }

      return actions
    },
    updateSearchValue (v: string): void {
      this.searchValue = v
    },
    async remove (id: string | number, name: string): Promise<void> {
      const answer = await this.$confirm({
        color: 'error',
        message: this.$t('core.message.deleteConfirm', [name]),
        buttons: [{
          text: this.$t('core.action.cancel'),
          type: 'outlined',
          answer: false
        }, {
          text: this.$t('core.action.delete'),
          color: 'error',
          answer: true
        }]
      })

      if (!answer) return

      this.loading += 1
      await this.$apollo.mutate({
        mutation: DELETE_RUN,
        variables: {
          id
        }
      })
      this.loading -= 1
      await this.$apollo.queries.runs.refetch()
    },
    async onImport () {
      await this.$apollo.queries.runs.refetch()
    }
  }
})
