















































































































































































































import mixins from 'vue-typed-mixins'
import FileUploader from '../../../core/components/uploader/FileUploader.vue'
import StringToColor from '@simpl/core/mixins/utils/StringToColor'
import TagSelectFilter from '@simpl/core/mixins/utils/TagSelectFilter'

import FileUpload from '@simpl/core/mixins/apollo/FileUpload'
import PackageUpload from '@simpl/core/mixins/apollo/PackageUpload'

import { CREATE_MODULE } from '../graphql'
import { nameToIdentifier } from '@simpl/core/utils/text'

import i18n from '@simpl/core/plugins/i18n'
import MarketTags from '@simpl/core/mixins/apollo/MarketTags'
import ActiveDomainTags from '@simpl/core/mixins/apollo/ActiveDomainTags'
import { ModulePackageVerification, NewModuleInput, File as GraphQLFile } from '@simpl/core/types/graphql'
import { PackageFormat, moduleFormats } from '@simpl/core/utils/package-formats'
import { TranslateResult } from 'vue-i18n'
import { TagWithName } from '@simpl/core/types/extended-types'

const URL_REGEXP = /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+$/

export default mixins(ActiveDomainTags,
                      TagSelectFilter,
                      MarketTags,
                      FileUpload,
                      PackageUpload,
                      StringToColor).extend({
                        name: 'EditModuleDialog',

                        components: {
                          FileUploader
                        },

                        model: {},

                        props: {
                          value: Boolean,
                          languageTags: Array as () => TagWithName[]
                        },

                        data () {
                          return {
                            moduleFormats,

                            rules: {
                              required: (value: string) => !!value || i18n.t('core.global.required'),
                              url: (value: string) => {
                                return URL_REGEXP.test(value) || i18n.t('module.error.invalidUrl')
                              }
                            },
                            selectedFormatIdentifier: null! as string,
                            accept: '',
                            step: 1,
                            steps: 2,
                            name: '',
                            link: '',
                            loading: false,

                            // For detecting if module name is already taken
                            loadingModuleName: false,
                            initialName: '',
                            errorMessages: '',
                            nameAlreadyTaken: false,

                            // This is a copy of the selected file
                            // to provide user with infos such as filename and filesize
                            uploadedFile: null! as GraphQLFile,
                            uploaderHasError: false,

                            moduleFile: null! as GraphQLFile,
                            modulePackage: null! as ModulePackageVerification | {errors:string[]},
                            moduleKeyvisualId: null! as string,
                            language: null! as string,
                            marketIds: [] as string[]
                          }
                        },

                        computed: {
                          availableFormats (): PackageFormat[] {
                            const filtered = ['generic']
                            if (!this.$permission.can(null, 'cms-view')) {
                              filtered.push('simpl')
                              filtered.push('test')
                            }

                            return this.moduleFormats.filter((f: PackageFormat) => !filtered.includes(f.key))
                          },
                          selectedFormat (): PackageFormat {
                            return this.moduleFormats.find((f: PackageFormat) => f.key === this.selectedFormatIdentifier)!
                          },
                          saveButtonText (): TranslateResult {
                            return this.$t('core.action.upload')
                          },
                          canContinue (): boolean {
                            if (!this.name || !this.languageSelected || this.nameAlreadyTaken || this.uploaderHasError) return false
                            if (this.step > 1) return true
                            if (this.step === 2) {
                              if (this.selectedFormat.upload) return !!this.uploadFile
                              if (this.selectedFormatIdentifier === 'external') return URL_REGEXP.test(this.link)
                            }

                            return true
                          },
                          show: {
                            get (): boolean {
                              return this.value
                            },
                            set (v: boolean) {
                              this.$emit('input', v)
                            }
                          },
                          languageSelected: {
                            get (): string {
                              if (!this.language && this.languageTags.find(l => l.identifier === this.$store.state.auth.user.languagecode)) {
                                return this.$store.state.auth.user.languagecode
                              }
                              return this.language
                            },
                            set (v: string) {
                              this.language = v
                            }
                          },
                          marketsSelected: {
                            get (): string[] {
                              if (!this.marketIds.length && this.activeDomainMarketTags.length === 1) {
                                return [this.activeDomainMarketTags[0].id]
                              }
                              return this.marketIds
                            },
                            set (v: string[]) {
                              this.marketIds = v
                            }
                          }
                        },

                        watch: {
                          selectedFormat (v, o) {
                            if (!o) this.step += 1
                            if (!v) this.step = 1
                            this.accept = (this.selectedFormat && this.selectedFormat.accept) || '*'
                            this.$refs.fileUploader && (this.$refs.fileUploader as any).reset()
                          },
                          name (v) {
                            if (v === '') {
                              this.initialName = ''
                            }
                          }
                        },

                        methods: {
                          createSlug (identifier: string) {
                            let result = ''
                            const characters = identifier.replace('-', '') + 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
                            const charactersLength = characters.length
                            for (let i = 0; i < 10; i++) {
                              result += characters.charAt(Math.floor(Math.random() * charactersLength))
                            }
                            return result
                          },
                          setUploadFile (file: File) {
                            this.uploaderHasError = false
                            this.uploadFile = file

                            if (file && !this.name) {
                              const name = file.name
                              this.name = name.lastIndexOf('.') > -1 ? name.substr(0, name.lastIndexOf('.')) : name
                            }
                          },
                          async setKeyVisual (file: { blob: Blob }) {
                            if (file) {
                              const { blob } = file
                              this.uploadFile = new File([blob], 'module_keyvisual', { type: blob.type })

                              this.uploadContentCategory = 'keyvisual_module'
                              const uploadedFiles = await this.upload()
                              if (uploadedFiles) {
                                this.moduleKeyvisualId = uploadedFiles[0].id!
                              }
                              this.uploadFile = null
                            } else {
                              this.uploadFile = null
                            }
                          },
                          async nextStep () {
                            if (this.step === 2) {
                              if (this.uploadFile) {
                                this.uploadContentCategory = 'module_content'
                                this.uploadLanguageCode = this.languageSelected
                                this.uploadedFile = this.uploadFile as any
                                if (this.selectedFormat.uploadAsPackage) {
                                  const uploadedPackage = await this.uploadPackage()
                                  if (uploadedPackage) {
                                    this.modulePackage = uploadedPackage
                                    if ((this.modulePackage as { errors:string[] }).errors) {
                                      this.$refs.fileUploader && (this.$refs.fileUploader as any).reset()
                                      return
                                    }
                                  }
                                } else {
                                  const uploadedFiles = await this.upload()
                                  if (uploadedFiles) {
                                    this.moduleFile = uploadedFiles[0] as GraphQLFile
                                  }
                                }
                                this.uploadLanguageCode = null!
                                this.uploadFile = null
                              }
                              await this.save()
                            }
                          },

                          async save () {
                            this.loading = true
                            try {
                              const module = await this.$apollo.mutate({
                                mutation: CREATE_MODULE,
                                variables: this.getMutationVariables()
                              })
                              this.show = false

                              this.resetData()

                              this.$emit('saved', module.data.createModule)
                            } catch (e) {
                              this.$notification.publish('bottom', {
                                message: this.$t(e.graphQLErrors[0].message),
                                borderColor: 'error'
                              })
                            }
                            this.loading = false
                          },

                          getMutationVariables (): { data: NewModuleInput } {
                            const languageTagId = this.languageTags.find(l => l.identifier === this.languageSelected)?.id
                            const variables = {
                              identifier: this.createSlug(nameToIdentifier(this.name)),
                              type: this.selectedFormatIdentifier,
                              active: true,
                              languagecode: this.languageSelected,
                              texts: {
                                create: [{
                                  text: this.name,
                                  languagecode: this.languageSelected,
                                  identifier: 'display_name'
                                }]
                              },
                              tags: {
                                sync: [
                                  ...this.marketsSelected,
                                  String(languageTagId)
                                ]
                              }
                            } as NewModuleInput

                            switch (this.selectedFormatIdentifier) {
                              case 'media':
                              case 'pdf':
                              case 'download':
                                variables.downloads = {
                                  create: [{
                                    type: 'module_content',
                                    master_file_id: this.moduleFile.id
                                  }]
                                }
                                break
                              case 'external':
                                variables.links = {
                                  create: [{
                                    type: 'external',
                                    is_master: true,
                                    languagecode: this.languageSelected,
                                    url: this.link
                                  }]
                                }
                                break
                              case 'package':
                                variables.packages = {
                                  create: [{
                                    type: (this.modulePackage as ModulePackageVerification).type,
                                    is_master: true,
                                    languagecode: this.languageSelected,
                                    path: (this.modulePackage as ModulePackageVerification).path!,
                                    properties: JSON.stringify({
                                      original_package_name: this.uploadedFile.name,
                                      original_package_size: this.uploadedFile.size
                                    })
                                  }]
                                }

                                if ((this.modulePackage as ModulePackageVerification).starter_file) {
                                  variables.packages!.create![0]!.starter_file = (this.modulePackage as ModulePackageVerification).starter_file
                                }

                                break
                            }

                            if (this.moduleKeyvisualId) {
                              variables.keyvisual_id = this.moduleKeyvisualId
                            }

                            return { data: variables }
                          },

                          close () {
                            this.show = false
                            this.$nextTick(() => {
                              this.resetData()
                            })
                          },

                          cancelUpload () {
                            this.cancelPackageUpload()
                            ;(this.$refs.fileUploader as any).reset()
                            this.uploadedFile = null!
                          },

                          resetData () {
                            Object.assign(this.$data, (this.$options.data as any)(), {
                              languageTags: this.languageTags.slice(),
                              marketTags: this.marketTags.slice()
                            })
                          }
                          // async exists () {
                          //   if (this.name !== '' || this.initialName !== this.name) {
                          //     this.loadingModuleName = true
                          //     const nameExists = await this.$apollo.query({
                          //       query: MODULE_NAME_EXISTS,
                          //       variables: {
                          //         identifier: nameToIdentifier(this.name)
                          //       }
                          //     })
                          //     this.loadingModuleName = false
                          //     if (nameExists.data.moduleByIdentifier) {
                          //       this.errorMessages = this.$t('module.error.moduleNameExists')
                          //       this.nameAlreadyTaken = true
                          //     } else {
                          //       this.errorMessages = ''
                          //       this.nameAlreadyTaken = false
                          //     }
                          //     this.initialName = this.name
                          //   }
                          // }
                        }
                      })
