

































































































































































































































































































import Vue from 'vue'

import SortableJS from 'sortablejs'
import StringToColor from '@simpl/core/mixins/utils/StringToColor'
import ShowTrackingVisualizationDialog from './ShowTrackingVisualizationDialog.vue'
import TrackingVisualizationAllocationSidebar from '../components/TrackingVisualizationAllocationSidebar.vue'
import TrackingVisualizationsView from '../components/TrackingVisualizationsView.vue'
import { stringToDate } from '@simpl/core/utils/core'
import {
  RUN_EVALUATION_WITH_DETAILED_MODULES,
  UPDATE_RUN_EVALUATION,
  RUN_EVALUATION_WITH_TRACKINGS
} from '../graphql'
import { RUN_TRACKING_HISTORY } from '@simpl/base-management/runs/graphql'
import { getTextForTemporaryUserLanguage } from '@simpl/core/utils'
import {
  Query,
  User,
  RunEvaluation,
  TrackingVisualization,
  TrackingVisualizationItem
} from '@simpl/core/types/graphql'
import { exportToXLSX, TableArray } from '@simpl/core/utils/export'
import {
  createTable,
  createRankingTable
} from './table/TrackingVisualizationExportTable'
import getFirstItem from '../utils/get-first-item'
import getName from '../utils/get-name'
import { sortByOrderedArray, sortByNestedKeys } from '../utils/sort'
import parseTrackingVisualizationItem from '@simpl/tracking-evaluation/parsers/TrackingVisualizationItemParser'

export default Vue.extend({
  name: 'TrackingVisualization',

  components: {
    ShowTrackingVisualizationDialog,
    TrackingVisualizationAllocationSidebar,
    TrackingVisualizationsView
  },

  mixins: [StringToColor],

  data () {
    return {
      /** Dialog control flow */
      dialog: false,
      selected: null! as TrackingVisualization,
      editing: false,
      expanded: [] as string[],

      /** Search and filter */
      searchValue: '',

      loading: 0,
      loadingEvaluations: 0,

      allocationDrawer: false,

      evaluation: null! as RunEvaluation,
      users: [] as User[],

      usedTitles: [] as string[],

      dragHeaderWidth: 0,
      nameHeaderWidth: 0,
      nameAndTypeHeaderWidth: 0,
      tagHeaderWidth: 0,
      typeHeaderWidth: 0,
      idHeaderWidth: 0,
      itemHeaderWidth: 0,
      createdAtHeaderWidth: 0,
      updatedAtHeaderWidth: 0,
      actionsHeaderWidth: 0,

      stateless: false
    }
  },

  computed: {
    draggable (): Boolean {
      return this.expanded.length === 0
    },
    title (): null | string {
      if (!this.evaluation) {
        return null
      }
      return getTextForTemporaryUserLanguage(this.evaluation)
    },

    headers (): Record<string, any>[] {
      return [
        {
          text: '',
          value: 'drag-indicator',
          class: 'tv-table-drag',
          cellClass: this.draggable ? '' : 'not-draggable'
        },
        {
          text: this.$t('core.global.name'),
          value: 'name',
          class: 'tv-table-name',
          cellClass: this.draggable ? '' : 'not-draggable'
        },
        {
          text: this.$t('core.global.type'),
          value: 'type',
          class: 'tv-table-type',
          cellClass: this.draggable ? '' : 'not-draggable'
        },
        {
          text: this.$t('core.global.id'),
          value: 'id',
          class: 'tv-table-id',
          cellClass: this.draggable ? '' : 'not-draggable'
        },
        {
          text: this.$t('trackingEvaluation.headers.items'),
          value: 'tag',
          class: 'tv-table-tag',
          cellClass: this.draggable ? '' : 'not-draggable'
        },
        {
          text: this.$t('core.global.createdAt'),
          value: 'created_at',
          class: 'tv-table-created-at',
          cellClass: this.draggable ? '' : 'not-draggable'
        },
        {
          text: this.$t('core.global.updatedAt'),
          value: 'updated_at',
          class: 'tv-table-updated-at',
          cellClass: this.draggable ? '' : 'not-draggable'
        },
        {
          text: '',
          value: 'actions',
          class: 'separate-left tv-table-actions',
          cellClass: this.draggable ? '' : 'not-draggable',
          width: 150,
          sortable: false
        },
        {
          text: '',
          value: 'data-table-expand',
          cellClass: this.draggable ? '' : 'not-draggable'
        }
      ]
    },

    orderedVisualizations (): TrackingVisualization[] {
      if (!this.evaluation?.properties?.visualizationOrder) {
        return this.evaluation?.trackingVisualizations || []
      }
      const visualizationOrder = this.evaluation.properties.visualizationOrder
      const reversedVisualizationOrder = [...visualizationOrder].reverse()
      return this.evaluation!.trackingVisualizations.sort((a, b) =>
        sortByOrderedArray(a, b, 'id', reversedVisualizationOrder)
      )
    },

    filteredVisualizations (): TrackingVisualization[] {
      return this.orderedVisualizations.map((visualization) => {
        visualization.items.filter((item) => item.content !== null)
        return visualization
      })
    },
    allocatedTrackingVisualizationIds (): string[] {
      return (
        this.evaluation?.trackingVisualizations.map(
          (trackingVisualization) => trackingVisualization.id
        ) || []
      )
    }
  },

  apollo: {
    evaluation: {
      query: RUN_EVALUATION_WITH_DETAILED_MODULES,

      fetchPolicy: 'cache-and-network',

      loadingKey: 'loadingEvaluations',

      skip (): boolean {
        return !this.$route.params.viewId
      },
      variables (): Record<string, any> {
        return {
          id: this.$route.params.viewId,
          filter: {
            search: {
              query: this.searchValue,
              columns: ['target']
            }
          }
        }
      },
      update (result: Query): RunEvaluation | null {
        this.createSortable()
        if (!result?.runEvaluation) return null!
        return {
          ...result.runEvaluation!,
          trackingVisualizations: (
            result.runEvaluation.trackingVisualizations || []
          ).map((this as any).remapEntry),
          properties: { ...(result.runEvaluation.properties || {}) }
        }
      }
    },
    users: {
      query: RUN_TRACKING_HISTORY,

      fetchPolicy: 'no-cache',

      skip (): boolean {
        return !this.evaluation?.run
      },

      variables (): Record<string, any> {
        const runIdentifier = this.evaluation.run!.id
        const usersPage = 0
        const userItemsPerPage = 10
        return {
          run_id: runIdentifier,
          filter: {},
          page: usersPage,
          first: userItemsPerPage
        }
      },

      update (result: Query): User[] {
        const { total, currentPage, perPage } =
          result.runTrackingHistory!.paginatorInfo
        this.loading = 0
        const users = result.runTrackingHistory!.data
        const sortedUsers = this.sortUsers(users)
        this.$emit('update', users)
        return sortedUsers
      },

      loadingKey: 'loading'
    }
  },

  methods: {
    filterItems (
      items: TrackingVisualizationItem[]
    ): TrackingVisualizationItem[] {
      return items.filter((item) => item.content != null)
    },

    calculateItemsWidth () {
      const dragHeader = document.getElementsByClassName('tv-table-drag')[0]
      const nameHeader = document.getElementsByClassName('tv-table-name')[0]
      const tagHeader = document.getElementsByClassName('tv-table-tag')[0]
      const typeHeader = document.getElementsByClassName('tv-table-type')[0]
      const idHeader = document.getElementsByClassName('tv-table-id')[0]
      const createdAtHeader = document.getElementsByClassName(
        'tv-table-created-at'
      )[0]
      const updatedAtHeader = document.getElementsByClassName(
        'tv-table-updated-at'
      )[0]
      const actionsHeader =
        document.getElementsByClassName('tv-table-actions')[0]

      this.dragHeaderWidth = dragHeader ? dragHeader.clientWidth : 0
      this.nameHeaderWidth = nameHeader ? nameHeader.clientWidth : 0
      this.tagHeaderWidth = tagHeader ? tagHeader.clientWidth : 0
      this.typeHeaderWidth = typeHeader ? typeHeader.clientWidth : 0
      this.idHeaderWidth = idHeader ? idHeader.clientWidth : 0
      this.createdAtHeaderWidth = createdAtHeader
        ? createdAtHeader.clientWidth
        : 0
      this.updatedAtHeaderWidth = updatedAtHeader
        ? updatedAtHeader.clientWidth
        : 0
      this.actionsHeaderWidth = actionsHeader ? actionsHeader.clientWidth : 0

      this.nameAndTypeHeaderWidth = this.nameHeaderWidth + this.typeHeaderWidth
    },
    stringToDate,

    remapEntry (entry: TrackingVisualization): Record<string, any> {
      const items = entry.items.map((item: TrackingVisualizationItem) => {
        const nameCandidates = [
          getTextForTemporaryUserLanguage(item.content_headline, 'text'),
          getTextForTemporaryUserLanguage(item)
        ]
        const name = getName(nameCandidates)

        return {
          ...item,
          name: name,
          created_at: item.created_at
            ? stringToDate(item.created_at)
            : item.created_at,
          updated_at: item.updated_at
            ? stringToDate(item.updated_at)
            : item.updated_at
        }
      })

      const nameCandidates = [
        entry.properties?.title,
        getTextForTemporaryUserLanguage(entry),
        getTextForTemporaryUserLanguage(entry.items[0]?.content_headline, 'text'),
        entry.identifier
      ]
      const name = getName(nameCandidates)

      const tag = this.getTagOfFirstItem(entry)
      const tagText = tag
        ? this.$t(`cms.names.${tag}`)
        : this.$t('core.global.others')

      return {
        ...entry,
        items: items,
        name: name,
        tag: tagText,
        created_at: entry.created_at
          ? stringToDate(entry.created_at)
          : entry.created_at,
        updated_at: entry.updated_at
          ? stringToDate(entry.updated_at)
          : entry.updated_at
      }
    },

    getTrackingVisualizationRoute (item: TrackingVisualization): string {
      const type = item.type === 'ranking' ? 'ranking' : 'visualization'
      return `/run/${this.evaluation!.run!.id}/evaluation/${
        this.evaluation.id
      }/${type}/${item.id}`
    },
    closeAndRefetch () {
      this.allocationDrawer = false
      this.refetch()
    },
    async refetch () {
      await this.$apollo.queries.evaluation.refetch()
    },
    getTagOfFirstItem (item: TrackingVisualization): string | null {
      return item?.items[0]?.content?.data?.tag
    },
    createSortable () {
      const sortableObject = (this as any)._sortable
      if (sortableObject) {
        if (sortableObject.el) {
          // TODO JH: There is a an error thrown, when el is NULL
          sortableObject.destroy()
        } else {
          // TODO JH: What happens with sortable, when there is no el?
        }
      }
      this.$nextTick(() => {
        const table: HTMLElement | null = (
          this.$el as HTMLElement
        ).querySelector('.v-data-table tbody')
        if (!table) return
        (this as any)._sortable = SortableJS.create(table, {
          filter: '.not-draggable',
          onEnd: ({ newIndex, oldIndex }: any): void => {
            const item = this.evaluation!.trackingVisualizations!.splice(
              oldIndex!,
              1
            )[0]
            this.evaluation!.trackingVisualizations!.splice(newIndex!, 0, item)

            if (!this.evaluation.properties) {
              this.$set(this.evaluation, 'properties', {})
            }
            const ids = this.evaluation!.trackingVisualizations.map(
              (m: any) => m!.id
            )
            this.$set(this.evaluation.properties!, 'visualizationOrder', ids)
            const data = {
              id: this.evaluation!.id,
              properties: JSON.stringify({
                visualizationOrder: ids
              })
            }
            this.update(data)
          }
        })
      })
    },

    async goToComponent (trackingVisualization: TrackingVisualization) {
      const firstItem = getFirstItem(trackingVisualization)
      if (firstItem) {
        const moduleId = firstItem.module?.id
        const selectedSiteId = firstItem.site
        const selectedComponentId = firstItem.content?.id
        if (moduleId && selectedSiteId && selectedComponentId) {
          localStorage.setItem('externalDefinedSiteId', selectedSiteId)
          localStorage.setItem(
            'externalDefinedComponentId',
            selectedComponentId
          )
          window.open(
            `${window.location.origin}/cms/${moduleId}/edit`,
            '_blank'
          )
        }
      }
    },

    add () {
      this.selected = null!
      this.editing = false
      this.dialog = true
    },

    async update (data: Record<string, any>): Promise<void> {
      await this.$apollo.mutate({
        mutation: UPDATE_RUN_EVALUATION,
        variables: {
          data: data
        }
      })
    },

    async remove (id: string | number, name: string): Promise<void> {
      const ids = this.evaluation!.trackingVisualizations!.map(
        (v: Record<string, any>) => v.id
      ).filter((vid: String) => vid !== String(id))

      const data = {
        id: this.evaluation!.id,
        trackingVisualizations: {
          sync: ids
        }
      }

      this.loading += 1
      await this.update(data)

      await this.$apollo.queries.evaluation.refetch()
      this.loading -= 1
    },
    async exportEvaluation () {
      const filename = this.title || 'Export'
      const data = await this.prepareEvaluationForExport()
      await exportToXLSX(filename, data)
    },
    async prepareEvaluationForExport (): Promise<TableArray> {
      const vars = {
        id: this.$route.params.viewId,
        filter: {
          search: {
            query: this.searchValue,
            columns: ['target']
          }
        }
      }
      this.loading += 1
      const result = await this.$apollo.query({
        query: RUN_EVALUATION_WITH_TRACKINGS,
        variables: vars
      })
      this.loading -= 1
      return result.data.runEvaluation?.trackingVisualizations.map(
        (trackingVisualization: TrackingVisualization, index: number) => {
          const remappedTrackingVisualization = this.remapEntry(
            trackingVisualization
          )
          const items = remappedTrackingVisualization.items
          const remappedItems = items.map((item: Record<string, any>) => {
            return {
              ...item,
              tracking_data: item.trackings
            }
          })
          const runTitle = getTextForTemporaryUserLanguage(this.evaluation.run)
          const evaluationTitle = getTextForTemporaryUserLanguage(
            this.evaluation
          )
          const runType = this.evaluation.run?.type
            ? this.evaluation.run?.type
            : ''
          const type = remappedTrackingVisualization.type

          const trackingVisualizationProperties = trackingVisualization.properties
          const parsedTrackingProperties = parseTrackingVisualizationItem(trackingVisualization.items[0])

          const currentProperties = {
            ...parsedTrackingProperties,
            ...trackingVisualizationProperties
          }

          const trackingVisualizationTitle = currentProperties?.title
            ? currentProperties.title
            : remappedTrackingVisualization.name
          if (type === 'ranking') {
            return createRankingTable(
              this.users,
              index,
              currentProperties,
              trackingVisualizationTitle,
              runTitle,
              evaluationTitle,
              runType
            )
          } else {
            return createTable(
              remappedItems,
              index,
              currentProperties,
              trackingVisualizationTitle,
              runTitle,
              evaluationTitle,
              runType
            )
          }
        }
      )
    },
    getUniqueTitle (title: string, copyIndex: number): string {
      let titleForTesting = title
      if (copyIndex > 1) {
        titleForTesting = `${title}_${copyIndex}`
      }
      if (this.isTitleUnique(titleForTesting)) {
        this.usedTitles.push(titleForTesting)
        return titleForTesting
      } else {
        return this.getUniqueTitle(title, copyIndex + 1)
      }
    },

    isTitleUnique (title: string): boolean {
      return !this.usedTitles.includes(title)
    },

    sortUsers (users: User[]): User[] {
      if (!users) {
        return []
      }

      return users.sort((a, b) =>
        sortByNestedKeys(a, b, [{
          name: 'run_evaluation.score',
          order: 'DESC'
        }])
      )
    },

    async applyCreatedTrackingVisualization (id: string) {
      const currentTrackingVisualizations =
        this.evaluation.trackingVisualizations
      const ids = this.evaluation.trackingVisualizations.map(
        (trackingVisualization) => trackingVisualization.id
      )
      const combinedTrackingVisualizations = [...ids, id]
      await this.updateRunEvaluation(combinedTrackingVisualizations)
      this.refetch()
    },

    async applyAllocations (selectedTrackingVisualizationIds: string[]) {
      await this.updateRunEvaluation(selectedTrackingVisualizationIds)
      this.$notification.publish('bottom', {
        message: 'Visualizations successfully allocated',
        type: 'success',
        color: 'success'
      })
      this.closeAndRefetch()
    },

    async updateRunEvaluation (selectedTrackingVisualizationIds: string[]) {
      this.loading = 1
      const data = {
        id: this.evaluation.id,
        trackingVisualizations: {
          sync: selectedTrackingVisualizationIds
        }
      }

      await this.$apollo.mutate({
        mutation: UPDATE_RUN_EVALUATION,
        variables: {
          data: data
        }
      })

      this.loading = 0
    }
  }
})
