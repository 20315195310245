import { app } from '@simpl/core/init-app'

export const HIGHLIGHT_ONLY = 'highlightOnly'
export const HIGHLIGHT_AND_TABLE = 'highlightAndTable'
export const TABLE_ONLY = 'tableOnly'

export const SHARED = 'shared'
export const SHARED_WITH_GAPS = 'sharedWithGaps'
export const UNIQUE = 'unique'

export const placeholderProperties = {
  xAxisTitle: app.$t('trackingVisualization.chart.xAxisLabel')
}

export const defaultProperties = {
  key: 0,
  yAxisTitle: app.$t('trackingVisualization.chart.votes'),
  axis: {
    xaxis: {
      title: {
        style: {
          color: '#6d7579',
          fontSize: '16px'
        }
      }
    },
    yaxis: {
      title: {
        style: {
          color: '#6d7579',
          fontSize: '16px'
        }
      }
    }
  },
  diagramTypes: ['bar', 'line', 'pie', 'donut', 'groupedBar'],
  availableDiagramTypes: ['bar', 'line', 'pie', 'donut', 'groupedBar'],
  palettes: [
    ['#ce1374', '#0E8CD6', '#FEB025', '#84BD39', '#FD721F'],
    ['#7DBD72', '#EC6F28', '#3DA4DD', '#DE4934', '#F3B82B'],
    ['#f0811d', '#25398B', '#E21030', '#49277D', '#CE0049'],
    ['#aeb23c', '#E04563', '#FDBA40', '#3666AE', '#76B550'],
    ['#fdff59', '#E720A4', '#9134EF', '#6CF9FF', '#489561'],
    ['#355070', '#6D597A', '#B56576', '#E56B6F', '#EAAC8B'],
    ['#BF211E', '#F6511D', '#FE7F2D', '#F79824', '#F9C22E'],
    ['#F95738', '#EE964B', '#F4D35E', '#FAF0CA', '#5389B4'],
    ['#51536A', '#A7B1C2', '#EDF2F4', '#EF233C', '#980023'],
    ['#46A69C', '#6BBF90', '#A3D9B6', '#D8F2DC', '#E3F26D'],
    ['#F2F2F2', '#F29544', '#BFB99B', '#F2E18D', '#D95F69'],
    ['#5389B4', '#F2B138', '#A1A5A6', '#D9D9D9', '#494F51'],
    ['#607291', '#ADCBFF', '#FFF4A1', '#9B5FB3', '#E194FF'],
    ['#BF5669', '#D9824C', '#D9CE36', '#E5DAAC', '#0074B2'],
    ['#465968', '#CABAAB', '#9ACFDD', '#FAF566', '#D90B1C']
  ],
  distributable: true,
  distributed: true,
  colors: ['#ce1374', '#0E8CD6', '#FEB025', '#84BD39', '#FD721F'],
  orientations: ['simple', 'medium', 'complex'],
  orientation: 'simple',
  dataSources: ['testData', 'userData']
}

export const defaultRankingProperties = {
  rankingStyles: [HIGHLIGHT_ONLY, HIGHLIGHT_AND_TABLE, TABLE_ONLY],
  rankingStyle: HIGHLIGHT_AND_TABLE,
  rankProperties: [SHARED_WITH_GAPS, SHARED, UNIQUE],
  rankProperty: SHARED_WITH_GAPS,
  userProperties: [{
    key: 'username',
    languageKeyPropertyKeyMatch: {
      languageKey: 'username',
      propertyKey: 'username'
    }
  }, {
    key: 'firstName',
    languageKeyPropertyKeyMatch: {
      languageKey: 'firstName',
      propertyKey: 'firstname'
    }
  }, {
    key: 'lastName',
    languageKeyPropertyKeyMatch: {
      languageKey: 'lastName',
      propertyKey: 'lastname'
    }
  }, {
    key: 'score',
    languageKeyPropertyKeyMatch: {
      languageKey: 'score',
      propertyKey: 'score'
    }
  }],
  selectedUserProperties: ['firstName', 'lastName', 'username', 'score'],
  dataSources: ['testData', 'userData'],
  dataSource: 'userData'
}
