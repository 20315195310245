import { TrackingVisualization, TrackingVisualizationItem } from '@simpl/core/types/graphql'

export default function getFirstItem (visualization: TrackingVisualization): TrackingVisualizationItem | null {
  if (
    !visualization?.items ||
    visualization?.items?.length === 0
  ) return null

  return visualization.items[0]
}
