















































































































































































import mixins from 'vue-typed-mixins'
import { LIST_PARTICIPANT_COURSES } from '../graphql'
import RunTile from '../components/RunTile.vue'

import { getTextForUserLanguage, getTextForTemporaryUserLanguage } from '@simpl/core/utils/text'
import { stringToDate } from '@simpl/core/utils/core'
import UserSettings from '@simpl/core/mixins/utils/UserSettings'
import TagSelectFilter from '@simpl/core/mixins/utils/TagSelectFilter'
import { MUTATIONS } from '@simpl/auth/store/consts'
import { DataTableHeader } from '@simpl/core/types/table'
import { ParticipantDasboardCourses, Query, RunDashboard, Tag } from '@simpl/core/types/graphql'
import { TranslateResult } from 'vue-i18n'

type RunDashboardExtended = RunDashboard & {
  name: TranslateResult | string,
  // eslint-disable-next-line camelcase
  created_at_date: Date | null,
  // eslint-disable-next-line camelcase
  updated_at_date: Date | null,
  score: number,
  status: string
}

function remapEntry (entry: RunDashboard): RunDashboardExtended {
  const { run } = entry

  return {
    ...run,
    name: run.version ? `${getTextForTemporaryUserLanguage(run)} (${run.version})` : getTextForTemporaryUserLanguage(run),
    created_at_date: run.created_at ? stringToDate(run.created_at) : null,
    updated_at_date: run.updated_at ? stringToDate(run.updated_at) : null,
    score: entry.configuration.user && entry.configuration.user.score,
    status: entry.configuration.user
  }
}

export default mixins(TagSelectFilter,
                      UserSettings('participantCourseList', [
                        'sortBy',
                        'sortDesc',
                        'selectedView',
                        'itemsPerPage',
                        'temporaryLanguage'
                      ])).extend({
  name: 'ParticipantCourseList',

  components: { RunTile },

  data () {
    return {
      selectedView: 'list',
      type: 'all',
      temporaryLanguage: '',

      /** Displayed data */
      courses: {},
      loading: 0,

      /** Search and filter */
      searchValue: '',
      sortBy: [],
      sortDesc: false
    }
  },

  computed: {
    headers (): DataTableHeader[] {
      return [{
        text: this.$t('core.global.name'),
        value: 'name'
      }, {
        text: this.$t('core.global.progress'),
        value: 'score',
        width: '25%'
      }, {
        text: this.$t('core.global.createdAt'),
        value: 'created_at',
        width: '20%'
      }, {
        text: this.$t('core.global.updatedAt'),
        value: 'updated_at',
        width: '20%'
      }, {
        text: '',
        value: 'actions',
        class: 'separate-left',
        width: 110,
        sortable: false
      }]
    },
    presentedData (): RunDashboardExtended[] {
      return this.courses && this.courses[this.type] && this.courses[this.type].map(remapEntry)
    },
    languageTags (): Tag[] {
      return this.$store.state.auth.user.tags
        .filter((tag: Tag) => tag.category!.identifier === 'language')
        .map((tag: Tag) => { return { ...tag, name: getTextForUserLanguage(tag, 'native_name') } })
    }
  },

  watch: {
    temporaryLanguage (v) {
      this.$store.commit(`auth/${MUTATIONS.SET_TEMPORARY_LANGUAGE}`, { temporaryLanguage: v })
    }
  },

  apollo: {
    courses: {
      query: LIST_PARTICIPANT_COURSES,

      fetchPolicy: 'cache-and-network',

      update (result: Query) {
        const courses: ParticipantDasboardCourses = result.participantDashboard.courses!

        let preparedCourses = {} as Record<string, any>

        preparedCourses.all = Object.keys(courses)
          .reduce((acc: RunDashboard[], key: keyof ParticipantDasboardCourses) => acc.concat(Array.isArray(courses[key])
            ? courses[key]
            : []), [])
          .filter((el, index, self) =>
            index === self.findIndex((t) => (
              t.run.id === el.run.id
            ))
          )

        preparedCourses = Object.assign(preparedCourses, courses)

        if (preparedCourses[this.type].length) {
          if (!preparedCourses.available.length) {
            this.type = Object.keys(preparedCourses)[0] || 'attending'
          } else {
            this.type = 'all'
          }
        }

        delete preparedCourses.__typename

        return preparedCourses
      },

      error (error) {
        console.error(error)
      },

      loadingKey: 'loading'
    }
  },

  methods: {
    updateSearchValue (v) {
      this.searchValue = v
    },
    handleRowClick (item) {
      this.$router.push(`course/${item.identifier}`)
    }
  }
})
