export const fontFormats = [{
  tag: 'b',
  value: 'bold'
}, {
  tag: 'i',
  value: 'italic'
}, {
  tag: 'u',
  value: 'underline'
}]

export const alignments = [{
  styleProperty: 'textAlign',
  value: 'left'
}, {
  styleProperty: 'textAlign',
  value: 'center'
}, {
  styleProperty: 'textAlign',
  value: 'right'
}]

export const listFormats = [{
  tag: 'ul',
  value: 'unordered'
}, {
  tag: 'ol',
  value: 'ordered'
}]

export const color = [{
  tag: 'span',
  value: 'color'
}]

export const indentation = [{
  styleProperty: 'marginLeft',
  value: 'increase'
}, {
  styleProperty: 'marginLeft',
  value: 'decrease'
}]

export const availableFormats = (fontFormats as any[]).concat(alignments, listFormats, color)
