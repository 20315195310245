var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"fill-height content-padding",attrs:{"tile":""}},[_c('Headline',{staticClass:"headline",attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('run.edit.participantAllocation'))+" "+_vm._s(_vm.quotaInfo)+" ")]),(_vm.showHint)?_c('v-alert',{attrs:{"type":"info"}},[_vm._v(" "+_vm._s(_vm.$t('participants.allocation.notificationHint'))+" ")]):_vm._e(),_c('SearchFilterBar',{staticClass:"flex-grow-0 mb-6 mr-1",attrs:{"search-value":_vm.searchValue},on:{"update:searchValue":function($event){_vm.searchValue=$event},"update:search-value":function($event){_vm.searchValue=$event}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-btn',{staticClass:"ml-4",attrs:{"text":!_vm.filterBy.length,"outlined":!_vm.filterBy.length,"color":_vm.filterBy.length > 0 ? 'primary' : undefined},on:{"click":function($event){_vm.showFilter = true}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-filter-variant ")]),_vm._v(" "+_vm._s(_vm.$t('core.global.filter'))+" ")],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-4",attrs:{"large":"","icon":"","small":""},on:{"click":_vm.removeSelectedParticipants}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v(" mdi-account-minus ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('participants.allocation.remove')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"can",rawName:"v-can",value:('participant-update'),expression:"'participant-update'"}],staticClass:"ml-4",attrs:{"color":"primary","fab":"","small":""},on:{"click":function($event){_vm.allocationDrawer = true}}},on),[_c('v-icon',[_vm._v("mdi-swap-horizontal")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('participants.allocation.headline')))])])]},proxy:true}])}),_c('v-data-table',{attrs:{"loading":_vm.loading > 0,"headers":_vm.headers,"items":_vm.participants,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.count,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"multi-sort":""},on:{"update:page":function($event){_vm.page=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"header.selected",fn:function(){return [_c('v-checkbox',{on:{"change":_vm.selectAll}})]},proxy:true},{key:"item.selected",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"value":item.id,"disabled":_vm.disableAllocation(item.run_evaluation.status)},model:{value:(_vm.selectedParticipants),callback:function ($$v) {_vm.selectedParticipants=$$v},expression:"selectedParticipants"}})]}},{key:"item.usernameOrEmail",fn:function(ref){
var item = ref.item;
return [(item.username)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(item.username)+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(item.email)+" ")])],1):_c('div',[_vm._v(" "+_vm._s(item.email)+" ")])]}},{key:"item.languagecode",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"white--text",attrs:{"small":"","color":_vm.stringToColor(item.languagecode)}},[_vm._v(" "+_vm._s((item.languageName !== 'display_name') ? item.languageName : item.languagecode)+" ")]),(item.secondaryLanguages.length > 1)?_c('v-chip',{staticStyle:{"margin-left":"-10px"},attrs:{"small":""}},[_vm._v(" +"+_vm._s(item.secondaryLanguages.length - 1)+" ")]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('core.status.' + item.run_evaluation.status)))])]}},{key:"item.wave",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"wave"},[_vm._v(" "+_vm._s(_vm.getWaveNameById(item.run_evaluation, 'name'))+" "),(_vm.getWaveNameById(item.run_evaluation, 'name') !== _vm.getWaveNameById(item.run_evaluation, 'waveStart'))?_c('div',{staticClass:"wave-start"},[_vm._v(" "+_vm._s(_vm.getWaveNameById(item.run_evaluation, 'waveStart'))+" ")]):_vm._e()])]}},(!_vm.$vuetify.breakpoint.xs)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"actions"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-3",attrs:{"small":"","icon":"","disabled":_vm.disableAllocation(item.run_evaluation.status)},on:{"click":function($event){return _vm.removeSelectedParticipants(item.id)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-account-minus ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('participants.allocation.remove')))])])],1)]}}:null],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('FilterView',{attrs:{"filter-by":_vm.filterBy,"filter-options":_vm.filterOptions},on:{"update:filterBy":function($event){_vm.filterBy=$event},"update:filter-by":function($event){_vm.filterBy=$event}},model:{value:(_vm.showFilter),callback:function ($$v) {_vm.showFilter=$$v},expression:"showFilter"}}),_c('participant-allocation-sidebar',{attrs:{"allocated-participants":_vm.participantsUnfiltered,"run":_vm.run,"wave-list":_vm.waves,"domain-quotas":_vm.domainQuotas,"language-tags":_vm.languageTags,"width":'80%'},on:{"allocated":_vm.refetch},model:{value:(_vm.allocationDrawer),callback:function ($$v) {_vm.allocationDrawer=$$v},expression:"allocationDrawer"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }