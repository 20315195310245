












































































































































































































import Vue from 'vue'
import formats from '../utils/visualization-formats'
import TagSelectFilter from '@simpl/core/mixins/utils/TagSelectFilter'
import ActiveDomainTags from '@simpl/core/mixins/apollo/ActiveDomainTags'
import {
  CREATE_TRACKING_VISUALIZATION,
  TRACKING_VISUALIZATION_ITEMS_ID_ONLY
} from '../graphql'
import SelectableGraphAndWordCloudView from '../components/SelectableGraphAndWordCloudView.vue'
import SelectableRankingListView from '../components/SelectableRankingListView.vue'
import { TranslateResult } from 'vue-i18n'
import { createTextWithFallback, nameToIdentifier } from '@simpl/core/utils'
import { Run, TrackingVisualizationItem } from '@simpl/core/types/graphql'

export default Vue.extend({
  components: {
    SelectableRankingListView,
    SelectableGraphAndWordCloudView
  },

  mixins: [
    ActiveDomainTags,
    TagSelectFilter
  ],

  props: {
    value: Boolean,
    run: Object as () => Run
  },

  data () {
    return {
      loading: false,
      hasChanged: false,
      selectedFormatIdentifier: null,
      startStep: 1,
      step: 1,
      steps: 2,
      name: '',

      errorMessages: '',

      selectedToChild: [] as string[],
      creating: false,
      updating: false
    }
  },

  computed: {
    formats () {
      return formats
    },
    show: {
      get (): boolean {
        return this.value
      },
      set (v: boolean) {
        this.$emit('input', v)
      }
    },
    previousButtonText (): TranslateResult {
      return this.$t('core.global.back')
    },
    nextButtonText (): TranslateResult {
      return this.step === this.steps ? this.$t('core.action.apply') : this.$t('core.global.next')
    },
    saveButtonText (): TranslateResult {
      return this.$t('core.action.save')
    },
    isBackDisabled (): boolean {
      return this.step === this.startStep || this.creating || this.updating
    },
    isNextDisabled (): boolean {
      if (this.step === 1) {
        return !this.selectedFormatIdentifier || !this.name
      } else {
        return !this.selectedToChild.length
      }
    }
  },

  methods: {
    close () {
      this.step = this.startStep
      this.name = ''
      this.selectedFormatIdentifier = null
      this.show = false
    },
    async previousStep () {
      this.step -= 1
    },
    async nextStep () {
      if (this.step < this.steps) {
        this.step += 1
      } else {
        await this.createVisualization()
      }
    },
    async createVisualization () {
      let ids = []
      if (this.selectedFormatIdentifier === 'ranking') {
        const variables = this.getTrackingVisualizationItemsIdOnlyMutationData(this.selectedToChild)
        const response = await this.$apollo.mutate({ mutation: TRACKING_VISUALIZATION_ITEMS_ID_ONLY, variables: variables })
        const trackingVisualizationItems = response.data.trackingVisualizationItems.data
        if (trackingVisualizationItems.length > 0) {
          ids = trackingVisualizationItems.map((item: TrackingVisualizationItem) => item.id)
        }

        if (ids.length === 0) {
          this.$notification.publish('bottom', {
            message: 'No component in this module has data tracking enabled.',
            type: 'error',
            color: 'red'
          })
          return null
        }
        const properties = {
          moduleIds: this.selectedToChild
        }
        await this.createTrackingVisualization(ids, properties)
      } else {
        ids = this.selectedToChild
        if (ids.length === 0) {
          return null
        }
        await this.createTrackingVisualization(ids)
      }
      this.close()
    },
    getTrackingVisualizationItemsIdOnlyMutationData (modules: Record<string, any>): Record<string, any> {
      const data = {
        filter: {
          search: {
            query: '',
            columns: ['texts:display_name']
          },
          filterBy: [{
            name: 'module:id',
            values: modules
          }]
        },
        orderBy: [],
        page: 1,
        first: 9999
      }
      return data
    },
    getTrackingVisualizationMutationData (ids: string[]): { data: Record<string, any> } {
      const data = {
        identifier: nameToIdentifier(this.name),
        type: this.selectedFormatIdentifier,
        items: {
          sync: ids
        },
        texts: {
          create: [
            ...createTextWithFallback(this.name)
          ]
        }
      }
      return { data }
    },

    async createTrackingVisualization (ids: string[], properties: Record<string, any> | null = null): Promise<any> {
      this.creating = true
      const variables = this.getTrackingVisualizationMutationData(ids)
      if (properties) {
        variables.data.properties = JSON.stringify(properties)
      }

      const trackingVisualization = await this.$apollo.mutate({ mutation: CREATE_TRACKING_VISUALIZATION, variables: variables })
      this.$notification.publish('bottom', {
        message: 'Tracking visualization successfully created',
        type: 'success',
        color: 'success'
      })
      const id = trackingVisualization.data.createTrackingVisualization.id
      this.creating = false
      this.$emit('created', id)
      return id
    }
  }
})
