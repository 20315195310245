














































































































import Vue from 'vue'

export default Vue.extend({
  name: 'ShareLinkDialog',

  props: {
    run: Object,
    buttonFullWidth: Boolean
  },

  data: () => ({
    showShareLinkDialog: false,
    apiUrl: process.env.VUE_APP_API_URL || '',
    originUrl: window.location.origin
  }),

  methods: {
    openQrLink () {
      window.open(`${window.location.origin}/qr/${this.run.identifier}`, '_blank')
    },

    downloadQr () {
      window.open(`${process.env.VUE_APP_API_URL}/qr/generate/${this.run.type}/${this.run.identifier}/${this.$store.state.auth.user.languagecode}`, '_blank')
    },

    async copyQr () {
    //  TODO: TBI
    },

    openRunDashboardLink () {
      window.open(`${window.location.origin}/${this.run.type}/${this.run.identifier}`, '_blank')
    },

    copyRunDashboardLink () {
      const dashboardLinkField = document.getElementById('dashboard-link-input') as any
      if (dashboardLinkField) {
        dashboardLinkField.select()
        dashboardLinkField.setSelectionRange(0, 99999)
        document.execCommand('copy')
      }
    }
  }
})
