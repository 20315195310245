import htmlDecode from './html-decode'

export default function getName (possibleNames: string[]): string {
  const nameFound = possibleNames.find(findName)
  return nameFound
    ? decodeHtmlIfNecessary(nameFound)
    : ''
}

function findName (item: any) {
  if (
    !item || item === 'display_name' || item === 'text'
  ) {
    return false
  }
  return true
}

function decodeHtmlIfNecessary (text: string): string {
  return htmlDecode(text) ? htmlDecode(text)! : text
}
