import { applyTextFormat, removeTextFormat } from './text-format'
import { applyListFormat, removeListFormat } from './list-format'
import { applyAlignment, changeAlignment } from './alignment'
import { applyColor } from './color'
import { applyIndent } from './indent'
import { isTextNode } from './helper'

export class TextEditor {
  document: Document = null!
  selectionRange: Range = null!
  marker = 0
  selectionLength = 0

  private _boundMethods: Record<string, (...args: any[]) => any> = {}

  get selectionParent () {
    return !isTextNode(this.selectionRange?.commonAncestorContainer)
      ? this.selectionRange?.commonAncestorContainer as HTMLElement
      : this.selectionRange?.commonAncestorContainer.parentElement as HTMLElement
  }

  constructor (private rootEl: HTMLElement = null!) {
    this.document = rootEl.ownerDocument

    this._boundMethods = {
      selectionchange: () => this._onSelectionChange()
    }

    this.init()
  }

  _onSelectionChange () {
    const selection = window.getSelection()
    if (selection && selection.rangeCount > 0) {
      this.selectionRange = selection.getRangeAt(0)
    }
  }

  init () {
    this.document.addEventListener('selectionchange', this._boundMethods.selectionchange)
  }

  destroy () {
    this.document.removeEventListener('selectionchange', this._boundMethods.selectionchange)
  }

  applyFormat (value: string, formatDetails: any) {
    switch (value) {
      case 'text':
        applyTextFormat(this.document, this.selectionRange, this.selectionParent, formatDetails, this.marker, this.selectionLength)
        break
      case 'list':
        applyListFormat(this.document, this.selectionRange, this.selectionParent, this.rootEl, formatDetails)
        break
      case 'alignment':
        applyAlignment(this.document, this.selectionRange, this.selectionParent, this.rootEl, formatDetails)
        break
      case 'color':
        applyColor(this.document, this.selectionRange, this.selectionParent, formatDetails, this.marker, this.selectionLength)
        break
      case 'indent':
        applyIndent(this.document, this.selectionRange, this.selectionParent, this.rootEl, formatDetails.value)
        break
      default:
        break
    }
  }

  removeFormat (value: string, formatDetails: any) {
    switch (value) {
      case 'text':
        removeTextFormat(this.document, this.selectionRange, this.selectionParent, formatDetails, this.marker, this.selectionLength)
        break
      case 'list':
        removeListFormat(this.selectionRange, this.selectionParent, this.rootEl, formatDetails)
        break
      default:
        break
    }
  }
}
