


























































import StringToColor from '@simpl/core/mixins/utils/StringToColor'
import { getTextForTemporaryUserLanguage, stringToDate } from '@simpl/core/utils'
import LanguageTags from '@simpl/core/mixins/apollo/LanguageTags'
import {
  TRACKING_VISUALIZATION_ITEMS
} from '../graphql'
import {
  Query, Run,
  TrackingVisualizationItem,
  Module
} from '@simpl/core/types/graphql'
import mixins from 'vue-typed-mixins'
import htmlDecode from '../utils/html-decode'
import { TranslateResult } from 'vue-i18n'

type TrackingVisualizationItemWithRemappedTags = TrackingVisualizationItem & {
  name: string,
  tag: TranslateResult,
  // eslint-disable-next-line camelcase
  created_at: string,
  // eslint-disable-next-line camelcase
  updated_at: string
}

export default mixins(
  StringToColor,
  LanguageTags
).extend({
  name: 'SelectableGraphAndWordCloudView',

  props: {
    run: Object as () => Run,
    selectedFromParent: Array as () => TrackingVisualizationItem[],
    selectedFormatIdentifier: String
  },

  data: () => ({
    trackingVisualizationItems: [] as any[],
    selectedTrackingVisualizationItems: [] as string[],
    currentItems: [] as TrackingVisualizationItem[],
    isInternalSelected: false,

    /** Search and filter */
    searchValue: '',
    page: 1,
    itemsPerPage: 5,

    loading: 0
  }),

  computed: {
    presentedData (): TrackingVisualizationItem[] {
      const remappedTrackingVisualizationItems = this.trackingVisualizationItems.slice().map(this.remapEntry)
      const filteredTrackingVisualizationItems = remappedTrackingVisualizationItems.filter(
        (trackingVisualizationItem: any) => {
          return this.searchCaseInsensitive(trackingVisualizationItem.name) ||
            (trackingVisualizationItem.content && this.searchCaseInsensitive(trackingVisualizationItem.id)) ||
            (trackingVisualizationItem.content && this.searchCaseInsensitive(trackingVisualizationItem.tag))
        })
      return filteredTrackingVisualizationItems
    },
    headers (): Record<string, any> {
      return [{
        text: '',
        value: 'selected',
        sortable: false
      }, {
        text: this.$t('core.global.name'),
        value: 'name'
      }, {
        text: this.$t('core.global.id'),
        value: 'id'
      }, {
        text: this.$t('trackingEvaluation.headers.items'),
        value: 'tag'
      }, {
        text: this.$t('core.global.createdAt'),
        value: 'created_at'
      }, {
        text: this.$t('core.global.updatedAt'),
        value: 'updated_at'
      }]
    },
    queryVariables () {
      const modules = this.run.modules
      const interactiveTypes = ['simpl', 'test']
      const interactiveModules = modules.filter((module: Module) => interactiveTypes.includes(module.type))
      const interactiveModuleIds = interactiveModules.map((module: Module) => module.id)

      const filterBy = [{
        name: 'module:id',
        values: interactiveModuleIds
      }]

      if (this.selectedFormatIdentifier === 'wordcloud') {
        filterBy.push({
          name: 'component_tag',
          values: ['CTextInput']
        })
      }

      return {
        filter: {
          filterBy: filterBy
        },
        first: 9999
      }
    },
    isSelected: {
      get () {
        return this.isInternalSelected
      },
      set (v: boolean) {
        this.isInternalSelected = v
        return v
      }
    }
  },

  watch: {
    searchValue (v:string) {
      this.page = 1
    },
    selectedTrackingVisualizationItems (v) {
      this.isSelected = this.testIfAllIdsAreSelected()
      this.$emit('update:selectedFromParent', v)
    },
    itemsPerPage () {
      this.isSelected = this.testIfAllIdsAreSelected()
    },
    page () {
      this.isSelected = this.testIfAllIdsAreSelected()
    }
  },

  methods: {
    remapEntry (entry: TrackingVisualizationItem): TrackingVisualizationItemWithRemappedTags {
      let name = ''
      if (entry.content_headline) {
        name = getTextForTemporaryUserLanguage(entry.content_headline, 'text')
      }
      if (htmlDecode(name)) name = htmlDecode(name)!
      if (name === 'display_name') {
        name = entry.id
      }

      const tag = this.getTagOfFirstItem(entry)
      const tagText = tag ? this.$t(`cms.names.${tag}`) : this.$t('core.global.others')

      return {
        ...entry,
        name: name,
        tag: tagText,
        created_at: entry.created_at ? stringToDate(entry.created_at) : entry.created_at,
        updated_at: entry.updated_at ? stringToDate(entry.updated_at) : entry.updated_at
      }
    },
    getTagOfFirstItem (item: TrackingVisualizationItem): string | null {
      return item?.content?.data?.tag
    },
    selectAll (v: boolean) {
      const idsOnPage = this.currentItems.map((item: TrackingVisualizationItem) => item.id)

      if (v) {
        for (const id of idsOnPage) {
          if (this.selectedTrackingVisualizationItems.indexOf(id) === -1) {
            this.selectedTrackingVisualizationItems.push(id)
          }
        }
      } else {
        for (const id of idsOnPage) {
          const index = this.selectedTrackingVisualizationItems.indexOf(id)
          if (index > -1) {
            this.selectedTrackingVisualizationItems.splice(index, 1)
          }
        }
      }
    },
    testIfAllIdsAreSelected (): boolean {
      let areAllSelected = true
      const idsOnPage = this.currentItems.map((item: TrackingVisualizationItem) => item.id)
      for (const id of idsOnPage) {
        if (this.selectedTrackingVisualizationItems.indexOf(id) === -1) {
          areAllSelected = false
        }
      }
      return areAllSelected
    },

    setCurrentItems (items: TrackingVisualizationItem[]) {
      this.currentItems = items
      this.isSelected = this.testIfAllIdsAreSelected()
    },
    searchCaseInsensitive (testString: string): boolean {
      return testString.toLowerCase().includes(this.searchValue.toLowerCase())
    }
  },

  apollo: {
    trackingVisualizationItems: {
      query: TRACKING_VISUALIZATION_ITEMS,

      variables (): Record<string, any> {
        return this.queryVariables
      },

      update (result: Query): TrackingVisualizationItem[] {
        return result!.trackingVisualizationItems!.data
      },
      error (error: Error): void {
        console.error(error)
      },

      loadingKey: 'loading'
    }
  }
})
