



































































































































































































































































































































































import mixins from 'vue-typed-mixins'
import { RUN_POTENTIAL_USERS, RUN_TRACKING_HISTORY } from '../graphql'
import { RUN_SESSIONS } from '@simpl/core/graphql'
import { getTextForTemporaryUserLanguage, getTextForUserLanguage, truncateIfNecessary } from '@simpl/core/utils/text'
import StringToColor from '@simpl/core/mixins/utils/StringToColor'
import { stringToDate } from '@simpl/core/utils/core'
import UserSettings from '@simpl/core/mixins/utils/UserSettings'
import FilterView from '@simpl/core/components/FilterView.vue'
import { exportToXLSX, getDateTime, XLSX_MAX_TITLE_LENGTH, META_COLOR, TableArray } from '@simpl/core/utils/export'
import IssueCertificate from '../mixins/IssueCertificate'
import { getLocaleDateTimeFormat, getLongLocaleDateTimeFormat } from '@simpl/core/utils/date-time-format'
import { DataTableHeader } from '@simpl/core/types/table'
import {
  FilterColumn, OrderByClause, Query, User, SortOrder,
  OrderByRelationClause, Run, Module, Session
} from '@simpl/core/types/graphql'
import { TranslateResult } from 'vue-i18n'
import { TagWithName } from '@simpl/core/types/extended-types'

type UserWithRemappedTags = User & {
  secondaryLanguages: TagWithName[],
  languageName: TranslateResult,
  userGroups: TranslateResult[],
  modules?: Module[]
}

const getPrimaryLanguage = (user: User): TranslateResult => {
  const languageTag = user.tags.find(tag => tag.category!.identifier === 'language' && tag.identifier === user.languagecode)
  return getTextForUserLanguage(languageTag)
}

const getLanguageCodes = (user: User): TagWithName[] => {
  return user.tags.filter(tag => tag.category!.identifier === 'language').map(tag => {
    return {
      ...tag,
      name: getTextForUserLanguage(tag)
    }
  })
}

const remapEntry = (entry: User): UserWithRemappedTags => {
  // TODO: status of allocation per entry here
  return {
    ...entry,
    secondaryLanguages: getLanguageCodes(entry),
    languageName: getPrimaryLanguage(entry),
    userGroups: entry.tags.filter(tag => tag.category!.identifier === 'usergroup').map(tag => getTextForUserLanguage(tag))
  }
}

export default mixins(StringToColor,
                      IssueCertificate,
                      UserSettings('runEditParticipants', [
                        'participantsSortBy',
                        'participantsSortDesc',
                        'participantsItemsPerPage',
                        'potentialsSortBy',
                        'potentialsSortDesc',
                        'potentialsItemsPerPage'
                      ])).extend({
  name: 'RunEditCourseEvaluation',

  components: { FilterView },

  props: {
    run: Object as () => Run,
    languageTags: Array as () => TagWithName[]
  },

  data () {
    return {
      listType: 'completed',

      potentials: [] as UserWithRemappedTags[],
      participants: [] as UserWithRemappedTags[],
      userTrackingData: {} as Record<string, any>,

      /** Search and filter */
      filterBy: [] as FilterColumn[],
      showFilter: false,
      searchValue: '',

      potentialsSortBy: [] as any[],
      potentialsSortDesc: [] as any[],
      potentialsPage: 1,
      potentialsItemsPerPage: 10,
      potentialsCount: 1,

      participantsSortBy: [] as any[],
      participantsSortDesc: [] as any[],
      participantsCount: 1,
      participantsPage: 1,
      participantsItemsPerPage: 10,
      participantsStatusFilter: {
        name: 'status',
        values: ['passed', 'completed']
      },

      relationData: {
        column: 'progress',
        relation: 'run_user'
      },

      lastParticipantsType: '',

      loading: 0,
      loading2: 0,
      expanded: [] as UserWithRemappedTags[],

      currentCertItem: null! as string
    }
  },

  computed: {
    headersParticipants (): DataTableHeader[] {
      return [{
        text: this.$t('core.global.username'),
        value: 'username'
      }, {
        text: this.$t('core.global.mail'),
        value: 'email'
      }, {
        text: this.$t('core.global.firstName'),
        value: 'firstname'
      }, {
        text: this.$t('core.global.lastName'),
        value: 'lastname'
      }, {
        text: this.$t('core.global.language'),
        value: 'languagecode'
      }, {
        text: this.$t('core.global.status'),
        value: 'status'
      }, {
        text: this.$t('core.global.progress'),
        value: 'progress',
        class: this.$vuetify.breakpoint.mdAndDown ? 'd-inline-flex justify-center' : 'justify-center'
      }, {
        text: this.$t('core.global.score'),
        value: 'score'
      }, {
        text: this.$t('core.global.updatedAt'),
        value: 'timestamp',
        sortable: false
      }, {
        text: '',
        value: 'data-table-expand'
      }]
    },
    headersPotentials (): DataTableHeader[] {
      return [{
        text: this.$t('core.global.username'),
        value: 'username'
      }, {
        text: this.$t('core.global.mail'),
        value: 'email'
      }, {
        text: this.$t('core.global.firstName'),
        value: 'firstname'
      }, {
        text: this.$t('core.global.lastName'),
        value: 'lastname'
      }, {
        text: this.$t('core.global.language'),
        value: 'languagecode'
      }, {
        text: this.$t('core.global.status'),
        value: 'status'
      }, {
        text: this.$t('core.global.updatedAt'),
        value: 'updated_at',
        sortable: false
      }]
    },
    potentialsOrderBy (): OrderByClause[] {
      return this.potentialsSortBy
        .filter(key => key !== this.relationData.relation)
        .map((key, index) => ({
          column: this.potentialsSortBy[index],
          order: this.potentialsSortDesc[index] ? SortOrder.Desc : SortOrder.Asc
        }))
    },
    potentialsOrderByRelation (): OrderByRelationClause {
      const index = this.potentialsSortBy.indexOf(this.relationData.relation)
      return index > -1
        ? {
          ...this.relationData,
          order: this.potentialsSortDesc[index] ? SortOrder.Desc : SortOrder.Asc
        }
        : {}
    },
    participantsOrderBy (): OrderByClause[] {
      return this.participantsSortBy
        .filter(key => key !== this.relationData.relation)
        .map((key, index) => ({
          column: this.participantsSortBy[index],
          order: this.participantsSortDesc[index] ? SortOrder.Desc : SortOrder.Asc
        }))
    },
    participantsOrderByRelation (): OrderByRelationClause {
      const index = this.participantsSortBy.indexOf(this.relationData.relation)
      return index > -1
        ? {
          ...this.relationData,
          order: this.participantsSortDesc[index] ? SortOrder.Desc : SortOrder.Asc
        }
        : {}
    },
    filterOptions (): Record<string, any>[] {
      return [{
        type: 'autocomplete',
        props: {
          headline: 'core.global.languages',
          subheadline: 'filter.global.languages',
          label: 'core.action.selectLanguages',
          items: this.languageTags.map((language: TagWithName) => {
            return {
              id: language.id,
              identifier: language.identifier,
              name: getTextForUserLanguage(language)
            }
          })
        },
        relationColumn: 'identifier',
        filterColumn: 'values',
        model: 'tags'
      }]
    },
    exportAvailable (): boolean {
      return ((this.listType === 'attending' || this.listType === 'completed') && this.participants.length > 0) ||
        (this.listType === 'available' && this.potentials.length > 0)
    }
  },

  watch: {
    listType (v: string) {
      if (v === 'attending') {
        if (this.lastParticipantsType !== v) {
          this.loading = 1
          this.participants = []
          this.lastParticipantsType = v
        }
        this.participantsStatusFilter = {
          name: 'status',
          values: ['attending']
        }
      } else if (v === 'completed') {
        if (this.lastParticipantsType !== v) {
          this.loading = 1
          this.participants = []
          this.lastParticipantsType = v
        }
        this.participantsStatusFilter = {
          name: 'status',
          values: ['passed', 'completed']
        }
      } else if (v === 'available') {
        this.loading2 = 1
        this.$apollo.queries.potentials.refetch()
      }
    },
    expanded (v: UserWithRemappedTags[]) {
      if (!v.length) return

      v.forEach(async expandedUser => {
        if (!expandedUser.modules) {
          const answer = await this.$apollo.query({
            query: RUN_SESSIONS,
            variables: {
              user_id: expandedUser.id,
              run_id: this.run.id
            }
          })

          const sessionsWithModules = answer.data.runSessions.filter((session: Session) => !!session.module)

          const remappedSessions = sessionsWithModules.map((session: Session) => {
            const copy: Record<string, any> = { ...session }
            copy.name = getTextForUserLanguage(copy.module)
            if (copy.tracking_status) {
              copy.started = stringToDate(copy.tracking_status.started_at)
              copy.completed = stringToDate(copy.tracking_status.completed_at)
              copy.progress = copy.tracking_status?.progress || 0
            }
            return copy
          })

          this.participants.forEach(user => {
            if (user.id === expandedUser.id) {
              this.$set(this.userTrackingData, user.id, remappedSessions.filter((s: Session) => !!s.module))
            }
          })
        }
      })
    }
  },

  methods: {
    getLocaleDateTimeFormat,
    getLongLocaleDateTimeFormat,

    async refetch () {
      await this.$apollo.queries.participants.refetch()
    },

    getHeaderNames (keys: string[]) {
      return keys.map((key) => ({ title: this.$t(`core.global.${key}`) }))
    },
    async exportEvaluation () {
      const filename = `${getTextForUserLanguage(this.run)} ${this.$t('run.edit.evaluation')}` || 'Export'
      const data = await this.prepareEvaluationForExport()
      await exportToXLSX(filename, data)
    },
    async prepareEvaluationForExport (): Promise<TableArray> {
      const headers = this.getHeaderNames(['mail', 'firstName', 'lastName', 'username', 'language', 'status', 'date', 'progress'])

      const aoa: any[] = []

      const potentials = this.listType !== 'attending' && this.listType !== 'completed'
      const vars = {
        run_id: this.run.id,
        filter: {
          search: {
            query: this.searchValue,
            columns: ['username', 'email', 'firstname', 'lastname']
          },
          filterBy: potentials ? this.filterBy : [...this.filterBy, this.participantsStatusFilter]
        },
        orderBy: potentials ? this.potentialsOrderBy : this.participantsOrderBy,
        orderByRelation: potentials ? this.potentialsOrderByRelation : this.participantsOrderByRelation,
        first: 999999
      }

      this.loading += 1
      this.loading2 += 1
      const result = await this.$apollo.query({
        query: !potentials ? RUN_TRACKING_HISTORY : RUN_POTENTIAL_USERS,
        variables: vars
      })
      this.loading -= 1
      this.loading2 -= 1

      // TODO: temporary fix of doublets cuz paginate directive has possible bug (?) TBD
      const storedPotentialKeys: string[] = []
      const exportList = !potentials
        ? result.data.runTrackingHistory.data.map(remapEntry)
        : result.data.runPotentialUsers.data.map(remapEntry)
          .reduce((result: UserWithRemappedTags[], entry: UserWithRemappedTags) => {
            if (storedPotentialKeys.find(element => element === entry.id) === undefined) {
              storedPotentialKeys.push(entry.id)
              result.push(entry)
            }
            return result
          }, [])

      exportList.forEach((participant: UserWithRemappedTags) => {
        aoa.push([
          participant.email,
          participant.firstname,
          participant.lastname,
          participant.username,
          participant.languagecode,
          participant.run_evaluation
            ? this.$t(`core.status.${participant.run_evaluation.status}`)
            : this.$t('core.status.notStarted'),
          participant.run_evaluation
            ? participant.run_evaluation.completed_at
              ? participant.run_evaluation.completed_at
              : participant.run_evaluation.started_at
            : '-',
          participant.run_evaluation && participant.run_evaluation.progress
            ? `${participant.run_evaluation.progress}%`
            : '-'
        ])
      })

      const title = this.$t('run.edit.participantStatus') as string
      const runTitle = getTextForTemporaryUserLanguage(this.run)
      const runType = this.run?.type || ''

      const titleWithoutSpecialChars = truncateIfNecessary(
        title.replace(/[&/\\#,+()$~%.'":*?<>{}]/g, ''),
        XLSX_MAX_TITLE_LENGTH
      )

      return [{
        title: titleWithoutSpecialChars,
        headline: this.$t('run.edit.participantStatus') as string,
        headers: headers,
        data: aoa,
        meta: [
          [],
          [{
            text: this.$t(`run.types.${runType}`),
            font: {
              size: 8,
              color: { argb: META_COLOR }
            }
          }, {
            text: runTitle,
            font: {
              size: 8,
              color: { argb: META_COLOR }
            }
          }], [{
            text: this.$t('trackingEvaluation.export.exportDate'),
            font: {
              size: 8,
              color: { argb: META_COLOR }
            }
          }, {
            text: getDateTime(),
            font: {
              size: 8,
              color: { argb: META_COLOR }
            }
          }]
        ]
      }]
    },
    async prepareIssueCertificate (userId: string) {
      this.currentCertItem = userId
      const certUrl = await this.issueCertificate(this.run.id, parseInt(userId))
      if (certUrl) {
        window.open(certUrl, '_blank')
      }
    }
  },

  apollo: {
    participants: {
      query: RUN_TRACKING_HISTORY,

      fetchPolicy: 'cache-and-network',

      variables (): Record<string, any> {
        return {
          run_id: this.run.id,
          filter: {
            search: {
              query: this.searchValue,
              columns: ['email', 'firstname', 'lastname', 'username']
            },
            filterBy: [...this.filterBy, this.participantsStatusFilter]
          },
          orderBy: this.participantsOrderBy,
          orderByRelation: this.participantsOrderByRelation,
          page: this.participantsPage,
          first: this.participantsItemsPerPage
        }
      },

      update (result: Query): UserWithRemappedTags[] {
        const { total, currentPage, perPage } = result.runTrackingHistory!.paginatorInfo
        this.participantsCount = total
        this.participantsPage = currentPage
        this.participantsItemsPerPage = perPage
        this.loading = 0

        return result.runTrackingHistory!.data.map(remapEntry)
      },

      loadingKey: 'loading'
    },
    potentials: {
      query: RUN_POTENTIAL_USERS,

      fetchPolicy: 'cache-and-network',

      variables (): Record<string, any> {
        return {
          run_id: this.run.id,
          filter: {
            search: {
              query: this.searchValue,
              columns: ['email', 'firstname', 'lastname', 'username']
            },
            filterBy: this.filterBy
          },
          orderBy: this.potentialsOrderBy,
          orderByRelation: this.potentialsOrderByRelation,
          page: this.potentialsPage,
          first: this.potentialsItemsPerPage
        }
      },

      update (result: Query): UserWithRemappedTags[] {
        const { total, currentPage, perPage } = result.runPotentialUsers!.paginatorInfo
        this.potentialsCount = total
        this.potentialsPage = currentPage
        this.potentialsItemsPerPage = perPage
        this.loading2 = 0
        // TODO: temporary fix of doublets cuz paginate directive has possible bug (?) TBD
        const storedPotentialKeys: any[] = []
        return result.runPotentialUsers!.data.map(remapEntry)
          .reduce((result: UserWithRemappedTags[], entry: UserWithRemappedTags) => {
            if (storedPotentialKeys.find(element => element === entry.id) === undefined) {
              storedPotentialKeys.push(entry.id)
              result.push(entry)
            }
            return result
          }, [])
      },

      loadingKey: 'loading2'
    }
  }
})
