








































































































import ModuleFormats from '@simpl/core/mixins/utils/ModuleFormats'
import { Module, Run } from '@simpl/core/types/graphql'
import { getTextForTemporaryUserLanguage } from '@simpl/core/utils/text'
import { stringToDate } from '@simpl/core/utils/core'
import UserSettings from '@simpl/core/mixins/utils/UserSettings'
import { MUTATIONS } from '@simpl/auth/store/consts'
import StringToColor from '@simpl/core/mixins/utils/StringToColor'
import FallbackLanguageCheck from '@simpl/core/mixins/utils/FallbackLanguageCheck'
import mixins from 'vue-typed-mixins'

function remapEntry (entry: Module) {
  return {
    ...entry,
    name: getTextForTemporaryUserLanguage(entry),
    updated_at: entry.updated_at ? stringToDate(entry.updated_at) : entry.updated_at
  }
}

export default mixins(
  ModuleFormats,
  StringToColor,
  FallbackLanguageCheck,
  UserSettings('selectableRankingListView', [
    'temporaryLanguage'
  ])).extend({
  name: 'SelectableRankingListView',

  props: {
    selectedFromParent: Array,
    selectedFormatIdentifier: String,
    run: Object as () => Run
  },

  data () {
    return {
      /** Displayed data */
      selectedModules: [] as string[],
      selectedModule: '',
      currentItems: [] as Module[],
      isInternalSelected: false as Boolean,

      /** Search and filter */
      searchValue: '',
      page: 1,
      itemsPerPage: 5,

      TEMPORARILY_DISABLED: true, // TODO JH: Solange nur ein Module ausgewählt werden
      temporaryLanguage: ''
    }
  },

  computed: {
    headers (): Record<string, any>[] {
      return [{
        text: '',
        value: 'selected',
        sortable: false
      }, {
        text: this.$t('core.global.name'),
        value: 'name'
      }, {
        text: this.$t('core.global.type'),
        value: 'type'
      }, {
        text: this.$t('core.global.trackingType'),
        value: 'tracking_type'
      }, {
        text: this.$t('core.global.languages'),
        value: 'languages',
        sortable: false
      }, {
        text: this.$t('core.global.updatedAt'),
        value: 'updated_at'
      }]
    },
    presentedData (): Module[] {
      const remappedModules = this.run.modules.slice().map(remapEntry)
      const filteredModules = remappedModules.filter((module: any) => {
        return this.searchCaseInsensitive(module.name) ||
          this.searchCaseInsensitive(module.id) ||
          this.searchCaseInsensitive(module.tracking_type)
      })
      return filteredModules
    },
    computedHeaders (): Record<string, any>[] {
      return this.headers.filter((header: Record<string, any>) =>
        !(this.$vuetify.breakpoint.xs && header.value === 'actions'))
    },
    isSelected: {
      get (): Boolean {
        return this.isInternalSelected
      },
      set (v: boolean) {
        this.isInternalSelected = v
        return v
      }
    }
  },

  watch: {
    searchValue (v:string) {
      this.page = 1
    },
    temporaryLanguage (v) {
      this.$store.commit(`auth/${MUTATIONS.SET_TEMPORARY_LANGUAGE}`, { temporaryLanguage: v })
    },
    selectedModules (v) {
      this.isSelected = this.testIfAllIdsAreSelected()
      this.$emit('update:selectedFromParent', v)
    },
    selectedModule (v: string) {
      this.isSelected = this.testIfAllIdsAreSelected()
      this.$emit('update:selectedFromParent', [v]) // TODO JH: Als Array gewrapped, solange nur ein Module ausgewählt werden kann
    }
  },

  methods: {
    selectAll (v: boolean) {
      const idsOnPage = this.currentItems.map((item: Module) => item.id)
      if (v) {
        for (const id of idsOnPage) {
          if (this.selectedModules.indexOf(id) === -1) {
            this.selectedModules.push(id)
          }
        }
      } else {
        for (const id of idsOnPage) {
          const index = this.selectedModules.indexOf(id)
          if (index > -1) {
            this.selectedModules.splice(index, 1)
          }
        }
      }
    },
    testIfAllIdsAreSelected (): boolean {
      let areAllSelected = true
      const idsOnPage = this.currentItems.map((item: Module) => item.id)
      for (const id of idsOnPage) {
        if (this.selectedModules.indexOf(id) === -1) {
          areAllSelected = false
        }
      }
      return areAllSelected
    },
    setCurrentItems (items: Module[]) {
      this.currentItems = items
      this.isSelected = this.testIfAllIdsAreSelected()
    },
    searchCaseInsensitive (testString: string): boolean {
      return testString.toLowerCase().includes(this.searchValue.toLowerCase())
    },
    updateSearchValue (v: string) {
      this.searchValue = v
    },

    getModuleTags (module: Module, category: string) {
      return module.tags.filter(tag => tag.category?.identifier === category)
    }
  }
})
