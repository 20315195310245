






















































































































import Vue from 'vue'
import { getTextForTemporaryUserLanguage } from '@simpl/core/utils'
import { getKeyvisualForTemporaryUserLanguage } from '../../utils/keyvisual'
import { Module } from '@simpl/core/types/graphql'

export default Vue.extend({
  name: 'RunTile',

  props: {
    run: Object,
    to: String
  },

  data: () => ({
    overlay: false
  }),

  computed: {
    active (): boolean | null | undefined {
      return this.run?.active
    },
    progress (): number | null | undefined {
      return this.run?.configuration?.user?.progress || this.run?.status?.progress
    },
    userStatus (): string | null | undefined {
      return this.run?.configuration?.user?.status || this.run?.status?.status
    },
    keyVisual (): string | null | undefined {
      return getKeyvisualForTemporaryUserLanguage(this.run)?.url
    },
    isEvent (): boolean {
      return this.run.type === 'event'
    },
    duration (): number {
      let duration = 0

      this.run.modules?.forEach((module: Module) => {
        if (module.duration) duration += module.duration
      })

      return duration
    }
  },

  methods: {
    getTextForTemporaryUserLanguage
  }
})
