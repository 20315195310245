









































































import mixins from 'vue-typed-mixins'
import { createTextWithFallback, nameToIdentifier } from '@simpl/core/utils/text'
import { CREATE_RUN_EVALUATION, UPDATE_RUN_EVALUATION } from '@simpl/tracking-evaluation/graphql'
import StringToColor from '@simpl/core/mixins/utils/StringToColor'
import ValidationRules from '@simpl/core/mixins/utils/ValidationRules'
import { unsavedChanges } from '@simpl/core/utils/message'
import { RunEvaluation, NewRunEvaluationInput, UpdateRunEvaluationInput } from '@simpl/core/types/graphql'
import { TranslateResult } from 'vue-i18n'
import { objectHash } from '@simpl/core/utils'

type RunEvaluationType = {
  name: TranslateResult,
  value: string
}

const emptyInput = (): Record<string, any> => ({
  name: '',
  type: '',
  template: ''
})

export default mixins(StringToColor, ValidationRules).extend({
  props: {
    value: Boolean,
    id: [String, Number],
    preset: Object as () => RunEvaluation,
    runId: [String, Number]
  },

  data () {
    return {
      loading: false,
      errorMessages: '',
      valid: true,

      input: {} as Record<string, any>,
      initialInput: emptyInput(),

      initialHash: null! as string,
      currentHash: null! as string,

      types: [{
        name: this.$t('trackingEvaluation.evaluationTypes.poll'),
        value: 'poll'
      }, {
        name: this.$t('trackingEvaluation.evaluationTypes.evaluation'),
        value: 'evaluation'
      }],
      templates: [{
        name: this.$t('trackingEvaluation.templates.pageChanger'),
        value: 'pageChanger'
      }] as RunEvaluationType[]
    }
  },

  computed: {
    show: {
      get (): boolean {
        return this.value
      },
      set (v: boolean) {
        this.$emit('input', v)
      }
    },
    hasChanged (): boolean {
      return this.currentHash !== this.initialHash
    },
    canSave (): boolean {
      return this.valid && this.hasChanged
    }
  },

  watch: {
    show (v) {
      if (!v) return
      if (this.preset) {
        Object.keys(emptyInput()).forEach((key: string) => {
          this.input[key] = this.preset[key as keyof RunEvaluation]
        })

        this.initialHash = objectHash(this.input)
      } else {
        this.input = this.templates.length === 1
          ? {
            ...emptyInput(),
            template: this.templates[0].value
          }
          : emptyInput()
        this.initialHash = objectHash(this.input)
      }
      this.errorMessages = ''
      this.valid = true

      this.$nextTick(() => (this.$refs.form as any).resetValidation())
    },
    input: {
      deep: true,
      handler (v) {
        this.currentHash = objectHash(v)
      }
    }
  },

  methods: {
    getRunEvaluationMutationData (): { data: NewRunEvaluationInput | UpdateRunEvaluationInput } {
      const data: NewRunEvaluationInput | UpdateRunEvaluationInput = {
        run: {
          connect: this.runId as any
        },
        identifier: nameToIdentifier(this.input.name),
        type: 'evaluation',
        template: 'pageChanger',
        target: 'manager',
        texts: {
          create: [
            ...createTextWithFallback(this.input.name)
          ]
        }
      }

      if (this.preset) (data as UpdateRunEvaluationInput).id = this.preset.id
      return { data }
    },
    async close () {
      this.show = this.hasChanged ? await unsavedChanges(this) : false
    },
    async save () {
      this.loading = true

      await this.$apollo.mutate({
        mutation: this.preset ? UPDATE_RUN_EVALUATION : CREATE_RUN_EVALUATION,
        variables: this.getRunEvaluationMutationData()
      })

      this.$notification.publish('bottom', {
        message: `Evaluation successfully ${this.preset ? 'edited' : 'created'}`,
        type: 'success',
        color: 'success'
      })

      this.loading = false
      this.show = false
      this.$emit('saved')
    }
  }
})
