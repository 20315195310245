import { saveAs } from 'file-saver'
import Excel, { Alignment } from 'exceljs'
import { truncateIfNecessary } from './text'
import { TranslateResult } from 'vue-i18n'

export const XLSX_MAX_TITLE_LENGTH = 31
export const META_COLOR = 'aaaaaa'
export const SECONDARY_COLOR = '333333'

type TableHeaders = {
  title: string | TranslateResult
  alignment: Alignment
  numFmt: string
}

export type Table = {
  title: string,
  data: any[][],
  headline?: string,
  headers?: Partial<TableHeaders>[]
  results?: [{
    text: string
    formula: string
    alignment: Alignment
  }]
  average?: number
  meta?: Record<string, any>[][]
}

export type TableArray = [Table]

export function getDateTime () {
  const today = new Date()
  const date = today.toISOString().split('T')[0]
  const time = today.toLocaleTimeString()
  return date + ' ' + time
}

export async function exportToXLSX (filename: string, tables: TableArray) {
  const workbook = new Excel.Workbook()
  let row = null
  let currentHeader:TableHeaders|null = null
  const usedTitles: string[] = []
  tables?.forEach((table: Table) => {
    let title = table.title
    let index:number = 0
    while (usedTitles.includes(title)) {
      title = `${table.title}-${index}`
      index++
    }
    usedTitles.push(title)
    const worksheet = workbook.addWorksheet(truncateIfNecessary(title, XLSX_MAX_TITLE_LENGTH))
    if (table.headline) {
      worksheet.addRow({})
      worksheet.addRow({})
    }

    if (table.headers) {
      const headers = table.headers.map(headers => headers.title)
      row = worksheet.addRow(headers)
      row.eachCell((cell, cellIndex) => {
        currentHeader = table.headers![cellIndex - 1] as TableHeaders
        cell.alignment = currentHeader.alignment
        cell.border = {
          bottom: { style: 'thin' }
        }
      })
    }
    const firstAddresses = [] as string[]
    const lastAddresses = [] as string[]
    table.data.forEach((data, rowIndex) => {
      row = worksheet.addRow(data)
      row.eachCell((cell, cellIndex) => {
        currentHeader = table.headers![cellIndex - 1] as TableHeaders
        if (rowIndex === 0) {
          firstAddresses.push(cell.address)
        } else if (rowIndex === table.data.length - 1) {
          lastAddresses.push(cell.address)
        }
        cell.alignment = currentHeader.alignment
        cell.numFmt = currentHeader.numFmt
        if (rowIndex % 2 === 0) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'EEEEEE' }
          }
        }
      })
    })

    const results = table.results?.map(result => result.text ? result.text : '')
    row = worksheet.addRow(results)
    row.eachCell((cell, cellIndex) => {
      cell.font = { bold: true }
      if (table.results) {
        cell.alignment = table.results[cellIndex - 1].alignment
        cell.border = {
          top: { style: 'thin' }
        }
        const firstAddress = firstAddresses[cellIndex - 1]
        const lastAddress = lastAddresses[cellIndex - 1]
        if (table.results[cellIndex - 1].formula === 'sum') {
          cell.value = { formula: `SUM(${firstAddress}:${lastAddress})`, date1904: false }
        }
      }
      if (table.headers) {
        cell.numFmt = table.headers[cellIndex - 1].numFmt!
      }
    })

    worksheet.columns?.forEach(function (column, i) {
      let maxLength = 0
      if (column.eachCell) {
        column.eachCell({ includeEmpty: true }, function (cell) {
          const columnLength = cell.value ? cell.value.toString().length : 10
          if (columnLength > maxLength) {
            maxLength = columnLength
          }
        })
      }
      column.width = maxLength < 10 ? 10 : maxLength
    })

    if (table.headline) {
      const headerRow = worksheet.getRow(1)
      headerRow.height = 70
      worksheet.mergeCells(1, 1, 1, worksheet.actualColumnCount)
      const currentHeader = headerRow.getCell(1)
      currentHeader.value = table.headline
      currentHeader.alignment = { wrapText: true }
      currentHeader.font = { size: 16, bold: false }

      const emptyRow = worksheet.getRow(2)
      emptyRow.height = 10
    }

    if (table.average) {
      table.average?.forEach(data => {
        const texts = data.map(item => item.text)
        row = worksheet.addRow(texts)
        row.eachCell((cell, cellIndex) => {
          cell.font = data[cellIndex - 1].font
          cell.alignment = data[cellIndex - 1].alignment
        })
      })
    }

    table.meta?.forEach(data => {
      const texts = data.map(item => item.text)
      row = worksheet.addRow(texts)
      row.eachCell((cell, cellIndex) => {
        cell.font = data[cellIndex - 1].font
        cell.alignment = data[cellIndex - 1].alignment
      })
    })
  })
  const buffer = await workbook.xlsx.writeBuffer()
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  const fileExtension = '.xlsx'

  const blob = new Blob([buffer], { type: fileType })

  saveAs(blob, truncateIfNecessary(filename, XLSX_MAX_TITLE_LENGTH) + fileExtension)
}
