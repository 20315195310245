import Vue from 'vue'
import {
  MappedTrackingData,
  MappedTrackingDataArray,
  TrackingVisualizationData
} from '@simpl/tracking-evaluation/types'
import { Content } from '@simpl/core/types/graphql'

type TableSheet = {
  sheet: any[],
  average?: number
}

const testDataArray = [] as number[][]

export function mapTrackingData (trackingVisualizationData: TrackingVisualizationData): MappedTrackingDataArray {
  const trackings = trackingVisualizationData.trackings
  const content = trackingVisualizationData.content
  const adjustments = trackingVisualizationData.adjustments
  const useTestData = adjustments.useTestData
  const categories = adjustments.categories

  return trackings.map((tracking, index) => {
    const tag = content.data?.tag
    const testDataProperties = { tag: tag, ...content.data.properties }
    const componentConstructor = Vue.component(tag)
    const seriesData = useTestData
      ? componentConstructor.options.cms?.tracking?.getTestData
        ? componentConstructor.options.cms?.tracking?.getTestData(content)
        : getTestData(testDataProperties, index)
      : componentConstructor.options.cms?.tracking?.parseTrackingForVisualization(tracking, content, adjustments)

    const getAverage = (values: string[]) => {
      let sum = 0
      let maxSum = 0
      let value = 0
      let category

      const maxCategory = values[values.length - 1]
      const parsedMaxCategory = parseFloat(maxCategory)

      let isLegit = true

      if (isNaN(parsedMaxCategory)) return
      seriesData.forEach((data: any, index: number) => {
        category = values[index]

        if (typeof category === 'string') {
          isLegit = false
          return
        }
        value = Object.hasOwn(data, 'y') ? data.y : data
        if (isNaN(value)) return

        sum += category * value
        maxSum += parsedMaxCategory * value
      })

      return isLegit
        ? maxSum === 0
          ? 0
          : parsedMaxCategory * sum / maxSum
        : null
    }
    const average = categories ? getAverage(categories) : null

    return average
      ? {
          properties: tracking.properties,
          seriesData: seriesData,
          average: average
        }
      : {
        properties: tracking.properties,
        seriesData: seriesData
      } as MappedTrackingData
  }) as MappedTrackingDataArray
}

export function parseToTable (
  items: Record<string, any>[],
  content: Content,
  categories: string[]
): TableSheet[] {
  let tag = null
  const sheets = [] as TableSheet[]
  const trackingVisualizationData = {
    trackings: items,
    content: content,
    adjustments: {
      categories: categories
    }
  } as TrackingVisualizationData
  const trackings = mapTrackingData(trackingVisualizationData)
  trackings.forEach((tracking, index) => {
    tag = content.data?.tag
    const componentConstructor = Vue.component(tag)
    if (componentConstructor.options.cms?.tracking?.parseTrackingForTable) {
      sheets.push({
        sheet: componentConstructor.options.cms?.tracking?.parseTrackingForTable(tracking, content, categories),
        average: tracking.average
      })
    }
  })
  return sheets
}

function getTestData (properties: Record<string, any>, index: number): any[] {
  if (testDataArray?.[index]?.length > 0) return testDataArray[index]
  if (properties.tag === 'CTextInput') {
    return [
      { name: 'LMS', value: 2 },
      { name: 'Exterior', value: 1 },
      { name: 'Motor', value: 1 },
      { name: 'Sales', value: 1 },
      { name: 'Experience', value: 1 },
      { name: 'Company', value: 1 }
    ]
  } else if (properties.tag === 'CSingleMultipleChoice' || properties.tag === 'CDragAndDrop' || properties.tag === 'CRating') {
    testDataArray[index] = createRandomData(properties, true)
  } else {
    testDataArray[index] = createRandomData(properties)
  }

  // testDataArray[index] = getStaticRandomData()
  return testDataArray[index]
}

function getStaticRandomData () {
  // return [1, 2, 0, 0, 0, 0, 0, 0, 0, 0]
  // return [3, 7, 0, 12, 2, 4, 15, 13, 1, 5]
  // return [{x: 0.5, y: 3}, {x: 1, y: 7}, {x: 1.5, y: 0}, {x: 2, y: 12}, {x: 2.5, y: 2}, {x: 3, y: 4}, {x: 3.5, y: 15}, {x: 4, y: 13}, {x: 4.5, y: 1}, {x: 5, y: 5}]
  // return [{x: 0.5, y: 1}, {x: 1, y: 2}, {x: 1.5, y: 0}, {x: 2, y: 0}, {x: 2.5, y: 0}, {x: 3, y: 0}, {x: 3.5, y: 0}, {x: 4, y: 0}, {x: 4.5, y: 0}, {x: 5, y: 0}]
  return [
    { x: 1, y: 900 },
    { x: 2, y: 800 },
    { x: 3, y: 700 },
    { x: 4, y: 600 },
    { x: 5, y: 500 },
    { x: 6, y: 400 },
    { x: 7, y: 300 },
    { x: 8, y: 200 },
    { x: 9, y: 100 },
    { x: 10, y: 0 }
  ]
}

function createRandomData (properties: Record<string, any>, isNumerical: boolean = false): any[] {
  let min = 0
  let max = 0
  let step = 1

  const factor = properties.halfIncrements ? 2 : 1

  if (properties?.min) {
    min = properties.min
  }

  if (properties.tag === 'CRating') {
    min = 1
  }

  if (properties?.length) {
    max = properties.length * factor
  } else if (properties?.max) {
    max = properties.max
  } else if (properties?.numberOfAnswers) {
    max = properties?.numberOfAnswers - 1
  }

  if (properties?.step) {
    step = properties.step
  }

  return distributeInABellCurve(min, max, step, 120, isNumerical)
}

function distributeInABellCurve (min:number, max:number, step:number, n:number, isNumerical: boolean): { x: number, y: number }[] | number[] {
  const data = [] as {x: number, y: number}[]
  const numericalData = [] as number[]

  const randnBm = (min:number, max:number, skew: number) => {
    let u = 0
    let v = 0
    while (u === 0) u = Math.random() // Converting [0,1) to (0,1)
    while (v === 0) v = Math.random()
    let num = Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v)

    num = num / 10.0 + 0.5 // Translate to 0 -> 1
    if (num > 1 || num < 0) num = randnBm(min, max, skew) // resample between 0 and 1 if out of range
    num = Math.pow(num, skew) // Skew
    num *= max - min // Stretch to fill range
    num += min // offset to min
    return num
  }

  const roundToPrecision = (x:number, precision?:number) => {
    const y = +x + (precision === undefined ? 0.5 : precision / 2)
    return y - (y % (precision === undefined ? 1 : +precision))
  }
  if (isNumerical) {
    // Seed data with a bunch of 0s
    for (let j = min; (j * step) <= max; j++) {
      numericalData.push(0)
    }
    const dataLength = numericalData.length
    // Create n samples between min and max
    for (let i = 0; i < n; i++) {
      const randNum = randnBm(0, dataLength, 1)
      const rounded = roundToPrecision(randNum, 1)
      numericalData[rounded] += 1
    }
    return numericalData
  } else {
    // Seed data with a bunch of 0s
    for (let j = min; (j * step) <= max; j++) {
      data.push({ x: j * step, y: 0 })
    }
    const dataLength = data.length
    // Create n samples between min and max
    for (let i = 0; i < n; i++) {
      const randNum = randnBm(0, dataLength, 1)
      const rounded = roundToPrecision(randNum, 1)
      data[rounded].y += 1
    }
    return data
  }
}
