













































































































import {
  nameToIdentifier,
  objectHash, getTextForLanguage, hasOwn
} from '@simpl/core/utils'

import { GET_RUN, UPDATE_RUN, CREATE_RUN, DUPLICATE_RUN, RUN_UPDATED } from '../graphql'

import RunEditSidebar from '../components/RunEditSidebar.vue'
import RunEditBasic from '../views/RunEditBasic.vue'
import RunEditModules from './RunEditModules.vue'
import RunEditParticipants from '../views/RunEditParticipants.vue'
import RunEditNotifyParticipants from '../views/RunEditNotifyParticipants.vue'

import RunEvaluation from '../../../tracking-evaluation/views/RunEvaluation.vue'
import TrackingVisualization from '../../../tracking-evaluation/views/TrackingVisualization.vue'
import RunEditCourseEvaluation from '../views/RunEditCourseEvaluation.vue'
import RunEditControlCenter from './RunEditControlCenter.vue'
import RunEditSessionTracking from './RunEditSessionTracking.vue'

import UserGroupTags from '@simpl/core/mixins/apollo/UserGroupTags'
import LanguageTags from '@simpl/core/mixins/apollo/LanguageTags'
import FileUpload from '@simpl/core/mixins/apollo/FileUpload'
import ExportContent from '@simpl/core/mixins/utils/ImportExportContent'
import { EditableRun } from '../types'
import { Module, DomainQuotaItem, DomainQuotas, Query, Run, Session, UpdateRunInput } from '@simpl/core/types/graphql'
import WavesListView from '../../waves/views/WavesListView.vue'
import mixins from 'vue-typed-mixins'
import { DOMAIN_QUOTAS } from '@simpl/access-control/graphql'
import { EXPORT_CONTENT } from '@simpl/core/graphql'

function createEmptyRun (featureMode: string, defaultLanguageCode: string): EditableRun {
  const type = (featureMode === 'course') ? 'course' : 'event'
  return {
    active: false,
    checkin_type: 'none',
    created_at: null,
    dedicated_waves: false,
    domain: null,
    ends_at: null,
    languagecode: defaultLanguageCode,
    files: [],
    groupflow: false,
    is_public: false,
    modules: [],
    owner: null,
    properties: {
      registrationMode: 'none'
    },
    starts_at: null,
    status: 'planning',
    sticky: false,
    tags: [],
    texts: [],
    type: type,
    updated_at: null,
    users: [],
    name: '',
    encrypt_qr: true,
    tagIds: []
  } as any
}

export type RunEditUpdates = {
  lang: Record<string, RunEditUpdates>
  name: string,
  [key: string]: any
}

export default mixins(UserGroupTags, LanguageTags, FileUpload, ExportContent).extend({
  name: 'RunEdit',

  components: {
    RunEditSidebar
  },

  props: {
    runId: [String, Number],
    view: {
      type: String,
      default: 'basic'
    }
  },

  breadcrumbs () {
    const featureMode = (this.$route.path.substr(1, 6) === 'course') ? 'course' : 'run'
    const breadcrumbs: any[] = [{
      text: `run.global.${featureMode}s`,
      to: `/${featureMode}s`
    }]

    if (!this.id) {
      breadcrumbs.push({
        text: 'core.action.create',
        to: null
      })
    }
    if (
      this.id &&
      getTextForLanguage(this.run, this.run?.languagecode!) &&
      this.view !== 'evaluation'
    ) {
      breadcrumbs.push({
        title: getTextForLanguage(this.run, this.run.languagecode!),
        to: null
      })
    }

    if (
      this.id &&
      getTextForLanguage(this.run, this.run?.languagecode!) &&
      this.view === 'evaluation'
    ) {
      breadcrumbs.push({
        title: getTextForLanguage(this.run, this.run.languagecode!),
        to: `/${featureMode}/${this.id}/evaluations`
      }, {
        title: this.$t('trackingVisualization.global.headline'),
        to: null
      })
    }

    return breadcrumbs
  },

  data () {
    const featureMode = (this.$route.path.substr(1, 6) === 'course') ? 'course' : 'run'
    return {
      id: this.runId,
      sidebarMini: false,
      showSidebar: true,

      featureMode,

      loading: 0,

      updates: { lang: {} } as RunEditUpdates,

      initialHash: null! as string,
      currentHash: null! as string,

      domainQuotas: {} as DomainQuotas,

      run: (this.runId
        ? null!
        : createEmptyRun(featureMode, this.$store.state.auth.user.languagecode)) as EditableRun
    }
  },

  computed: {
    hasViews (): boolean {
      return !!this.views?.length
    },
    keyOfFirstView (): string {
      return this.hasViews ? this.views[0].key : ''
    },
    views (): Record<string, any>[] {
      if (this.run.type === 'course') {
        return [{
          key: 'basic',
          icon: 'mdi-record-circle-outline',
          component: RunEditBasic,
          hasActions: true,
          hidden: !this.$permission.can(null, 'module-update')
        }, {
          key: 'contents',
          icon: 'mdi-folder',
          component: RunEditModules,
          hidden: !this.$permission.can(null, 'module-update') || this.run.isShared
        }, {
          key: 'sessions',
          icon: 'mdi-power-plug',
          component: RunEditSessionTracking,
          disabled: !this.run.modules.length,
          hidden: !this.$permission.can(null, 'session-delete')
        }, {
          key: 'notifications',
          icon: 'mdi-email',
          component: RunEditNotifyParticipants,
          hidden: !this.$permission.can(null, 'module-update')
        }, {
          key: 'evaluations',
          icon: 'mdi-chart-timeline-variant',
          component: RunEditCourseEvaluation,
          hidden: !this.runId || !this.$permission.can(null, 'evaluation-view')
        }]
      } else {
        return [{
          key: 'basic',
          icon: 'mdi-record-circle-outline',
          component: RunEditBasic,
          hasActions: true,
          hidden: !this.$permission.can(null, 'module-update')
        }, {
          key: 'contents',
          icon: 'mdi-folder',
          component: RunEditModules,
          hidden: !this.$permission.can(null, 'module-update') || this.run.isShared
        }, {
          key: 'waves',
          icon: 'mdi-calendar-multiple',
          component: WavesListView,
          disabled: !this.run.dedicated_waves,
          hidden: !this.$permission.can(null, 'wave-update') || this.run.isShared
        }, {
          key: 'controlCenter',
          icon: 'mdi-power-plug',
          component: RunEditControlCenter,
          disabled: !this.run.modules.length,
          hidden: !this.$permission.can(null, 'participant-update')
        }, {
          key: 'notifications',
          icon: 'mdi-email',
          component: RunEditNotifyParticipants,
          hidden: !this.$permission.can(null, 'module-update')
        }, {
          key: 'participantAllocation',
          icon: 'mdi-account-multiple-plus',
          component: RunEditParticipants,
          hidden: !this.$permission.can(null, 'participant-update')
        }, {
          key: 'participantStatus',
          icon: 'mdi-account-check',
          component: RunEditCourseEvaluation,
          hidden: !this.runId || !this.$permission.can(null, 'evaluation-view')
        }, {
          key: 'evaluations',
          icon: 'mdi-chart-timeline-variant',
          component: RunEvaluation,
          hidden: !this.runId || !this.$permission.can(null, 'evaluation-update'),
          class: this.view === 'evaluation' ? 'mainitem' : undefined
        }, {
          key: 'evaluation',
          icon: 'mdi-chart-bar',
          title: this.$t('trackingVisualization.global.visualizations'),
          component: TrackingVisualization,
          hidden: !this.runId || !this.$permission.can(null, 'evaluation-update') || this.view !== 'evaluation',
          class: 'subitem'
        }]
      }
    },

    canSave (): boolean {
      // eslint-disable-next-line
      return this.hasChanged && !!(this.id || this.run.name || this.updates.lang[this.run!.languagecode!]?.display_name)
    },

    hasChanged (): boolean {
      const hasUpdates = Object.keys(this.updates).length > 1 || Object.keys(this.updates.lang).length > 0
      return hasUpdates || (this.initialHash !== this.currentHash)
    },

    currentQuota (): DomainQuotaItem {
      if (hasOwn(this.domainQuotas, `${this.featureMode}s`) && (this.domainQuotas as any)[`${this.featureMode}s`].quota !== -1) {
        return (this.domainQuotas as any)[`${this.featureMode}s`]
      }
      return null! as DomainQuotaItem
    }
  },

  watch: {
    run: {
      deep: true,
      handler (v: object): void {
        this.currentHash = objectHash(v)
      }
    }
  },

  apollo: {
    run: {
      query: GET_RUN,

      skip (): boolean {
        return !this.id
      },

      fetchPolicy: 'no-cache',

      loadingKey: 'loading',

      variables (): object {
        return { id: this.id }
      },

      update (data: Query): EditableRun {
        const run = data.run!

        if (!run) {
          this.$router.replace('/404')
          return null!
        }

        const retVal = {
          ...run,
          name: getTextForLanguage(run, run.languagecode!) || '',
          isShared: run.shareable && run!.domain!.id !== this.$store.state.auth.user.active_domain.id,
          tagIds: run.tags!.map(t => `${t!.id}`),
          modules: run.properties.moduleAgenda
            ? run.properties.moduleAgenda.filter((moduleId: string) => run.modules!.find(module => module!.id === moduleId)).map((moduleId: string) =>
              run.modules!.find(module => module!.id === moduleId))
            : run.modules
        }

        this.initialHash = this.currentHash = objectHash(retVal)

        return retVal as any
      },

      subscribeToMore: {
        document: RUN_UPDATED,

        // eslint-disable-next-line camelcase
        variables (): { run_id: string | number } {
          return {
            run_id: this.id
          }
        },

        updateQuery (previous: Run[], { subscriptionData }: any) {
          const runUpdated = subscriptionData.data.runUpdated

          this.run.modules = runUpdated.properties.moduleAgenda
            ? runUpdated.properties.moduleAgenda
              .filter((moduleId: string) => runUpdated.modules!
                .find((module: Module) => module!.id === moduleId)).map((moduleId: string) =>
                runUpdated.modules!.find((module: Module) => module!.id === moduleId))
            : runUpdated.modules
        }
      }
    },
    domainQuotas: {
      query: DOMAIN_QUOTAS,

      fetchPolicy: 'cache-and-network',

      update (result: Query): any {
        return result.domainQuotas
      },

      error (error: Error): void {
        console.error(error)
      }
    }
  },

  methods: {
    createSlug (identifier: string): string {
      let result = ''
      const characters = identifier.replace('-', '') + 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      for (let i = 0; i < 10; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }
      return result
    },
    getMutationVariables (): UpdateRunInput {
      const variables: UpdateRunInput = {
        texts: {
          create: [],
          delete: []
        },
        modules: {
          sync: this.run.modules.map((module: Module) => (
            { id: module.id, properties: JSON.stringify(module.run_pivot?.properties || {}) }
          ))
        },
        files: {
          sync: this.run.files!.map(f => f!.id)
        },
        tags: {
          sync: this.run.tagIds
        },
        active: this.run.active
      } as any

      if (!this.id) {
        // eslint-disable-next-line
        this.run.name = this.updates.lang[this.run.languagecode!].display_name
        this.run.tagIds.push(this.languageTags!.find(tag => tag.identifier === this.run.languagecode!)!.id)

        variables.identifier = (this.run as any).encrypt_qr
          ? this.createSlug(nameToIdentifier(this.run!.name!))// String(hash(this.run!.name!))
          : nameToIdentifier(this.run!.name!)
      }
      variables.version = this.run.version

      variables.periodic = this.run.periodic

      variables.remember_periodic_at = this.run.remember_periodic_at

      variables.type = this.run.type

      variables.checkin_type = this.run.checkin_type

      variables.dedicated_waves = this.run.dedicated_waves

      variables.is_public = this.run.is_public

      variables.shareable = this.run.shareable

      variables.languagecode = this.run.languagecode!

      if (this.run.id) {
        variables.id = this.run.id
      }

      for (const lang in this.updates.lang) {
        this.getMutationsVariablesForLang(this.updates.lang[lang], variables, lang)
      }

      this.deleteTextForDeletedLanguages(variables)

      if (this.run.properties) {
        variables.properties = JSON.stringify(this.run.properties)
      }

      return variables
    },

    getMutationsVariablesForLang (data: RunEditUpdates, variables: UpdateRunInput, language: string): void {
      for (const identifier in data) {
        if (data[identifier].length) {
          variables.texts!.create!.push({
            languagecode: language,
            identifier: identifier,
            text: data[identifier]
          })
        } else {
          const textId = this.run.texts!
            .find(text => text!.identifier === identifier && text!.languagecode === language)?.id

          if (textId) variables.texts!.delete!.push(textId)
        }
      }
    },

    deleteTextForDeletedLanguages (variables: UpdateRunInput) {
      if (!this.updates.deletedLanguages) return

      const languageCodes = this.updates.deletedLanguages
      const deletedTextIds = this.run.texts!.filter(text => languageCodes.indexOf(text!.languagecode!) >= 0).map(text => text!.id)

      deletedTextIds.forEach(id => variables.texts!.delete!.push(id))
    },

    async cancel (): Promise<void> {
      await this.$router.push(`/${this.featureMode}s`)
    },

    async save (): Promise<void> {
      this.loading += 1
      let hasErrors: Boolean = false

      const result = await this.$apollo.mutate({
        mutation: this.id ? UPDATE_RUN : CREATE_RUN,

        variables: {
          data: this.getMutationVariables()
        }
      }).catch((error: Error) => {
        console.error(error.message, error.name)
        hasErrors = true
      })
      this.currentHash = this.initialHash

      if (!hasErrors && result) {
        if (!this.id) {
          if (this.hasViews) {
            await this.$router.replace(`/${this.featureMode}/${result.data.createRun.id}/${this.keyOfFirstView}`)
          } else {
            await this.$router.replace(`/${this.featureMode}/${result.data.createRun.id}/basic`)
          }
          this.id = result.data.createRun.id
        } else {
          await this.$apollo.queries.run.refetch()
        }

        this.$notification.publish('bottom', {
          message: this.$t('core.message.savedChanges'),
          type: 'success',
          color: 'success'
        })
        this.loading -= 1

        this.updates = { lang: {} } as RunEditUpdates
      } else {
        this.loading -= 1
      }
    },

    async duplicate () {
      this.loading += 1
      let hasErrors: Boolean = false

      const result = await this.$apollo.mutate({
        mutation: DUPLICATE_RUN,

        variables: {
          id: this.run.id
        }
      }).catch((error: Error) => {
        console.error(error.message, error.name)
        hasErrors = true
      })

      if (!hasErrors && result) {
        if (this.hasViews) {
          await this.$router.replace(`/${this.featureMode}/${result.data.duplicateRun.id}/${this.keyOfFirstView}`)
        } else {
          await this.$router.replace(`/${this.featureMode}/${result.data.duplicateRun.id}/basic`)
        }
        this.id = result.data.duplicateRun.id
        this.reloadRun()

        this.$notification.publish('bottom', {
          message: this.$t('core.message.duplicated'),
          type: 'success',
          color: 'success'
        })
        this.loading -= 1
      } else {
        this.loading -= 1
      }
    },

    reloadRun (): void {
      this.$apollo.queries.run.refetch()
    },
    hasActions (view: string): boolean {
      return (this.views.find((v: any) => v.key === view) as any)?.hasActions
    }
  },

  async beforeRouteLeave (to: any, _from: any, next: (target?: any) => void) {
    if (!this.hasChanged || this.loading > 0) {
      next()
      return
    }

    const nextIsModuleEdit = to.name === 'base-management.module.edit'

    const userFeedback = await this.$confirm({
      message: nextIsModuleEdit ? this.$t('core.message.leavePage') : this.$t('core.message.unsavedChanges'),
      buttons: [{
        text: this.$t('core.global.yes'),
        type: 'outlined',
        answer: false
      }, {
        text: this.$t('core.global.no'),
        answer: true
      }]
    })

    if (!userFeedback) {
      await this.$apollo.queries.run.refetch()
      next()
      return
    }

    next(false)
  }
})
