import { availableFormats } from './available-formats'

export function unwrap (node: Element | null | undefined) {
  if (!node) return

  node.replaceWith(...node.childNodes)
}

export function getCurrentSelectionSettings (range: Range, selection: string[], el: HTMLElement, count = 0): string[] {
  if (count === 0) {
    const childrenFormats = getChildElementsFormats(range, el)
    childrenFormats.forEach((format: string) => {
      if (!selection.includes(format)) {
        selection.push(format)
      }
    })
  }

  availableFormats.forEach((format: any) => {
    if ((el.style[format.styleProperty] === format.value || format.tag === el.nodeName.toLowerCase()) &&
      !selection.includes(format.value)) {
      selection.push(format.value)
    }
  })

  if (el.parentElement && !el.classList?.contains('editor-text-field') && !el.classList?.contains('editor')) {
    count++
    getCurrentSelectionSettings(range, selection, el.parentElement, count)
  }

  return selection
}

export function getChildElementsFormats (range: Range, el: HTMLElement): string[] {
  const tags = [...el.getElementsByTagName('*')]
    .filter((child: Element) => range.intersectsNode(child))
    .map((child: Element) => child.tagName?.toLowerCase())
  const formats = [] as string[]

  availableFormats.forEach((format: any) => {
    if (tags.indexOf(format.tag) > -1 && !formats.includes(format.value)) {
      formats.push(format.value)
    }
  })

  return formats
}

export function isFormatted (el: HTMLElement): boolean {
  return hasFormattedParents(el) || hasFormattedChildren(el)
}

export function hasFormattedParents (el: HTMLElement): boolean {
  return !el.classList?.contains('editor-text-field') && !el.classList?.contains('editor')
}

export function hasFormattedChildren (el: HTMLElement): boolean {
  return !!el.children?.length
}

export function findParentByTag (el: HTMLElement, tag: string): HTMLElement | null | undefined {
  if (!el.parentElement ||
    el.parentElement.classList?.contains('editor-text-field') ||
    el.parentElement.classList?.contains('editor')) {
    return
  }

  if (el.parentElement.nodeName.toLowerCase() === tag) {
    return el.parentElement
  }

  findParentByTag(el.parentElement, tag)
}

export function findParentWithStyle (el: HTMLElement, style: string): HTMLElement | null | undefined {
  if (!el.parentElement ||
    el.parentElement.classList?.contains('editor-text-field') ||
    el.parentElement.classList?.contains('editor')) {
    return
  }

  if ((el.parentElement.style as any)[style]) {
    return el.parentElement
  }

  findParentByTag(el.parentElement, style)
}

export function hasSelectedFormat (el: HTMLElement, tag: string): boolean {
  if (isTextNode(el)) return false

  return el.nodeName.toLowerCase() === tag
}

export function hasParentWithFormat (el: HTMLElement, tag: string): boolean {
  return !!findParentByTag(el, tag)
}

export function hasChildrenWithFormat (el: HTMLElement, tag: string): boolean {
  let isFormatted = false

  el.children.length > 1 && [...el.children].forEach((child: Element) => {
    if (tag === child.nodeName?.toLowerCase()) {
      isFormatted = true
    }
  })

  return isFormatted
}

export function isTextNode (node: Node | undefined): boolean {
  if (!node) return false

  return node.nodeName === '#text'
}

export function getNodeTextContent (node: Node) {
  return isTextNode(node) ? node.nodeValue : (node as HTMLElement).innerText
}

export function getSelectionText (parentText: string, marker: number, selectionLength: number): string {
  const selectionEndOffset = parentText.length - marker <= selectionLength
    ? parentText.length
    : selectionLength

  return parentText.substring(marker, selectionEndOffset)
}

export function getUnselectedTexts (parentText: string, marker: number, selectionLength: number): string[] {
  const unselectedTexts = [] as string[]

  const selectionEndOffset = parentText.length - marker <= selectionLength
    ? parentText.length
    : selectionLength

  if (marker > 0) {
    unselectedTexts.push(parentText.substring(0, marker))
  }

  if (parentText.length > selectionEndOffset) {
    unselectedTexts.push(parentText.substring(selectionEndOffset, parentText.length))
  }

  return unselectedTexts
}

export function restoreSelection (newSelection: Record<string, any>) {
  const selection = window.getSelection()

  const newSelectionRange = document.createRange()
  newSelectionRange.setStart(newSelection.startContainer, newSelection.startOffset)
  newSelectionRange.setEnd(newSelection.endContainer, newSelection.endOffset)

  selection!.removeAllRanges()
  selection!.addRange(newSelectionRange)
}
