



















import Vue from 'vue'

export default Vue.extend({
  name: 'TrackingVisualizationAllocationSidebar',

  props: {
    value: Boolean,
    width: [Number, String],
    stateless: Boolean
  },

  computed: {
    show: {
      get (): boolean {
        return this.value
      },
      set (v: boolean) {
        this.$emit('input', v)
      }
    }
  }
})
