








































































































































































import Vue from 'vue'
import { debounce, getTextForLanguage } from '../utils'
import { listFormats, fontFormats, alignments, indentation } from '../utils/text-editor/available-formats'
import { TextEditor } from '../utils/text-editor/text-editor'
import { getCurrentSelectionSettings } from '../utils/text-editor/helper'

export default Vue.extend({
  name: 'Editor',

  props: {
    targetContent: Object,
    languagecode: String,
    identifier: {
      type: String,
      default: 'display_name'
    },
    disabled: Boolean
  },

  data () {
    return {
      fontFormats,
      listFormats,
      alignments,
      indentation,

      showEditor: false,

      swatches: [
        ...[['#000000'], ['#ffffff']],
        ...Object
          .keys(this.$vuetify.theme.currentTheme)
          .map(key => this.$vuetify.theme.currentTheme[key])
          .filter((x, i, a) => a.indexOf(x) === i)
          .map(v => [v])
      ],
      textColor: '#fff' as string | undefined,
      showColorPicker: false,

      textEditor: null! as TextEditor
    }
  },

  computed: {
    textField (): any {
      return this.$refs.textField
    },
    text (): string {
      return getTextForLanguage(
        this.targetContent,
        this.languagecode || this.$store.state.auth.user.languagecode,
        this.identifier
      )
    },
    currentSelectionSettings (): string[] {
      return this.textEditor?.selectionParent
        ? getCurrentSelectionSettings(this.textEditor.selectionRange!, [], this.textEditor.selectionParent)
        : []
    }
  },

  beforeDestroy () {
    this.textEditor.destroy()
    this.textEditor = null!
  },
  mounted () {
    this.textEditor = new TextEditor(
      this.textField
    )
  },

  methods: {
    focus () {
      this.textField.focus()
      this.showEditor = true
    },

    onBlur () {
      this.showEditor = false
      this.showColorPicker = false
    },

    onInput () {
      this.$emit('input', this.textField.innerHTML)
    },

    onPaste (e: ClipboardEvent) {
      const paste = (e.clipboardData || (window as any).clipboardData).getData('text')
      const selection = this.textField.ownerDocument.getSelection()

      if (!selection || selection.rangeCount < 1) return

      selection.deleteFromDocument()
      selection.getRangeAt(0).insertNode(document.createTextNode(paste))

      this.onInput()
    },

    toggleFormat (value: string, format: any) {
      if (this.currentSelectionSettings.indexOf(format.value) > -1) {
        this.textEditor.removeFormat(value, format)
      } else {
        this.textEditor.applyFormat(value, format)
      }

      this.$emit('input', this.textField.innerHTML)
    },

    onTextColorChange: debounce(function (this: any, hex: string) {
      if (!this.textEditor.selectionRange.toString().length) return

      if (!this.textColor || (this.textColor.toLowerCase() === hex.toLowerCase())) {
        this.focus()
        return
      }

      this.textColor = hex
      this.toggleFormat('color', this.textColor)
    }, 50)
  }
})
