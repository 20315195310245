













































































































































































































































































































import { RUN_EVALUATIONS, DELETE_RUN_EVALUATION } from '../graphql'
import CreateRunEvaluationDialog from '@simpl/tracking-evaluation/components/dialogs/CreateRunEvaluationDialog.vue'
import EvaluationTile from '@simpl/base-management/runs/components/EvaluationTile.vue'
import ModuleFormats from '@simpl/core/mixins/utils/ModuleFormats'
import { getTextForTemporaryUserLanguage, getTextForUserLanguage } from '@simpl/core/utils/text'
import { stringToDate } from '@simpl/core/utils/core'
import UserSettings from '@simpl/core/mixins/utils/UserSettings'
import LanguageTagSelectFilter from '@simpl/core/mixins/utils/TagSelectFilter'
import { MUTATIONS } from '@simpl/auth/store/consts'
import { DataTableHeader } from 'packages/core/types/table'
import { RunEvaluation } from 'packages/core/types/graphql'

export type RunEvaluationExtended = RunEvaluation & { name: string }

export default {
  name: 'RunEvaluation',

  components: {
    CreateRunEvaluationDialog,
    EvaluationTile
  },

  mixins: [
    ModuleFormats,
    LanguageTagSelectFilter,
    UserSettings('runEvaluation', [
      'sortBy',
      'sortDesc',
      'selectedView',
      'itemsPerPage',
      'temporaryLanguage'
    ])
  ],

  props: {
    run: Object
  },

  data () {
    return {
      /** Displayed data */
      evaluations: [],
      loading: 0,

      showContentDialog: false,
      moduleContent: null,

      /** Dialog control flow */
      dialog: false,
      selected: null as null|RunEvaluationExtended,
      editing: false,

      /** Search and filter */
      searchValue: '',
      sortBy: [],
      sortDesc: [],
      count: 1,
      page: 1,
      itemsPerPage: 10,

      selectedView: 'list',
      temporaryLanguage: ''
    }
  },

  computed: {
    headers () {
      return [{
        text: this.$t('core.global.name'),
        value: 'name'
      }, {
        text: this.$t('core.global.template'),
        value: 'template'
      }, {
        text: this.$t('core.global.updatedAt'),
        value: 'updated_at'
      }, {
        text: '',
        value: 'actions',
        class: 'separate-left',
        width: 205,
        sortable: false
      }]
    },
    presentedData (): RunEvaluationExtended[] {
      return this.evaluations.slice().map(this.remapEntry)
    },
    computedHeaders (): DataTableHeader[] {
      return this.headers.filter(header => !(this.$vuetify.breakpoint.xs && header.value === 'actions'))
    },
    queryVariables (): Record<string, any> {
      return {
        filter: {
          search: {
            query: this.searchValue,
            columns: ['identifier']
          },
          filterBy: [{
            name: 'run_id',
            values: [this.run.id]
          }]
        },
        orderBy: this.sortBy.map((key, index) => ({
          column: this.sortBy[index],
          order: this.sortDesc[index] ? 'DESC' : 'ASC'
        })),
        page: this.page,
        first: this.itemsPerPage
      }
    }
  },

  watch: {
    searchValue () {
      this.page = 1
    },
    temporaryLanguage (v) {
      this.$store.commit(`auth/${MUTATIONS.SET_TEMPORARY_LANGUAGE}`, { temporaryLanguage: v })
    }
  },

  apollo: {
    evaluations: {
      query: RUN_EVALUATIONS,

      fetchPolicy: 'cache-and-network',

      variables (): Record<string, any> {
        return this.queryVariables
      },

      update (result): RunEvaluation[] {
        return result.runEvaluations.data
      },

      error (error) {
        console.error(error)
      },

      loadingKey: 'loading'
    }
  },

  methods: {
    remapEntry (entry: RunEvaluation): RunEvaluationExtended {
      let name = getTextForTemporaryUserLanguage(entry)
      if (name === 'display_name') {
        name = entry.identifier
      }
      return {
        ...entry,
        name: name,
        updated_at: entry.updated_at ? stringToDate(entry.updated_at) : entry.updated_at
      }
    },
    updateSearchValue (v: string) {
      this.searchValue = v
    },

    getMutationVariables () {
      return {
        identifier: 'New Evaluation',
        type: 'generic'
      }
    },
    hasTrackingVisulizations (item: RunEvaluation) {
      return item.trackingVisualizations.length > 0
    },

    add () {
      this.selected = null
      this.editing = false
      this.dialog = true
    },
    edit (item: RunEvaluationExtended) {
      this.selected = item
      this.editing = true
      this.dialog = true
    },
    async remove (id: string, name: string) {
      const answer = await this.$confirm({
        color: 'error',
        message: this.$t('core.message.deleteConfirm', [name]),
        buttons: [{
          text: this.$t('core.action.cancel'),
          type: 'outlined',
          answer: false
        }, {
          text: this.$t('core.action.delete'),
          color: 'error',
          answer: true
        }]
      })

      if (!answer) return

      this.loading += 1
      await this.$apollo.mutate({
        mutation: DELETE_RUN_EVALUATION,
        variables: {
          id
        }
      })
      await this.$apollo.queries.evaluations.refetch()
      this.loading -= 1
    },
    refetchRunEvaluations () {
      this.$apollo.queries.evaluations.refetch()
    }
  }
}
