
































































































































































































import mixins from 'vue-typed-mixins'
import { ALLOCATE_PARTICIPANTS, RUN_TRACKING_HISTORY } from '../graphql'
import { RUN_SESSIONS } from '@simpl/core/graphql'
import { getTextForTemporaryUserLanguage, getTextForUserLanguage } from '@simpl/core/utils/text'
import StringToColor from '@simpl/core/mixins/utils/StringToColor'
import { hasOwn, stringToDate } from '@simpl/core/utils/core'
import UserSettings from '@simpl/core/mixins/utils/UserSettings'
import FilterView from '@simpl/core/components/FilterView.vue'
import { LIST_WAVES } from '../../waves/graphql'
import ParticipantAllocationSidebar from '../components/ParticipantAllocationSidebar.vue'
import {
  OrderByClause,
  OrderByRelationClause,
  SortOrder,
  User,
  Tag,
  Query,
  Wave,
  FilterColumn,
  Session,
  RunUserPivot, DomainQuotas
} from '@simpl/core/types/graphql'
import { TranslateResult } from 'vue-i18n'
import { DataTableHeader } from '@simpl/core/types/table'

type TagWithName = Tag & { name: string }
type WaveWithNames = Wave & {
  name: string,
  nameExtended: string
}

type UserWithRemappedTags = User & {
  languageName: TranslateResult,
  secondaryLanguages: TagWithName[],
  userGroups: TranslateResult[]
}

export default mixins(
  StringToColor,
  UserSettings('runEditParticipants', [
    'sortBy',
    'sortDesc',
    'selectedView',
    'itemsPerPage'
  ])).extend({
  name: 'RunEditParticipants',

  components: {
    FilterView,
    ParticipantAllocationSidebar
  },

  props: {
    run: Object,
    domainQuotas: Object as () => DomainQuotas,
    languageTags: Array as () => TagWithName[]
  },

  data: () => ({
    participants: [] as UserWithRemappedTags[],
    participantsUnfiltered: [] as UserWithRemappedTags[],
    selectedParticipants: [] as string[],
    waves: [] as WaveWithNames[],
    wavesLoaded: false,
    userTrackingData: {},

    /** Search and filter */
    searchValue: '',
    sortBy: [] as any[],
    sortDesc: [] as any[],
    count: 0,
    page: 1,
    itemsPerPage: 10,
    filterBy: [] as FilterColumn[],
    showFilter: false,
    selected: [] as Record<string, any>[],

    relationData: {
      column: 'progress',
      relation: 'run_user'
    },

    loading: 0,
    expanded: [] as Record<string, any>[],

    allocationDrawer: false
  }),

  computed: {
    headers (): DataTableHeader[] {
      return [{
        text: '',
        value: 'selected',
        sortable: false
      }, {
        text: this.$t('core.global.username'),
        value: 'username'
      }, {
        text: this.$t('core.global.mail'),
        value: 'email'
      }, {
        text: this.$t('core.global.firstName'),
        value: 'firstname'
      }, {
        text: this.$t('core.global.lastName'),
        value: 'lastname'
      }, {
        text: this.$t('core.global.language'),
        value: 'languagecode'
      }, {
        text: this.$t('core.global.status'),
        value: 'status'
      }, {
        text: this.$t('wave.global.wave'),
        value: 'wave',
        sortable: false
      }, {
        text: '',
        value: 'actions',
        class: 'separate-left',
        sortable: false,
        width: 100
      }]
    },
    orderBy (): OrderByClause[] {
      return this.sortBy
        .map((key: any, index: number) => ({
          column: this.sortBy[index],
          order: this.sortDesc[index] ? SortOrder.Desc : SortOrder.Asc
        }))
        .filter((key: any) => key !== this.relationData.column)
    },
    orderByRelation (): OrderByRelationClause {
      const index = this.sortBy.indexOf(this.relationData.column)
      return index > -1
        ? {
          ...this.relationData,
          order: this.sortDesc[index] ? SortOrder.Desc : SortOrder.Asc
        }
        : {}
    },
    filterOptions (): Record<string, any>[] {
      return [
        {
          type: 'chip-group',
          props: {
            headline: 'core.global.status',
            items: [{
              id: 0,
              identifier: 'invited',
              name: this.$t('core.status.invited')
            }, {
              id: 1,
              identifier: 'invite_queued',
              name: this.$t('core.status.invite_queued')
            }, {
              id: 2,
              identifier: 'registered',
              name: this.$t('core.status.registered')
            }, {
              id: 3,
              identifier: 'register_queued',
              name: this.$t('core.status.register_queued')
            }, {
              id: 4,
              identifier: 'checked_in',
              name: this.$t('core.status.checked_in')
            }]
          },
          filterColumn: 'values',
          model: 'status'
        }, {
          type: 'autocomplete',
          props: {
            headline: 'wave.global.waves',
            label: 'wave.action.selectWaves',
            items: this.waves.map(wave => {
              return {
                id: wave.id,
                identifier: wave.id,
                name: wave.nameExtended
              }
            })
          },
          filterColumn: 'values',
          model: 'wave_id'
        }, {
          type: 'autocomplete',
          props: {
            headline: 'core.global.languages',
            subheadline: 'filter.global.languages',
            label: 'core.action.selectLanguages',
            items: this.languageTags.map(language => {
              return {
                id: language.id,
                identifier: language.identifier,
                name: getTextForUserLanguage(language)
              }
            })
          },
          relationColumn: 'identifier',
          filterColumn: 'values',
          model: 'tags'
        }
      ]
    },
    showHint (): number {
      return this.participants.filter((f: Record<string, any>) => f.run_evaluation.status === 'invite_queued' || f.run_evaluation.status === 'register_queued').length
    },
    quotaInfo (): string {
      if (this.domainQuotas && hasOwn(this.domainQuotas, 'run_participants') && this.domainQuotas.run_participants.quota !== -1) {
        return `(${this.count}/${this.domainQuotas.run_participants.quota})`
      }
      return ''
    }
  },

  watch: {
    expanded (v) {
      if (!v.length) return

      v.forEach(async (expandedUser: Record<string, any>) => {
        if (!expandedUser.modules) {
          const answer = await this.$apollo.query({
            query: RUN_SESSIONS,
            variables: {
              user_id: expandedUser.id,
              run_id: this.run.id
            }
          })

          const modules = answer.data.runSessions.filter((module: Session) => !!module.module)

          const remappedModules = modules.map((module: Session) => {
            const copy = { ...module } as any
            copy.name = getTextForUserLanguage(copy.module)
            copy.created = stringToDate(copy.created_at)
            if (copy.tracking_status) {
              copy.started = stringToDate(copy.tracking_status.started_at)
              copy.completed = stringToDate(copy.tracking_status.completed_at)
              copy.progress = copy.tracking_status?.progress || 0
            }
            return copy
          })

          this.participants.forEach((user: Record<string, any>) => {
            if (user.id === expandedUser.id) {
              this.$set(this.userTrackingData, user.id, remappedModules.filter((m: Session) => !!m.module))
            }
          })
        }
      })
    }
  },

  apollo: {
    participants: {
      query: RUN_TRACKING_HISTORY,

      fetchPolicy: 'cache-and-network',

      skip () {
        return !this.wavesLoaded
      },

      variables (): Record<string, any> {
        return {
          run_id: this.run.id,
          filter: {
            search: {
              query: this.searchValue,
              columns: ['username', 'email', 'firstname', 'lastname']
            },
            filterBy: this.filterBy
          },
          orderBy: this.orderBy,
          orderByRelation: this.orderByRelation,
          page: this.page,
          first: this.itemsPerPage
        }
      },

      update (result: Query): UserWithRemappedTags[] {
        const { total, currentPage, perPage } = result.runTrackingHistory!.paginatorInfo
        this.count = total
        this.page = currentPage
        this.itemsPerPage = perPage

        this.participantsUnfiltered = result.runTrackingHistory!.data!.map(this.remapEntry)

        return result.runTrackingHistory!.data!.map(this.remapEntry).filter((user: User) => user!.run_evaluation!.status !== 'attending' && user!.run_evaluation!.status !== 'completed' && user!.run_evaluation!.status !== 'passed')
      },

      loadingKey: 'loading'
    },
    waves: {
      query: LIST_WAVES,

      fetchPolicy: 'cache-and-network',

      variables () {
        return {
          filter: {
            search: {},
            filterBy: [{
              name: 'run_id',
              values: [this.run.id]
            }]
          },
          orderBy: {},
          page: 1,
          first: 9999
        }
      },

      update (result: Query): Record<string, any>[] {
        this.wavesLoaded = true
        return result.waves!.data!.map(this.remapWaveEntry).sort((a, b) => (a.starts_at < b.starts_at) ? -1 : 1)
      },

      error (error: Error) {
        console.error(error)
      },

      loadingKey: 'loading'
    }
  },

  methods: {
    disableAllocation (status: string) {
      return ['working', 'attending', 'completed', 'passed'].includes(status)
    },
    selectAll (v: boolean) {
      this.selectedParticipants = []

      if (v) {
        this.selectedParticipants = this.participants.filter((f: Record<string, any>) => !this.disableAllocation(f.run_evaluation.status))
          .map((x: Record<string, any>) => x.id)
      }
    },
    remapWaveEntry (entry: Wave): WaveWithNames {
      let name = getTextForTemporaryUserLanguage(entry)
      let extName = ''
      if (name === 'display_name') {
        name = ''
      } else {
        extName = `(${name})`
      }
      return {
        ...entry,
        name: (name !== '') ? name : entry.starts_at,
        nameExtended: `${entry.starts_at} ${extName}`
      }
    },
    getWaveNameById (entry: RunUserPivot, neededVar: string) {
      if (!entry || !entry.wave_id) {
        return 'n/a'
      }
      const waveEntry = this.waves.filter((obj: Record<string, any>) => {
        return obj.id === entry.wave_id!.toString()
      })
      if (waveEntry.length > 0 && neededVar === 'name') {
        return waveEntry[0].name
      } else if (waveEntry.length > 0 && neededVar === 'waveStart') {
        return waveEntry[0].starts_at
      } else {
        return 'n/a'
      }
    },
    async removeParticipants (ids: string[]) {
      const answer = await this.$confirm({
        color: 'error',
        message: this.$t('core.message.deleteConfirm', [`${ids.length} ${this.$t('run.edit.participants')}`]),
        buttons: [{
          text: this.$t('core.action.cancel'),
          type: 'outlined',
          answer: false
        }, {
          text: this.$t('core.action.apply'),
          color: 'error',
          answer: true
        }]
      })

      if (!answer) return

      this.loading += 1
      await this.$apollo.mutate({
        mutation: ALLOCATE_PARTICIPANTS,
        variables: {
          data: {
            run_id: this.run.id,
            users: ids,
            status: 'removed'
          }
        }
      })

      await this.$apollo.queries.participants.refetch()
      this.$notification.publish('bottom', {
        message: this.$t('participants.allocation.removed'),
        type: 'success',
        color: 'success'
      })
      this.selectedParticipants = []
      this.selected = []

      this.loading -= 1
    },
    async refetch () {
      await this.$apollo.queries.participants.refetch()
    },
    getPrimaryLanguage (user: User) {
      const languageTag = user.tags.filter((tag: Tag) => tag!.category!.identifier === 'language' && tag!.identifier === user.languagecode)
      return getTextForUserLanguage(languageTag[0])
    },
    getLanguageCodes (user: User): TagWithName[] {
      return user.tags.filter((tag: Tag) => tag!.category!.identifier === 'language').map((tag: Tag) => {
        return {
          ...tag,
          name: getTextForUserLanguage(tag)
        }
      })
    },
    remapEntry (entry: User): UserWithRemappedTags {
      // TODO: status of allocation per entry here
      return {
        ...entry,
        secondaryLanguages: this.getLanguageCodes(entry),
        languageName: this.getPrimaryLanguage(entry),
        userGroups: entry.tags.filter((tag: Tag) => tag!.category!.identifier === 'usergroup')
          .map((tag: Tag) => getTextForUserLanguage(tag))
      }
    },
    removeSelectedParticipants (id: any) {
      if (typeof id === 'string') {
        this.selectedParticipants = []
        this.selectedParticipants[0] = id
      }
      this.removeParticipants(this.selectedParticipants)
    },

    getHeaderNames (keys: string[]): string[] {
      return keys.map((key: string) => {
        return this.$t(`core.global.${key}`) as string
      })
    }
  }
})
