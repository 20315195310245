var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"flex-grow-1 content-padding--small",attrs:{"tile":""}},[(_vm.title)?_c('Headline',{staticClass:"headline",attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_c('div',{staticClass:"d-flex",class:_vm.$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'},[_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"can",rawName:"v-can",value:('evaluation-view'),expression:"'evaluation-view'"}],staticClass:"mr-4",attrs:{"large":"","icon":""},on:{"click":_vm.exportEvaluation}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v(" mdi-download ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("trackingVisualization.action.exportVisualizations")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"large":"","icon":""},on:{"click":_vm.add}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v(" mdi-plus ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("module.action.createModule")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"can",rawName:"v-can",value:('evaluation-update'),expression:"'evaluation-update'"}],staticClass:"ml-5 mr-5",attrs:{"color":"primary","fab":"","small":""},on:{"click":function($event){_vm.allocationDrawer = true}}},on),[_c('v-icon',[_vm._v("mdi-swap-horizontal")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("trackingEvaluation.allocation.headline")))])])],1),_c('v-slide-x-transition',{attrs:{"mode":"out-in"}},[_c('div',[_c('v-data-table',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.calculateItemsWidth),expression:"calculateItemsWidth"}],key:"list",attrs:{"loading":_vm.loading > 0 || _vm.loadingEvaluations > 0,"headers":_vm.headers,"items":_vm.filteredVisualizations,"disable-sort":"","show-expand":"","expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event},"item-expanded":_vm.calculateItemsWidth},scopedSlots:_vm._u([{key:"item.drag-indicator",fn:function(){return [_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.draggable),expression:"draggable"}]},[_vm._v(" mdi-drag-horizontal-variant ")])]},proxy:true},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.$t(("trackingVisualization.visualizationTypes." + (item.type))))+" ")])]}},{key:"item.tag",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"white--text",attrs:{"small":"","color":_vm.getTagOfFirstItem(item)
                ? _vm.stringToColor(_vm.getTagOfFirstItem(item))
                : 'grey'},domProps:{"innerHTML":_vm._s(item.tag)}}),(item.items.length > 1)?_c('v-chip',{staticStyle:{"margin-left":"-10px"},attrs:{"small":""}},[_vm._v(" +"+_vm._s(item.items.length - 1)+" ")]):_vm._e()]}},{key:"item.created_at",fn:function(ref){
                var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.created_at ? _vm.$d(item.created_at, "short") : ""))])]}},{key:"item.updated_at",fn:function(ref){
                var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.updated_at ? _vm.$d(item.updated_at, "short") : ""))])]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"actions"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"small":"","icon":"","to":_vm.getTrackingVisualizationRoute(item)}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("trackingVisualization.action.editVisualization")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-3",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.goToComponent(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-open-in-new ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("trackingVisualization.action.openCmsComponentInNewTab")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-3",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.remove(item.id, item.name)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-minus-circle ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("trackingVisualization.action.removeVisualization")))])])],1)]}},{key:"expanded-item",fn:function(ref){
                var rowHeaders = ref.headers;
                var item = ref.item;
return [_c('td',{staticClass:"expanded-item px-0",attrs:{"colspan":rowHeaders.length}},[_c('div',{staticClass:"d-flex flex-row"},[_c('v-simple-table',{attrs:{"dark":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((_vm.filterItems(item.items)),function(tvItem){return _c('tr',{key:tvItem.id},[_c('td',{attrs:{"width":_vm.dragHeaderWidth}}),_c('td',{attrs:{"width":_vm.nameAndTypeHeaderWidth}},[_vm._v(" "+_vm._s(tvItem.name)+" ")]),_c('td',{attrs:{"width":_vm.idHeaderWidth}},[_vm._v(" "+_vm._s(tvItem.id)+" ")]),_c('td',{attrs:{"width":_vm.tagHeaderWidth}},[_c('v-chip',{staticClass:"white--text",attrs:{"small":"","color":tvItem.content.data.tag
                              ? _vm.stringToColor(tvItem.content.data.tag)
                              : 'grey'},domProps:{"innerHTML":_vm._s(
                            _vm.$t(("cms.names." + (tvItem.content.data.tag)))
                              ? _vm.$t(("cms.names." + (tvItem.content.data.tag)))
                              : 'other'
                          )}})],1),_c('td',{attrs:{"width":_vm.createdAtHeaderWidth}},[_c('div',[_vm._v(" "+_vm._s(tvItem.created_at ? _vm.$d(tvItem.created_at, "short") : "")+" ")])]),_c('td',{attrs:{"width":_vm.updatedAtHeaderWidth}},[_c('div',[_vm._v(" "+_vm._s(tvItem.updated_at ? _vm.$d(tvItem.updated_at, "short") : "")+" ")])])])}),0)]},proxy:true}],null,true)})],1)])]}}])}),_c('div',{staticClass:"d-flex flex-row"},[_c('v-spacer'),(_vm.evaluation)?_c('div',[_c('v-btn',{staticClass:"tt-upper mt-4",attrs:{"color":"primary","to":("/run/" + (_vm.evaluation.run.id) + "/evaluation/" + (_vm.evaluation.id) + "/present"),"target":"_blank","disabled":_vm.evaluation.trackingVisualizations.length === 0}},[_vm._v(" "+_vm._s(_vm.$t("core.action.present"))+" ")])],1):_vm._e()],1)],1)]),(_vm.evaluation)?_c('ShowTrackingVisualizationDialog',{attrs:{"run":_vm.evaluation.run},on:{"created":_vm.applyCreatedTrackingVisualization},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}}):_vm._e(),(_vm.evaluation)?_c('TrackingVisualizationAllocationSidebar',{attrs:{"width":'80%',"stateless":_vm.stateless},model:{value:(_vm.allocationDrawer),callback:function ($$v) {_vm.allocationDrawer=$$v},expression:"allocationDrawer"}},[_c('TrackingVisualizationsView',{attrs:{"allocated-tracking-visualization-ids":_vm.allocatedTrackingVisualizationIds,"evaluation-id":_vm.evaluation.id,"run":_vm.evaluation.run},on:{"allocate":_vm.applyAllocations,"removed":_vm.refetch,"canceled":function($event){_vm.allocationDrawer = false},"dialog-opened":function($event){_vm.stateless = true},"dialog-closed":function($event){_vm.stateless = false}}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }