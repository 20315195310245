























































































































































































































































































































































































































































































































































































































































































































































































import ImageUpload from '@simpl/core/components/uploader/ImageUpload.vue'
import LanguageTags from '@simpl/core/mixins/apollo/LanguageTags'
import UserGroupTags from '@simpl/core/mixins/apollo/UserGroupTags'
import ActiveDomainTags from '@simpl/core/mixins/apollo/ActiveDomainTags'
import TopicTags from '@simpl/core/mixins/apollo/TopicTags'
import StringToColor from '@simpl/core/mixins/utils/StringToColor'
import TagSelectFilter from '@simpl/core/mixins/utils/TagSelectFilter'

import { EditableRun } from '../types'
import { DomainQuotaItem, Query, Tag, User } from '@simpl/core/types/graphql'
import mixins from 'vue-typed-mixins'
import { hasOwn, getTextForUserLanguage, getTextForLanguage } from '@simpl/core/utils'
import FileUpload from '@simpl/core/mixins/apollo/FileUpload'
import EditKeyvisual from '../components/EditKeyvisual.vue'
import { getKeyvisual, updateKeyvisual, removeLanguageKeyvisual } from '../../utils/keyvisual'
// @ts-ignore
import { RunEditUpdates } from './RunEdit.vue'
import ShareLinkDialog from '../components/ShareLinkDialog.vue'
import DomainMarketSelection from '@simpl/core/components/DomainMarketSelection.vue'
import GenericKeyvisuals from '../mixins/GenericKeyvisuals'
import TextareaEditor from '@simpl/core/components/TextareaEditor.vue'
import { LIST_SUPPORT_NOTIFIABLES } from '@simpl/access-control/graphql'
import RunTypes from '../mixins/RunTypes'
import { getLocaleDateTimeFormat } from '@simpl/core/utils/date-time-format'
import { TagWithName } from '@simpl/core/types/extended-types'

export default mixins(
  RunTypes,
  ActiveDomainTags,
  TopicTags,
  StringToColor,
  TagSelectFilter,
  FileUpload,
  GenericKeyvisuals
).extend({
  name: 'RunEditBasic',

  components: { ShareLinkDialog, ImageUpload, EditKeyvisual, TextareaEditor, DomainMarketSelection },

  props: {
    run: Object as () => EditableRun,
    updates: Object as () => RunEditUpdates,
    mode: String,
    currentQuota: Object as () => DomainQuotaItem,
    userGroupTags: Array as () => TagWithName[],
    userGroupTagIds: Array,
    languageTags: Array as () => TagWithName[]
  },

  data () {
    return {
      keyVisualBlob: null as Blob | null,
      keyVisualUrl: undefined as string | null | undefined,
      keyVisualId: null as number | null,
      showAdvancedSettings: false,
      checkInTypes: [{
        text: this.$t('run.global.checkinTypes.none'),
        value: 'none'
      }, {
        text: this.$t('run.global.checkinTypes.pin'),
        value: 'pin'
      }, {
        text: this.$t('run.global.checkinTypes.email'),
        value: 'email'
      }, {
        text: this.$t('run.global.checkinTypes.gov_id'),
        value: 'gov_id'
      }],
      activeSettings: null! as string,
      showLanguageSelect: false,
      masterLanguageId: null! as string,
      contactableUsers: [] as User[],
      rememberPickerMenu: false,
      toggleDetailsCertificate: false
    }
  },

  computed: {
    selectedUserGroupTags (): string[] {
      return this.run.tagIds.filter((id: string) => this.userGroupTagIds.indexOf(id) > -1)
    },
    selectedTopicTags (): string[] {
      return this.run.tagIds.filter((id: string) => this.topicTagIds.indexOf(id) > -1)
    },
    isEvent (): boolean {
      return this.run.type !== 'course'
    },
    dateRange: {
      get (): string[] {
        const range = [] as string[]
        if (this.run.starts_at) range.push(this.run.starts_at)
        if (this.run.ends_at) range.push(this.run.ends_at)

        return range
      },
      set (v: string[]) {
        this.run.starts_at = Date.parse(v[0]) < Date.parse(v[1]) ? v[0] : v[1] || null
        this.run.ends_at = Date.parse(v[0]) < Date.parse(v[1]) ? v[1] : v[0] || null
      }
    },
    masterLanguageTag: {
      get (): Tag | void {
        return this.languageTags?.find((tag: Record<string, any>) => tag?.identifier === this.run.languagecode)
      },
      set (v: Record<string, any>) {
        this.run.languagecode = v.identifier
      }
    },
    masterLanguageKeyVisual (): any {
      return getKeyvisual(this.run) || null
    },
    name: {
      get (): string {
        const updates = this.updates.lang[this.run.languagecode!]
        // eslint-disable-next-line
        const hasUpdates = typeof updates?.display_name === 'string'

        // eslint-disable-next-line
        return hasUpdates ? updates?.display_name : getTextForLanguage(this.run, this.run.languagecode!)
      },
      set (v: string) {
        this.updateLanguage(v, this.run.languagecode!, 'display_name')
      }
    },
    description: {
      get (): string {
        const updates = this.updates.lang[this.run.languagecode!]
        const hasUpdates = typeof updates?.description === 'string'

        return hasUpdates ? updates.description : getTextForLanguage(this.run, this.run.languagecode!, 'description')
      },
      set (v: string) {
        this.updateLanguage(v, this.run.languagecode!, 'description')
      }
    },
    runLanguages (): Record<string, any>[] | null {
      const languageTags = this.languageTags!.filter(tag => this.run.tagIds.indexOf(tag.id) > -1)

      return languageTags.map(tag => {
        const languagecode = tag!.identifier
        const updates = this.updates.lang[languagecode]

        return {
          ...tag,
          keyVisualUrl: getKeyvisual(this.run, languagecode),
          // eslint-disable-next-line
          name: typeof updates?.display_name === 'string'
            ? updates.display_name
            : getTextForLanguage(this.run, languagecode),
          description: typeof updates?.description === 'string'
            ? updates.description
            : getTextForLanguage(this.run, languagecode, 'description')
        }
      })
    },
    quotaLabel (): string {
      return this.$t('plans.runs.quotaLabel', [this.currentQuota.available, this.currentQuota.quota, this.$t(`run.global.${this.mode}s`)]) as string
    },
    allowNewUserRegistration: {
      get (): boolean {
        return !!this.run.properties.allowNewUserRegistration
      },
      set (v: boolean) {
        this.$set(this.run.properties, 'allowNewUserRegistration', v)
      }
    },
    registrationEnabled: {
      get (): boolean {
        return !!this.run.properties.registrationMode && this.run.properties.registrationMode !== 'none'
      },
      set (v: boolean) {
        this.$set(this.run.properties, 'registrationMode', (v) ? 'default' : 'none')
      }
    },
    registrationRestricted: {
      get (): boolean {
        return !!this.run.properties.registrationMode && this.run.properties.registrationMode === 'restricted'
      },
      set (v: boolean) {
        this.$set(this.run.properties, 'registrationMode', (v) ? 'restricted' : 'default')
      }
    },
    registrationMode: {
      get (): string {
        return this.run.properties.registrationMode || 'none'
      },
      set (v: string) {
        this.$set(this.run.properties, 'registrationMode', v)
      }
    },
    generateCertificate: {
      get (): boolean {
        return !!this.run.properties.generateCertificate
      },
      set (v: boolean) {
        this.$set(this.run.properties, 'generateCertificate', v)
      }
    },
    setAnonUsername: {
      get (): boolean {
        return this.run.properties.setAnonUsername
      },
      set (v: boolean) {
        this.$set(this.run.properties, 'setAnonUsername', v)
      }
    },
    contactPerson: {
      get (): string | undefined {
        return this.run.properties.contactPerson?.email
      },
      set (v: string | null) {
        if (v) {
          const selectedContact: User = this.contactableUsers.filter((user: User) => user.email === v)[0]

          this.$set(this.run.properties, 'contactPerson', {
            firstname: selectedContact.firstname,
            lastname: selectedContact.lastname,
            email: selectedContact.email
          })
        } else {
          this.$set(this.run.properties, 'contactPerson', null)
        }
      }
    }
  },

  watch: {
    registrationMode (v) {
      if (v !== 'default') {
        this.allowNewUserRegistration = false
      }
    },
    'run.is_public' (v) {
      if (!v) {
        this.allowNewUserRegistration = false
        this.setAnonUsername = false
      }
    },
    'run.periodic' (newValue) {
      if (newValue && this.run.remember_periodic_at === null) {
        const oneYearFromNow = new Date()
        oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1)
        this.run.remember_periodic_at = oneYearFromNow.toISOString().substr(0, 10)
      } else if (!newValue) {
        this.run.remember_periodic_at = null
      }
    },
    masterLanguageKeyVisual (v, old) {
      if (!v) {
        const genericKeyvisual = this.getRandomGenericKeyvisual()
        updateKeyvisual(this.run, genericKeyvisual)

        return
      }
      if (v.url === old?.url) return
      this.$set(this.updates, 'masterKeyVisualUrl', v.url)
    },
    genericKeyvisuals () {
      if (!this.masterLanguageKeyVisual) {
        updateKeyvisual(this.run, this.getRandomGenericKeyvisual())
      }
    },
    contactableUsers (v) {
      if (v?.length && !this.run.id) {
        this.contactPerson = v.filter((user: User) => user.email === this.$store.state.auth.user.email)[0]?.email
      }
    }
  },

  methods: {
    getTextForUserLanguage,
    getKeyvisual,
    updateKeyvisual,
    removeLanguageKeyvisual,
    getLocaleDateTimeFormat,

    async setAsMasterLanguage (language: Record<string, any>) {
      const confirmed = await this.$confirm({
        message: this.$t('run.notifications.setMasterLanguageConfirm',
                         {
                           current: getTextForUserLanguage(this.masterLanguageTag),
                           new: getTextForUserLanguage(language)
                         }),
        buttons: [{
          text: this.$t('core.global.yes'),
          type: 'outlined',
          answer: true
        }, {
          text: this.$t('core.global.no'),
          answer: false
        }]
      })

      if (confirmed) {
        this.masterLanguageTag = language
      }
    },

    updateLanguage (text: string, language: string, identifier?: string) {
      if (!this.updates.lang[language]) {
        this.$set(this.updates.lang, language, {})
      }

      this.$set(this.updates.lang[language], identifier || 'display_name', text)
    },

    removeLanguage (language: Record<string, any>) {
      const index = this.run.tagIds.indexOf(language.id)
      this.run.tagIds.splice(index, 1)

      if (!this.updates.deletedLanguages) {
        this.$set(this.updates, 'deletedLanguages', [])
      }
      this.updates.deletedLanguages.push(language.identifier)

      removeLanguageKeyvisual(this.run, language.identifier)
    },

    openSettings (name: string) {
      this.showAdvancedSettings = true
      this.activeSettings = name
    },
    remapContactableEntry (entry: User) {
      return {
        ...entry,
        entryName: `${entry.lastname}, ${entry.firstname} (${entry.email})`
      }
    }
  },

  apollo: {
    contactableUsers: {
      query: LIST_SUPPORT_NOTIFIABLES,

      variables: {
        first: 9999,
        filter: {
          filterBy: []
        },
        orderBy: [{
          column: 'lastname',
          order: 'ASC'
        }]
      },

      update (result: Query): User[] {
        return result.supportNotifiables!.data.map(this.remapContactableEntry)
      },

      error (error: Error): void {
        console.error(error)
      }
    }
  }
})
