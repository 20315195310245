import Vue from 'vue'
import { UPLOAD_PACKAGE } from '../../graphql'
import { ModulePackageVerification, UploadFileInput } from '../../types/graphql'
import axios from 'axios'

const cancelToken = axios.CancelToken.source()

export default Vue.extend({
  data: () => ({
    isUploading: false,
    uploadProgress: 0,
    uploadLanguageCode: null! as string,
    uploadFile: null as File | null,
    uploadContentCategory: null as string | null
  }),

  methods: {
    getPackageUploadMutationVariables () {
      const variables: UploadFileInput = {
        selected_file: this.uploadFile,
        name: this.uploadFile!.name,
        target_type: this.uploadContentCategory || 'package'
      }

      if (this.uploadLanguageCode) {
        variables.languagecode = this.uploadLanguageCode
      }

      return variables
    },

    async uploadPackage (): Promise<ModulePackageVerification | { errors: string[] }> {
      this.isUploading = true
      try {
        const result = await this.$apollo.mutate({
          mutation: UPLOAD_PACKAGE,

          variables: {
            file: this.getPackageUploadMutationVariables()
          },

          context: {
            fetchOptions: {
              onUploadProgress: (progress: ProgressEvent) => {
                if (progress.lengthComputable) {
                  this.uploadProgress = progress.loaded / progress.total
                } else {
                  this.uploadProgress = -1
                }
              },
              cancelToken: cancelToken.token
            }
          }
        })

        this.isUploading = false

        return result.data?.uploadModulePackage
      } catch (e) {
        this.isUploading = false

        return {
          errors: e.graphQLErrors.map((e: any) => e.message)
        }
      }
    },

    cancelPackageUpload () {
      this.isUploading = false
      this.uploadFile = null

      cancelToken.cancel('Request canceled.')
    }
  }
})
