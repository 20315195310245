





































































































































import mixins from 'vue-typed-mixins'
import { getTextForUserLanguage } from '@simpl/core/utils'
import ModuleProgressDisplay from '../../components/ModuleProgressDisplay.vue'
import ModuleFormats from '@simpl/core/mixins/utils/ModuleFormats'

export default mixins(ModuleFormats).extend({
  name: 'CourseModuleTile',

  components: {
    ModuleProgressDisplay
  },

  props: {
    module: Object,
    to: String,
    locked: Boolean,
    tracking: Object
  },

  computed: {
    active (): boolean {
      return this.module.active && !this.locked
    },
    moduleContentType (): Record<string, any> {
      return this.getModuleFormat(this.module)!
    }
  },

  methods: {
    getTextForUserLanguage
  }
})
