import { render, staticRenderFns } from "./CourseModuleTile.vue?vue&type=template&id=18b2b7bc&"
import script from "./CourseModuleTile.vue?vue&type=script&lang=ts&"
export * from "./CourseModuleTile.vue?vue&type=script&lang=ts&"
import style0 from "./CourseModuleTile.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VBtn,VCard,VCardText,VDivider,VHover,VIcon,VImg,VResponsive,VSheet})
