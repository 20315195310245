import Vue from 'vue'
import { LIST_TAGS } from '../../graphql'
import { getTextForUserLanguage } from '../../utils'
import { Query, Tag } from '../../types/graphql'

export default Vue.extend({
  data: () => ({
    marketTags: [] as Tag[]
  }),

  computed: {
    marketTagIds (): string[] {
      return this.marketTags.map((item: { id: string }) => item.id)
    }
  },

  methods: {
    marketTagSelectFilter (item: Tag, queryText: string, itemText: string): boolean {
      const displayName = getTextForUserLanguage(item).toLowerCase()
      const nativeName = getTextForUserLanguage(item, 'native_name').toLowerCase()
      const identifier = item.identifier.toLowerCase()

      return displayName.includes(queryText.toLocaleLowerCase()) ||
        nativeName.includes(queryText.toLocaleLowerCase()) ||
        identifier.includes(queryText.toLocaleLowerCase())
    }
  },

  apollo: {
    marketTags: {
      query: LIST_TAGS,

      variables () {
        return {
          first: 9999,
          filter: {
            filterBy: [{
              name: 'identifier',
              values: ['market']
            }]
          },
          orderByRelation: {
            relation: 'texts',
            column: 'text',
            conditions: [{
              column: 'identifier',
              value: 'display_name'
            }, {
              column: 'languagecode',
              value: this.$store.state.auth?.user?.languagecode || 'en'
            }],
            order: 'ASC'
          }
        }
      },

      update (result: Query): Tag[] {
        return result.tagCategories!.data[0].tags.slice().map((r: any) => ({
          ...r,
          name: getTextForUserLanguage(r)
        }))
      },

      error (error: Error) {
        console.error(error)
      }
    }
  }
})
