export function applyIndent (document: Document, range: Range, el: HTMLElement, root: HTMLElement, value: string) {
  if (!range.toString().length) {
    const parentElement = el.style.marginLeft ? el : el.firstChild

    if (!(parentElement as any)?.style?.marginLeft) {
      const range = document.createRange()
      range.selectNodeContents(root)

      applyIndentToRange(document, range, root, value)
    } else {
      if (value === 'increase') {
        indentIncrease(parentElement)
      } else {
        indentDecrease(parentElement)
      }
    }
  } else {
    applyIndentToRange(document, range, el, value)
  }
}

function applyIndentToRange (document: Document, range: Range, el: HTMLElement, value: string) {
  const newElement = document.createElement('div') as any
  [...el.childNodes].forEach((node: Node) => {
    newElement.append(node)
  })
  newElement.style.marginLeft = value === 'increase' ? '20px' : '-20px'
  range.deleteContents()
  range.insertNode(newElement)
}

function indentIncrease (el: any) {
  const oldValue = el.style.marginLeft.replace(/[^\d.-]/g, '')

  el.style.marginLeft = `${parseInt(oldValue) + 20}px`
}

function indentDecrease (el: any) {
  const oldValue = el.style.marginLeft.replace(/[^\d.-]/g, '')

  if (oldValue < 20) return

  el.style.marginLeft = `${parseInt(oldValue) - 20}px`
}
