







































































































import Vue from 'vue'
import { RunEvaluation } from '@simpl/core/types/graphql'
import { getTextForTemporaryUserLanguage, stringToColor } from '@simpl/core/utils'

export default Vue.extend({
  name: 'ItemTile',

  props: {
    item: Object as () => RunEvaluation
  },

  computed: {
    keyVisualUrl (): string | null | undefined {
      // TODO: option for later when evaluations have keyvisuals
      return (this.item as any).keyvisual?.url
    },
    itemType (): string {
      return this.$t(`trackingEvaluation.evaluationTypes.${this.item.type}`) as string
    }
  },

  methods: {
    getTextForTemporaryUserLanguage,
    stringToColor
  }
})
