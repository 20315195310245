












































































































































































































































































































































































































































import SortableJS from 'sortablejs'

import ItemTile from '../../components/ItemTile.vue'
import EditModuleDialog from '../../modules/components/EditModuleDialog.vue'
import LockModuleDialog from './dialogs/LockModuleDialog.vue'
import SearchFilterBar from '@simpl/core/components/SearchFilterBar.vue'

import mixins from 'vue-typed-mixins'
import StringToColor from '@simpl/core/mixins/utils/StringToColor'
import ModuleFormats from '@simpl/core/mixins/utils/ModuleFormats'

import { EditableRun } from '../types'
import { Module } from '@simpl/core/types/graphql'
import { stringToDate } from '@simpl/core/utils/core'
import { UPDATE_RUN } from '../graphql'
import { LIST_MODULES_BY_USAGE } from '../../modules/graphql'
import { getTextForTemporaryUserLanguage, getTextForUserLanguage } from '@simpl/core/utils'
import { PackageFormat } from '@simpl/core/utils/package-formats'
import { TagWithName } from '@simpl/core/types/extended-types'

function remapEntry (entry: Module) {
  return {
    ...entry,
    name: getTextForTemporaryUserLanguage(entry),
    updated_at: entry.updated_at ? stringToDate(entry.updated_at) : entry.updated_at
  }
}

export default mixins(StringToColor, ModuleFormats).extend({
  name: 'RunEditModules',

  components: {
    SearchFilterBar,
    ItemTile,
    EditModuleDialog,
    LockModuleDialog
  },

  props: {
    run: Object as () => EditableRun,
    languageTags: Array as () => TagWithName[]
  },

  data () {
    const featureMode = (this.$route.path.split('/')[1] === 'course') ? 'course' : 'run'
    return {
      createModuleDialog: false,

      modules: [] as Module[],
      showModuleList: false,

      showLockModuleDialog: false,
      selectedModule: null! as Module,

      loadingModules: 1,
      limit: 6,
      filterType: undefined as string | undefined,
      searchValue: '',

      featureMode,

      togglePropertyLoading: {} as Record<string, boolean>,

      moduleListDirty: false

    }
  },

  computed: {
    headers (): Record<string, any>[] {
      const headers = [{
        text: '',
        value: 'drag-indicator'
      }, {
        text: '',
        value: 'active'
      }, {
        text: this.$t('core.global.name'),
        value: 'name'
      }, {
        text: this.$t('core.global.type'),
        value: 'type'
      }, {
        text: this.$t('core.global.trackingType'),
        value: 'tracking_type'
      }, {
        text: this.$t('core.global.languages'),
        value: 'languages'
      }, {
        text: this.$t('core.global.duration'),
        value: 'duration'
      }, {
        text: '',
        value: 'actions-cms',
        class: 'separate-right',
        width: 15
      }, {
        text: '',
        value: 'actions',
        class: 'separate-left',
        width: 200
      }]
      return headers
    },

    runModuleIds (): string[] {
      return this.run!.modules!.map(module => module!.id)
    },

    filteredModules (): Module[] {
      let modules = this.modules

      if (this.filterType) {
        modules = modules.filter(module => module.type === this.filterType)
      }

      if (this.searchValue) {
        modules = modules.filter(module => getTextForUserLanguage(module).toLowerCase().includes(this.searchValue.toLowerCase()) ||
          module.identifier.includes(this.searchValue.toLowerCase()))
      }

      return modules
    },

    displayedModules (): Module[] {
      return this.filteredModules.slice(0, this.limit)
    },

    isConsecutive: {
      get (): boolean {
        return this.run.properties?.consecutive || false
      },
      set (v: boolean) {
        this.$set(this.run.properties, 'consecutive', v)
        this.toggleProperty('consecutive')
      }
    },

    filterModuleFormats (): PackageFormat[] {
      return this.moduleFormats.filter(moduleFormat => moduleFormat.key !== 'generic')
    },

    remoteControlled: {
      get (): boolean {
        return !!this.run.properties.remoteControlled
      },
      set (v: boolean) {
        this.$set(this.run.properties, 'remoteControlled', v)
        this.toggleProperty('remoteControlled')
      }
    }
  },

  apollo: {
    modules: {
      query: LIST_MODULES_BY_USAGE,

      fetchPolicy: 'no-cache',

      loadingKey: 'loadingModules',

      update (data) {
        const values = data.modulesByUsage.slice()

        this.initSortableTable()
        this.loadingModules = 0

        return values.map(remapEntry)
      }
    }
  },

  watch: {
    showModuleList (v) {
      if (v) return
      this.saveUpdateModules()
      this.moduleListDirty = false
    }
  },

  methods: {
    async toggleProperty (property: string) {
      this.togglePropertyLoading = {
        ...this.togglePropertyLoading,
        [property]: true
      }
      await this.$apollo.mutate({
        mutation: UPDATE_RUN,
        variables: {
          data: {
            id: this.run.id,
            properties: JSON.stringify({
              ...this.run.properties,
              [property]: this.run.properties[property]
            })
          }
        }
      })
      this.$emit('saved')
      this.togglePropertyLoading = {
        ...this.togglePropertyLoading,
        [property]: false
      }
    },
    async saveUpdateModules () {
      await this.$apollo.mutate({
        mutation: UPDATE_RUN,
        variables: {
          data: {
            id: this.run.id,
            modules:
              {
                sync: this.run.modules.map((module: Module) => (
                  { id: module.id, properties: JSON.stringify(module.run_pivot?.properties || {}) }
                ))
              },
            properties: JSON.stringify({
              ...this.run.properties,
              moduleAgenda: this.run!.modules!.map(m => m!.id)
            })
          }
        }
      })
      this.$emit('saved')
    },
    getTextForTemporaryUserLanguage,
    stringToDate,
    remove (id: string) {
      this.run!.modules = this.run!.modules!.filter(m => m!.id !== id)
      if (this.run!.properties.moduleAgenda) {
        this.run!.properties.moduleAgenda.splice(this.run!.properties.moduleAgenda.indexOf(id), 1)
      }
      this.saveUpdateModules()
    },
    handleRowClick (item: Module) {
      this.$router.push(`/${this.featureMode}/${this.run.id}/content/${item.id}/basic`)
    },
    getModuleLanguageVariants (module: Module) {
      return module.properties?.languageVariants || []
    },
    updateModules (v: any[]) {
      this.$nextTick(() => {
        this.run!.modules = v.reduce((acc, c) => {
          const module = this.run.modules.find(m => m.id === c) || this.modules.find(m => m.id === c)!
          acc.push(module)
          return acc
        }, [])
        this.$set(this.run.properties, 'moduleAgenda', this.run!.modules!.map(m => m!.id))
        this.moduleListDirty = true
      })
    },
    async addModule (module: Module) {
      this.loadingModules = 1
      await this.$apollo.queries.modules.refetch()
      this.run.modules!.push(module)
      if (this.run.properties.moduleAgenda) {
        this.$set(this.run.properties, 'moduleAgenda', this.run!.modules!.map(m => m!.id))
      }
      this.saveUpdateModules()
      this.loadingModules = 0
    },
    lockModule (module: Module) {
      this.selectedModule = module
      this.showLockModuleDialog = true
    },
    initSortableTable () {
      if ((this as any)._sortable) {
        (this as any)._sortable.destroy()
      }

      this.$nextTick(() => {
        const table: HTMLElement | null =
          (this.$el as HTMLElement).querySelector('.v-data-table tbody')
        if (!table) return;
        (this as any)._sortable = SortableJS.create(table, {
          onEnd: ({ newIndex, oldIndex }: any): void => {
            const item = this.run!.modules!.splice(oldIndex!, 1)[0]
            this.run!.modules!.splice(newIndex!, 0, item)
            this.$set(this.run.properties, 'moduleAgenda', this.run!.modules!.map(m => m!.id))
            this.saveUpdateModules()
          }
        })
      })
    },
    openPreview (moduleId: number) {
      window.open(`/content-preview/${moduleId}`, '_blank')
    }
  }
})
