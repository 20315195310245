var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"tvt-8-sheet full-height flex-grow-1"},[_c('v-card-text',[_c('SearchFilterBar',{staticClass:"flex-grow-0 mb-6 mr-1",attrs:{"search-value":_vm.searchValue},on:{"update:searchValue":function($event){_vm.searchValue=$event},"update:search-value":function($event){_vm.searchValue=$event}}}),_c('v-data-table',{attrs:{"loading":_vm.loading > 0,"headers":_vm.headers,"items":_vm.presentedData,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"footer-props":{
        'items-per-page-options': [1, 2, 3, 4, 5]
      }},on:{"update:page":function($event){_vm.page=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"current-items":_vm.setCurrentItems},scopedSlots:_vm._u([{key:"header.selected",fn:function(){return [_c('v-checkbox',{on:{"change":_vm.selectAll},model:{value:(_vm.isSelected),callback:function ($$v) {_vm.isSelected=$$v},expression:"isSelected"}})]},proxy:true},{key:"item.tag",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{staticClass:"white--text",attrs:{"small":"","color":_vm.getTagOfFirstItem(item) ? _vm.stringToColor(_vm.getTagOfFirstItem(item)) : 'grey'},domProps:{"innerHTML":_vm._s(item.tag)}}),(item.items && item.items.length > 1)?_c('v-chip',{staticStyle:{"margin-left":"-10px"},attrs:{"small":""}},[_vm._v(" +"+_vm._s(item.items.length - 1)+" ")]):_vm._e()]}},{key:"item.selected",fn:function(ref){
      var item = ref.item;
return [_c('v-checkbox',{attrs:{"value":item.id},model:{value:(_vm.selectedTrackingVisualizationItems),callback:function ($$v) {_vm.selectedTrackingVisualizationItems=$$v},expression:"selectedTrackingVisualizationItems"}})]}},{key:"item.created_at",fn:function(ref){
      var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.created_at ? _vm.$d(item.created_at, 'short') : ''))])]}},{key:"item.updated_at",fn:function(ref){
      var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.updated_at ? _vm.$d(item.updated_at, 'short') : ''))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }