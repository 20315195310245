





































































































































































import mixins from 'vue-typed-mixins'
import FilterView from '@simpl/core/components/FilterView.vue'
import StringToColor from '@simpl/core/mixins/utils/StringToColor'
import UserSettings from '@simpl/core/mixins/utils/UserSettings'
import { getTextForUserLanguage, hasOwn } from '@simpl/core/utils'
import LanguageTags from '@simpl/core/mixins/apollo/LanguageTags'
import {
  FilterColumn,
  OrderByClause,
  OrderByRelationClause,
  Tag,
  User,
  Query,
  SortOrder,
  Wave, DomainQuotas
} from '@simpl/core/types/graphql'
import { ALLOCATE_PARTICIPANTS, LIST_PARTICIPANTS } from '../graphql'

type TagWithName = Tag & { name: string }

type UserWithRemappedTags = User & {
  secondaryLanguages: TagWithName[],
  primaryLanguage: string,
  userGroups: string[]
}

export default mixins(
  StringToColor,
  UserSettings('allocateParticipants', [
    'sortBy',
    'sortDesc',
    'selectedView',
    'itemsPerPage'
  ])).extend({
  name: 'ParticipantAllocationSidebar',

  components: {
    FilterView
  },

  props: {
    value: Boolean,
    id: [Number, String],
    allocatedParticipants: Array,
    run: Object,
    waveList: Array,
    width: [Number, String],
    domainQuotas: Object as () => DomainQuotas,
    languageTags: Array as () => TagWithName[]
  },

  data: () => ({
    participants: [] as UserWithRemappedTags[],

    selectedParticipants: [] as string[],

    /** Search and filter */
    searchValue: '',
    sortBy: [] as any[],
    sortDesc: [] as any[],
    count: 1,
    page: 1,
    itemsPerPage: 10,
    filterBy: [] as FilterColumn[],
    showFilter: false,

    relationData: {
      column: 'progress',
      relation: 'run_user'
    },

    loading: 0,

    waveSelection: '',
    statusSelection: '',

    showDatePicker: false
  }),

  computed: {
    show: {
      get (): boolean {
        return this.value
      },
      set (v: boolean) {
        this.$emit('input', v)
      }
    },
    canAllocate (): boolean {
      return this.statusSelection !== '' &&
        ((this.waveSelection !== '' && this.run.dedicated_waves) ||
          !this.run.dedicated_waves) &&
        this.selectedParticipants.length > 0 &&
        this.loading === 0 &&
        (this.selectedParticipants.length <= this.availablePlaces || this.availablePlaces === -1)
    },
    headers (): Record<string, any> {
      /** Table view */
      return [{
        text: '',
        value: 'selected',
        sortable: false
      }, {
        text: this.$t('core.global.username'),
        value: 'username'
      }, {
        text: this.$t('core.global.mail'),
        value: 'email'
      }, {
        text: this.$t('core.global.firstName'),
        value: 'firstname'
      }, {
        text: this.$t('core.global.lastName'),
        value: 'lastname'
      }, {
        text: this.$t('core.global.language'),
        value: 'languagecode'
      }, {
        text: this.$t('core.global.status'),
        value: 'status',
        sortable: false
      }]
    },
    orderBy (): OrderByClause[] {
      return this.sortBy
        .map((key: any, index: number) => ({
          column: this.sortBy[index],
          order: this.sortDesc[index] ? SortOrder.Desc : SortOrder.Asc
        }))
        .filter((key: any) => key !== this.relationData.column)
    },
    orderByRelation (): OrderByRelationClause {
      const index = this.sortBy.indexOf(this.relationData.column)
      return index > -1
        ? {
          ...this.relationData,
          order: this.sortDesc[index] ? SortOrder.Desc : SortOrder.Asc
        }
        : {}
    },
    filterOptions (): Record<string, any> {
      return [
        {
          type: 'autocomplete',
          props: {
            headline: 'core.global.languages',
            subheadline: 'filter.global.languages',
            label: 'core.action.selectLanguages',
            items: this.languageTags.map((language: Tag) => {
              return {
                id: language.id,
                identifier: language.identifier,
                name: getTextForUserLanguage(language)
              }
            })
          },
          relationColumn: 'identifier',
          filterColumn: 'values',
          model: 'tags'
        }
      ]
    },
    statusList (): Record<string, string>[] {
      if (this.run.checkin_type !== 'pin') {
        return [
          { key: 'invite_queued', text: this.$t('run.registerParticipant.invite') as string },
          { key: 'registered', text: this.$t('run.registerParticipant.silentRegister') as string },
          { key: 'register_queued', text: this.$t('run.registerParticipant.register') as string }
        ]
      } else {
        return [
          { key: 'invite_queued', text: this.$t('run.registerParticipant.invite') as string },
          { key: 'register_queued', text: this.$t('run.registerParticipant.register') as string }
        ]
      }
    },
    availablePlaces (): number {
      if (this.waveSelection !== '') {
        const wave = this.getWaveById(this.waveSelection) as Wave
        if (wave) {
          if (wave.participant_max === -1) {
            return Infinity
          }
          return wave.participant_max - wave.participant_count
        }
      }
      return -1
    },
    alertType (): string {
      if (this.waveSelection !== '') {
        if (this.availablePlaces - this.selectedParticipants.length === 0) {
          return 'warning'
        } else if (this.availablePlaces - this.selectedParticipants.length < 0) {
          return 'error'
        }
      }
      return 'success'
    },
    currentAvailableOfQuota (): number {
      if (this.domainQuotas && hasOwn(this.domainQuotas, 'run_participants') && this.domainQuotas.run_participants.quota !== -1) {
        return this.domainQuotas.run_participants.quota - (this.allocatedParticipants.length + this.selectedParticipants.length)
      }
      return 0
    }
  },

  methods: {
    async applyAllocations () {
      this.loading = 1

      const data = {
        run_id: this.run.id,
        users: this.selectedParticipants,
        status: this.statusSelection
      } as any

      if (this.waveSelection !== '') {
        const selectedWave = this.waveList.filter((wave: any) => wave.id === this.waveSelection)[0] as Record<string, any>
        data.wave_id = selectedWave.id
      }
      try {
        await this.$apollo.mutate({
          mutation: ALLOCATE_PARTICIPANTS,
          variables: {
            data: data
          }
        })

        this.$notification.publish('bottom', {
          message: 'Users successfully allocated',
          type: 'success',
          color: 'success'
        })
      } catch (e) {
        this.$notification.publish('bottom', {
          message: this.$t(e.message),
          type: 'error',
          color: 'error'
        })
      }
      this.loading = 0
      this.show = false
      this.selectedParticipants = []
      this.statusSelection = ''
      this.waveSelection = ''
      this.$emit('allocated')
    },
    cancelAllocations () {
      this.show = false
    //  TODO: RESET VALUES...
    },
    getPrimaryLanguage (user: User): string {
      const languageTag = user.tags.filter((tag: Tag) => tag!.category!.identifier === 'language' &&
        tag!.identifier === user.languagecode)
      return getTextForUserLanguage(languageTag[0])
    },
    getLanguageCodes (user: User): TagWithName[] {
      return user.tags.filter((tag: Tag) => tag!.category!.identifier === 'language').map((tag: Tag) => {
        return {
          ...tag,
          name: getTextForUserLanguage(tag)
        }
      })
    },
    remapEntry (entry: User): UserWithRemappedTags {
      return {
        ...entry,
        secondaryLanguages: this.getLanguageCodes(entry),
        primaryLanguage: this.getPrimaryLanguage(entry),
        userGroups: entry.tags.filter((tag: Tag) => tag!.category!.identifier === 'usergroup')
          .map((tag: Tag) => getTextForUserLanguage(tag))
        // TODO: status of allocation per entry here
      }
    },
    getStatus (item: Record<string, any>): string {
      const allocatedFound: any[] = this.allocatedParticipants
        .filter((participant: any) => participant.id === item.id)
      if (allocatedFound.length > 0) {
        return allocatedFound[0]!.run_evaluation!.status as string
      } else {
        return 'none'
      }
    },
    selectAll (v: boolean) {
      this.selectedParticipants = []

      if (v) {
        this.selectedParticipants = this.participants.map((x: any) => x.id)
          .filter((f: any) => !this.allocatedParticipants.map((y: any) => y.id).includes(f))
      }
    },
    getWaveById (waveId: string) {
      const waves = this.waveList.filter((wave: any) => wave.id === waveId)
      return (waves.length) ? waves[0] : null
    },
    getPlacesAvailableText (waveId: string) {
      const wave: Wave = this.getWaveById(waveId) as Wave
      if (wave) {
        if (wave.participant_max === -1) {
          return this.$t('run.registerParticipant.quotaAllAvailable') as string
        }
        if (wave.participant_max - wave.participant_count <= 0) {
          return this.$t('run.registerParticipant.noQuotaAvailable') as string
        }
        return this.$t('run.registerParticipant.quotaAvailable', [
          wave.participant_max - wave.participant_count - this.selectedParticipants.length
        ]) as string
      }
      return ''
    }
  },

  apollo: {
    participants: {
      query: LIST_PARTICIPANTS,

      fetchPolicy: 'cache-and-network',

      variables (): Record<string, any> {
        return {
          filter: {
            search: {
              query: this.searchValue,
              columns: ['username', 'email', 'firstname', 'lastname']
            },
            filterBy: this.filterBy
          },
          orderBy: this.orderBy,
          orderByRelation: this.orderByRelation,
          page: this.page,
          first: this.itemsPerPage
        }
      },

      update (result: Query): Record<string, any> {
        const { total, currentPage, perPage } = result!.participants!.paginatorInfo
        this.count = total
        this.page = currentPage
        this.itemsPerPage = perPage

        return result!.participants!.data!.map(this.remapEntry)
      },

      loadingKey: 'loading'
    }
  }
})
