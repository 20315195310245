import {
  getNodeTextContent,
  getSelectionText,
  isTextNode,
  restoreSelection
} from './helper'

const originalSelection = {} as Record<string, any>

export function applyColor (
  document: Document,
  range: Range,
  el: HTMLElement,
  color: string,
  marker: number,
  selectionLength: number
) {
  if (!range.toString().length) return

  if (!el.childNodes.length) {
    if (el.style?.color) {
      changeColor(el, color)
    } else {
      applyColorToRange(document, range, color)
    }
  } else {
    marker = range.startOffset
    selectionLength = range.toString().length

    editChildElements(document, range, [...el.childNodes], color, marker, selectionLength)
  }
}

function applyColorToRange (document: Document, range: Range, color: string, el?: HTMLElement) {
  const colorElement = document.createElement('span') as any
  if (el) {
    colorElement.append(el)
  } else {
    const rangeContent = document.createTextNode(range.toString())
    colorElement.append(rangeContent)
  }
  colorElement.style.color = color
  range.deleteContents()
  range.insertNode(colorElement)
}

function editChildElements (
  document: Document,
  range: Range,
  children: any[],
  color: any,
  marker: number,
  selectionLength: number
) {
  const childrenInRange = children.filter((child: any) => range.intersectsNode(child))

  childrenInRange.forEach((child: any, index: number) => {
    if (child.children?.length > 1) {
      editChildElements(document, range, [...child.childNodes], color, marker, selectionLength)
    }

    if (child.style?.color) {
      changeColor(child, color)
    } else {
      const nodeText = getNodeTextContent(child)
      const selection = getSelectionText(nodeText!, marker, selectionLength)

      if (nodeText) {
        const rangeContainer = child

        const endOffset = nodeText.length - marker < selectionLength
          ? nodeText.length
          : selectionLength + marker

        const range = document.createRange()

        if (!isTextNode(child)) {
          range.selectNode(child)
          applyColorToRange(document, range, color, child)
        } else {
          range.setStart(rangeContainer, marker)
          range.setEnd(rangeContainer, endOffset)
          applyColorToRange(document, range, color)
        }

        if (index === 0) {
          originalSelection.startContainer = range.startContainer
          originalSelection.startOffset = range.startOffset
        }

        if (index === childrenInRange.length - 1) {
          originalSelection.endContainer = range.endContainer
          originalSelection.endOffset = range.endOffset
        }

        marker = 0
        selectionLength = selectionLength - selection.length
      }
    }
  })

  restoreSelection(originalSelection)
}

function changeColor (el: any, color: string) {
  el.style.color = color
}
