
































































































































































































































































































































import mixins from 'vue-typed-mixins'
import {
  LIST_SESSIONS, CLEAR_SESSION_TRACKINGS,
  SESSION_REVOKED,
  SESSION_STARTED, SESSION_TRACKING_STORED,
  SESSION_UPDATED, UPDATE_RUN
} from '../graphql'
import FilterView from '@simpl/core/components/FilterView.vue'
import StringToColor from '@simpl/core/mixins/utils/StringToColor'
import UserSettings from '@simpl/core/mixins/utils/UserSettings'
import { getTextForUserLanguage } from '@simpl/core/utils'
import ShareLinkDialog from '../components/ShareLinkDialog.vue'
import LockModuleDialog from './dialogs/LockModuleDialog.vue'
import ModuleFormats from '@simpl/core/mixins/utils/ModuleFormats'
import {
  FilterColumn, Module,
  OrderByClause,
  OrderByRelationClause, Query,
  Session,
  SortOrder, User
} from '@simpl/core/types/graphql'
import { DataTableHeader } from '@simpl/core/types/table'
import type PresenceChannel from 'pusher-js/types/src/core/channels/presence_channel'
import ShareModuleLinkDialog from '../../modules/components/ShareModuleLinkDialog.vue'
import { getLocaleDateTimeFormat } from '@simpl/core/utils/date-time-format'

function getDateFilter (): FilterColumn[] {
  const today = new Date()
  const date = today.getFullYear() +
    '-' +
    String(today.getMonth() + 1).padStart(2, '0') +
    '-' +
    String(today.getDate()).padStart(2, '0')

  return [{
    name: 'created_at',
    between: [date + ' 00:00:00', date + ' 23:59:59']
  }]
}

export default mixins(StringToColor, ModuleFormats, UserSettings('runEditSessions', [
  'sortBy',
  'sortDesc',
  'selectedView',
  'itemsPerPage'
])).extend({
  name: 'RunEditSessionTracking',

  components: { ShareLinkDialog, FilterView, LockModuleDialog, ShareModuleLinkDialog },

  props: {
    run: Object
  },

  data: () => {
    return {
      selectedModuleIndex: 0,
      selectedTab: 0,

      /** Displayed data */
      sessions: [] as Session[],

      /** Search and filter */
      searchValue: '',
      searchField: 'token',
      filterBy: getDateFilter(),
      sortBy: [] as any[],
      sortDesc: [] as any[],
      count: 1,
      page: 1,
      itemsPerPage: 9999,
      showFilter: false,

      presenceChannel: null! as PresenceChannel,

      relationData: {
        column: 'email',
        relation: 'users'
      },

      launchModuleLoading: null as string | null,
      moduleUpdating: null as string | null,
      toggleRunActiveLoading: false,

      showOnlineUsersDrawer: false,
      showLockModuleDialog: false,
      showModuleQRDialog: false,
      onlineUsers: [] as any[],

      loading: 0
    }
  },

  computed: {
    headers (): DataTableHeader[] {
      /** Table view */
      return [{
        text: this.$t('core.global.active'),
        value: 'status'
      }, {
        text: this.$t('core.global.username'),
        value: 'emailOrToken',
        sortable: false
      }, {
        text: this.$t('core.global.progress'),
        value: 'progress',
        sortable: false
      }, {
        text: this.$t('core.global.score'),
        value: 'score',
        sortable: true
      }, {
        text: this.$t('core.global.status'),
        value: 'trackingStatus',
        sortable: false
      }, {
        text: this.$t('core.global.createdAt'),
        value: 'created_at',
        sortable: true
      }, {
        text: '',
        value: 'actions',
        class: 'separate-left',
        sortable: false,
        width: 70
      }]
    },
    orderBy (): OrderByClause[] {
      return this.sortBy
        .map((key: any, index: number) => ({
          column: this.sortBy[index],
          order: this.sortDesc[index] ? SortOrder.Desc : SortOrder.Asc
        }))
        .filter((key: any) => key !== this.relationData.column)
    },
    orderByRelation (): OrderByRelationClause {
      const index = this.sortBy.indexOf(this.relationData.column)
      return index > -1
        ? {
          ...this.relationData,
          order: this.sortDesc[index] ? SortOrder.Desc : SortOrder.Asc
        }
        : {}
    },
    filterOptions (): Record<string, any>[] {
      return [{
        type: 'date-range',
        props: {
          headline: 'core.global.createdAt',
          format: 'datetime'
        },
        filterColumn: 'between',
        model: 'created_at'
      }]
    },
    modules (): Module[] {
      return this.run?.modules || []
    },
    selectedModuleIdentifier (): string {
      return this.modules[this.selectedModuleIndex].identifier
    },
    currentModule (): Module {
      return this.modules.filter((module: Module) => module.identifier === this.selectedModuleIdentifier)[0]!
    },
    sessionHeadline (): string {
      const moduleName = getTextForUserLanguage(this.currentModule)
      return `${moduleName} (${this.sessions.length} ${this.$t('run.editSessions.sessions')})`
    },
    headline (): string {
      return `${getTextForUserLanguage(this.run)} - ${this.$t('run.editSessions.sessions')}`
    },
    tabs (): any[] {
      return [{
        text: this.$t('run.editAgenda.availableModules'),
        value: 'modules'
      }, {
        text: this.sessionHeadline,
        value: 'session'
      }]
    },
    channelConnected (): boolean {
      return this.$pusher.connection.state === 'connected'
    }
  },

  watch: {
    presenceChannel: {
      deep: true,
      handler (v) {
        if (!v) return

        this.onlineUsers = Object.values(this.presenceChannel.members.members)
          .sort((a: any, b: any) => (a.username < b.username) ? -1 : 1)
          .filter((user: any) => user.username !== this.$store.state.auth.user.email)
      }
    }
  },

  created () {
    this.presenceChannel = this.$pusher.subscribe(`presence-users-online-${this.run.identifier}`) as PresenceChannel
  },
  mounted () {
    if (!this.modules[this.selectedModuleIndex].active) {
      this.selectedModuleIndex = this.modules.findIndex(module => module.active)
    }
  },

  methods: {
    getLocaleDateTimeFormat,

    selectModule (module: Module, index: number) {
      if (!module.active) return

      if (this.selectedModuleIndex !== index) {
        this.selectedModuleIndex = index
        this.sessions = []
        this.loading = 1
      }
    },
    async toggleRunActive () {
      this.toggleRunActiveLoading = true
      await this.$apollo.mutate({
        mutation: UPDATE_RUN,
        variables: {
          data: {
            id: this.run.id,
            active: this.run.active
          }
        }
      })
      this.toggleRunActiveLoading = false
    },
    async toggleModuleActive (module: Module) {
      const newValue = !module.run_pivot?.properties?.deactivated

      this.moduleUpdating = module.identifier
      const result = await this.$apollo.mutate({
        mutation: UPDATE_RUN,
        variables: {
          data: {
            id: this.run.id,
            modules: {
              sync: [
                ...this.run.modules.map((module: Module) => ({ id: module.id })),
                {
                  id: module.id,
                  properties: JSON.stringify({ ...module.run_pivot?.properties, deactivated: newValue })
                }
              ]
            }
          }
        }
      })

      module.run_pivot!.properties.deactivated = result.data.updateRun.modules.find((m: Module) => m.id === module.id).run_pivot!.properties.deactivated

      this.moduleUpdating = null
    },
    async launchModuleOnDevices (module?: Module) {
      const currentModule = module || this.currentModule as Module
      this.launchModuleLoading = currentModule.identifier

      const moduleActive = !this.run.properties.moduleActive || (currentModule as any).identifier !== this.run.properties.moduleActive

      const properties = JSON.stringify({
        ...this.run.properties,
        moduleActive: moduleActive ? (currentModule as any).identifier : null
      })

      const result = await this.$apollo.mutate({
        mutation: UPDATE_RUN,

        variables: {
          data: {
            id: this.run.id,
            properties: properties
          }
        }
      })
      this.launchModuleLoading = null

      // eslint-disable-next-line vue/no-mutating-props
      this.run.properties.moduleActive = result.data.updateRun.properties.moduleActive
    },
    shareModule (index: number) {
      this.selectedModuleIndex = index
      this.showModuleQRDialog = true
    },
    getUsernameOrToken (session: Session, currentModule: Module) {
      return session.users.length && currentModule.tracking_type !== 'anon'
        ? session.users.map(el => el.username || el.email).join(', ')
        : session.data?.anonUsername || session.token
    },
    hasUsername (session: Session, currentModule: Module) {
      return session.users.length &&
        currentModule.tracking_type !== 'anon' &&
        session.users.map(el => el.username).join(', ')
    },
    async clearSessionTrackings (selectedModule?: Module, token?: string) {
      let confirmQuestion = this.$t('run.notifications.clearAllTrackings')
      const data = {
        run_id: this.run.id
      } as any
      if (token) {
        data.token = token
        confirmQuestion = this.$t('run.notifications.clearUserTracking')
      } else if (selectedModule) {
        data.module_id = selectedModule.id
        confirmQuestion = this.$t('run.notifications.clearModuleTrackings')
      }
      const answer = await this.$confirm({
        color: 'error',
        message: confirmQuestion,
        buttons: [{
          text: this.$t('core.action.cancel'),
          type: 'outlined',
          answer: false
        }, {
          text: this.$t('core.action.delete'),
          color: 'error',
          answer: true
        }]
      })

      if (!answer) return

      this.loading += 1
      const result = await this.$apollo.mutate({
        mutation: CLEAR_SESSION_TRACKINGS,

        variables: {
          data: data
        }
      })
      await this.$apollo.queries.sessions.refetch()

      this.loading -= 1
      this.$notification.publish('bottom', {
        message: this.$t('run.notifications.sessionTrackingsCleared'),
        type: 'success',
        color: 'success'
      })
    }
  },

  apollo: {
    sessions: {
      query: LIST_SESSIONS,

      fetchPolicy: 'no-cache',

      variables (): Record<string, any> {
        return {
          run_id: this.run.id,
          filter: {
            search: {
              query: this.searchValue,
              columns: [this.searchField]
            },
            filterBy: this.filterBy.concat({
              name: 'module:identifier',
              values: [(this.currentModule as any).identifier]
            })
          },
          orderBy: [{
            column: 'created_at',
            order: 'DESC'
          }],
          orderByRelation: this.orderByRelation,
          page: this.page,
          first: 9999
        }
      },

      update (result: Query): Session[] {
        const { total, currentPage, perPage } = result.sessions!.paginatorInfo
        this.count = total
        this.page = currentPage
        this.itemsPerPage = perPage

        this.loading = 0

        return result.sessions!.data
      },

      loadingKey: 'loading',

      subscribeToMore: [{
        document: SESSION_STARTED,

        variables (): Record<string, any> {
          return {
            run_id: this.run.id,
            module_id: this.currentModule.id
          }
        },

        updateQuery (previous: Session[], { subscriptionData }: any) {
          let found = false
          this.sessions.forEach((session: Session, index: number) => {
            if (session.token === subscriptionData.data.sessionStarted.token) {
              this.$set(this.sessions, index, subscriptionData.data.sessionStarted)
              found = true
            }
          })

          if (!found) {
            if (!subscriptionData.data.sessionStarted.tracking_status) {
              subscriptionData.data.sessionStarted.tracking_status = {
                progress: 0,
                score: 0,
                status: 'none'
              }
            }
            this.$set(this.sessions, this.sessions.length, subscriptionData.data.sessionStarted)

            if (this.sessions.length === 1) {
              this.$apollo.queries.sessions.refetch()
            } else {
              this.sessions.sort((a: any, b: any) => {
                const desc = (this.sortDesc.length > 0) ? this.sortDesc[0] : true
                const prop = (this.sortBy.length > 0) ? this.sortBy[0] : 'created_at'
                if (a[prop] < b[prop]) {
                  return (desc) ? 1 : -1
                }
                if (a[prop] > b[prop]) {
                  return (desc) ? -1 : 1
                }
                return 0
              })
            }
          }
        }
      }, {
        document: SESSION_UPDATED,

        variables () {
          return {
            run_id: this.run.id,
            module_id: this.currentModule.id
          }
        },
        updateQuery (previous: Session[], { subscriptionData }: any) {
          this.sessions.forEach((session: Session, index: number) => {
            if (session.token === subscriptionData.data.sessionUpdated.token) {
              this.$set(this.sessions, index, subscriptionData.data.sessionUpdated)
            }
          })
        }
      }, {
        document: SESSION_REVOKED,

        variables () {
          return {
            run_id: this.run.id,
            module_id: this.currentModule.id
          }
        },
        updateQuery () {
          this.$apollo.queries.sessions.refetch()
        }
      }, {
        document: SESSION_TRACKING_STORED,

        variables () {
          return {
            run_id: this.run.id,
            module_id: this.currentModule.id
          }
        },
        updateQuery (previous: Session[], { subscriptionData }: any) {
          let found = false
          this.sessions.forEach((session: Session, index: number) => {
            if (session.token === subscriptionData.data.sessionTrackingStored.token) {
              this.$set(this.sessions, index, subscriptionData.data.sessionTrackingStored)
              found = true
            }
          })

          if (!found) {
            if (!subscriptionData.data.sessionTrackingStored.tracking_status) {
              subscriptionData.data.sessionTrackingStored.tracking_status = {
                progress: 0,
                score: 0,
                status: 'none'
              }
            }
            this.$set(this.sessions, this.sessions.length, subscriptionData.data.sessionTrackingStored)

            if (this.sessions.length === 1) {
              this.$apollo.queries.sessions.refetch()
            } else {
              this.sessions.sort((a: any, b: any) => {
                const desc = (this.sortDesc.length > 0) ? this.sortDesc[0] : true
                const prop = (this.sortBy.length > 0) ? this.sortBy[0] : 'created_at'
                if (a[prop] < b[prop]) {
                  return (desc) ? 1 : -1
                }
                if (a[prop] > b[prop]) {
                  return (desc) ? -1 : 1
                }
                return 0
              })
            }
          }
        }
      }] as any
    }
  }
})
