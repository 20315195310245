import { EXPORT_CONTENT, IMPORT_CONTENT } from '../../graphql'
import Vue from 'vue'

export default Vue.extend({
  data () {
    return {
      exporting: false,
      importing: false
    }
  },

  methods: {
    async exportContent (type: String, id: Number) {
      this.exporting = true

      try {
        const result = await this.$apollo.mutate({
          mutation: EXPORT_CONTENT,
          variables: {
            data: {
              id: id,
              type: type
            }
          }
        })

        window.open(result.data.exportContent, '_blank')
      } catch (error: any) {
        this.$notification.publish('bottom', {
          message: this.$t(error.message),
          borderColor: 'error'
        })
      }

      this.exporting = false
    },
    async importContent (type: String, data: String) {
      this.importing = true

      const result = await this.$apollo.mutate({
        mutation: IMPORT_CONTENT,
        variables: {
          data: {
            data: data,
            type: type
          }
        }
      }).catch((e: Error) => {
        this.$notification.publish('bottom', {
          message: this.$t(e.message),
          type: 'error',
          color: 'error'
        })
      })
      this.importing = false
    }
  }
})
