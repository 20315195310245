export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  ContentTree: any;
  /** A date string with format `Y-m-d`, e.g. `2011-05-23`. */
  Date: any;
  /** A datetime string with format `Y-m-d H:i:s`, e.g. `2018-01-01 13:00:00`. */
  DateTime: any;
  Identity: string | number | boolean;
  /** Arbitrary data encoded in JavaScript Object Notation. See https://www.json.org/. */
  JSON: any;
  /**
   * Loose type that allows any value. Be careful when passing in large `Int` or `Float` literals,
   * as they may not be parsed correctly on the server side. Use `String` literals if you are
   * dealing with really large numbers to be on the safe side.
   */
  Mixed: any;
  /** Arbitrary data encoded in JavaScript Object Notation. See https://www.json.org/. */
  Properties: any;
  Tags: any;
  /** Can be used as an argument to upload files using https://github.com/jaydenseric/graphql-multipart-request-spec */
  Upload: any;
  UserSettings: any;
};

export type AcceptInvitationWithPasswordAndCodeInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  password_confirmation: Scalars['String'];
  token: Scalars['String'];
};

export type AccessControlUserRelationsInput = {
  domains?: Maybe<CreateUserDomainsRelation>;
  id: Scalars['ID'];
  roles?: Maybe<CreateUserRolesRelation>;
};

export type AllocateParticipantsInput = {
  group_id?: Maybe<Scalars['ID']>;
  run_id: Scalars['ID'];
  status: Scalars['String'];
  users?: Maybe<Array<Scalars['ID']>>;
  wave_id?: Maybe<Scalars['ID']>;
};

export type AllocationResult = {
  __typename?: 'AllocationResult';
  change_log?: Maybe<Scalars['JSON']>;
  users: Array<Participant>;
};

export type AnonUsernameResult = {
  __typename?: 'AnonUsernameResult';
  status: Scalars['String'];
  username: Scalars['String'];
};

export type AppModuleTheme = {
  __typename?: 'AppModuleTheme';
  file: File;
  key: Scalars['String'];
};

export type AppModuleThemeInput = {
  file_id?: Maybe<Scalars['ID']>;
  key: Scalars['String'];
};

export type ApprovePendingUsersInput = {
  ids: Array<Scalars['ID']>;
  usergroups?: Maybe<Array<Scalars['ID']>>;
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  access_token: Scalars['String'];
  expires_in: Scalars['Int'];
  refresh_token: Scalars['String'];
  token_type: Scalars['String'];
  user: User;
};

export type BadgeAuthPayload = {
  __typename?: 'BadgeAuthPayload';
  auth_payload: AuthPayload;
  run: Run;
};

export type BadgeLoginInput = {
  badge: Scalars['String'];
  client_id: Scalars['Int'];
  client_secret?: Maybe<Scalars['String']>;
  grant_type: Scalars['String'];
};

export type ClearSessionTrackingsInput = {
  module_id?: Maybe<Scalars['ID']>;
  run_id: Scalars['ID'];
  token?: Maybe<Scalars['ID']>;
};

export type ClientLoginInput = {
  client_id: Scalars['Int'];
  client_secret: Scalars['String'];
  grant_type: Scalars['String'];
};

export type ConnectAttachableInput = {
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type ConnectModuleRun = {
  id: Scalars['ID'];
  properties?: Maybe<Scalars['String']>;
};

export type Content = {
  __typename?: 'Content';
  _lft?: Maybe<Scalars['Int']>;
  _rgt?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['DateTime']>;
  data?: Maybe<Scalars['JSON']>;
  files: Array<File>;
  id: Scalars['ID'];
  module: Module;
  parent?: Maybe<Content>;
  properties?: Maybe<Scalars['Properties']>;
  texts: Array<Text>;
  type: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
};

/** A paginated list of Content items. */
export type ContentPaginator = {
  __typename?: 'ContentPaginator';
  /** A list of Content items. */
  data: Array<Content>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type ContentPreset = {
  __typename?: 'ContentPreset';
  created_at?: Maybe<Scalars['DateTime']>;
  data?: Maybe<Scalars['String']>;
  domain_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  module_id?: Maybe<Scalars['ID']>;
  properties?: Maybe<Scalars['String']>;
  tags: Array<Tag>;
  texts: Array<Text>;
  thumb_desktop?: Maybe<Scalars['String']>;
  thumb_mobile?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

/** A paginated list of ContentPreset items. */
export type ContentPresetPaginator = {
  __typename?: 'ContentPresetPaginator';
  /** A list of ContentPreset items. */
  data: Array<ContentPreset>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};


export type CreateCategoryRelation = {
  connect?: Maybe<Scalars['ID']>;
};

export type CreateDomainConfig = {
  languages?: Maybe<Array<Scalars['String']>>;
  markets?: Maybe<Array<Scalars['String']>>;
  presets?: Maybe<Array<Scalars['JSON']>>;
};

export type CreateDomainRelation = {
  connect?: Maybe<Scalars['ID']>;
};

export type CreateFeatureRelation = {
  connect?: Maybe<Scalars['ID']>;
};

export type CreateFeaturesRelation = {
  connect?: Maybe<Array<Scalars['ID']>>;
};

export type CreateFilesRelation = {
  connect?: Maybe<Array<Scalars['ID']>>;
  delete?: Maybe<Array<Scalars['ID']>>;
  disconnect?: Maybe<Array<Scalars['ID']>>;
  sync?: Maybe<Array<Scalars['ID']>>;
};

export type CreateModuleFileInput = {
  files?: Maybe<CreateFilesRelation>;
  master_file_id?: Maybe<Scalars['ID']>;
  properties?: Maybe<Scalars['Properties']>;
  sorting?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
};

export type CreateModuleFilesRelation = {
  connect?: Maybe<Array<Scalars['ID']>>;
  create?: Maybe<Array<CreateModuleFileInput>>;
  delete?: Maybe<Array<Scalars['ID']>>;
  disconnect?: Maybe<Array<Scalars['ID']>>;
  sync?: Maybe<Array<Scalars['ID']>>;
  update?: Maybe<Array<UpdateModuleFileInput>>;
};

export type CreateModuleLinkInput = {
  is_master?: Maybe<Scalars['Boolean']>;
  languagecode?: Maybe<Scalars['String']>;
  properties?: Maybe<Scalars['Properties']>;
  sorting?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  url: Scalars['String'];
};

export type CreateModuleLinksRelation = {
  connect?: Maybe<Array<Scalars['ID']>>;
  create?: Maybe<Array<CreateModuleLinkInput>>;
  delete?: Maybe<Array<Scalars['ID']>>;
  disconnect?: Maybe<Array<Scalars['ID']>>;
  sync?: Maybe<Array<Scalars['ID']>>;
  update?: Maybe<Array<UpdateModuleLinkInput>>;
};

export type CreateModulePackageInput = {
  is_master?: Maybe<Scalars['Boolean']>;
  languagecode?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  properties?: Maybe<Scalars['Properties']>;
  sorting?: Maybe<Scalars['Int']>;
  starter_file?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type CreateModulePackagesRelation = {
  connect?: Maybe<Array<Scalars['ID']>>;
  create?: Maybe<Array<CreateModulePackageInput>>;
  delete?: Maybe<Array<Scalars['ID']>>;
  disconnect?: Maybe<Array<Scalars['ID']>>;
  sync?: Maybe<Array<Scalars['ID']>>;
  update?: Maybe<Array<UpdateModulePackageInput>>;
};

export type CreateModuleRelation = {
  connect?: Maybe<Scalars['ID']>;
};

export type CreateModulesRelation = {
  connect?: Maybe<Array<ConnectModuleRun>>;
  sync?: Maybe<Array<ConnectModuleRun>>;
};

export type CreateParentRelation = {
  connect: Scalars['ID'];
};

export type CreatePermissionsRelation = {
  connect?: Maybe<Array<Scalars['ID']>>;
  sync?: Maybe<Array<Scalars['ID']>>;
};

export type CreatePlanRelation = {
  connect?: Maybe<Scalars['ID']>;
};

export type CreateRoleRelation = {
  connect?: Maybe<Scalars['ID']>;
};

export type CreateRolesRelation = {
  connect?: Maybe<Array<Scalars['ID']>>;
  sync?: Maybe<Array<Scalars['ID']>>;
};

export type CreateRunRelation = {
  connect?: Maybe<Scalars['ID']>;
};

export type CreateRunsRelation = {
  connect?: Maybe<Array<Scalars['ID']>>;
  sync?: Maybe<Array<Scalars['ID']>>;
};

export type CreateSharedResourceRelation = {
  sync?: Maybe<Array<Scalars['ID']>>;
};

export type CreateTagInput = {
  category?: Maybe<CreateCategoryRelation>;
  identifier: Scalars['String'];
  texts?: Maybe<CreateTextsRelation>;
};

export type CreateTagsRelation = {
  connect?: Maybe<Array<Scalars['ID']>>;
  create?: Maybe<Array<CreateTagInput>>;
  sync?: Maybe<Array<Scalars['ID']>>;
};

export type CreateTextInput = {
  identifier: Scalars['String'];
  languagecode?: Maybe<Scalars['String']>;
  text: Scalars['String'];
};

export type CreateTextsRelation = {
  connect?: Maybe<Array<Scalars['ID']>>;
  create?: Maybe<Array<CreateTextInput>>;
  delete?: Maybe<Array<Scalars['ID']>>;
  sync?: Maybe<Array<Scalars['ID']>>;
};

export type CreateTrackingVisualizationItemRelation = {
  connect?: Maybe<Array<Scalars['ID']>>;
  create?: Maybe<Array<Maybe<NewTrackingVisualizationItemInput>>>;
  sync?: Maybe<Array<Scalars['ID']>>;
};

export type CreateTrackingVisualizationRelation = {
  connect?: Maybe<Array<Scalars['ID']>>;
};

export type CreateTrackingVisualizationsRelation = {
  connect?: Maybe<Array<Scalars['ID']>>;
  create?: Maybe<Array<Maybe<NewTrackingVisualizationInput>>>;
  sync?: Maybe<Array<Scalars['ID']>>;
};

export type CreateUserDomainsRelation = {
  connect?: Maybe<Array<Scalars['ID']>>;
  sync?: Maybe<Array<Scalars['ID']>>;
};

export type CreateUserRolesRelation = {
  connect?: Maybe<Array<Scalars['ID']>>;
  sync?: Maybe<Array<Scalars['ID']>>;
};

export type CreateUserSettingInput = {
  expires_at?: Maybe<Scalars['DateTime']>;
  key: Scalars['String'];
  properties?: Maybe<Scalars['Properties']>;
  res_id?: Maybe<Scalars['ID']>;
  res_type?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

export type CreateUserSettingsRelation = {
  create?: Maybe<Array<CreateUserSettingInput>>;
};

export type CreateUsersRelation = {
  connect?: Maybe<Array<Scalars['ID']>>;
  sync?: Maybe<Array<Scalars['ID']>>;
};


export type DateRange = {
  from: Scalars['Date'];
  to: Scalars['Date'];
};


export type DeleteContentTextsInput = {
  languages: Array<Scalars['String']>;
  module_id: Scalars['ID'];
};

export type Domain = {
  __typename?: 'Domain';
  card_brand?: Maybe<Scalars['String']>;
  card_last_four?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  deleted_at?: Maybe<Scalars['DateTime']>;
  features: Array<Feature>;
  id: Scalars['ID'];
  identifier: Scalars['String'];
  is_demo?: Maybe<Scalars['Boolean']>;
  markets?: Maybe<Scalars['Boolean']>;
  namespace?: Maybe<Scalars['String']>;
  parent?: Maybe<Domain>;
  payment_status?: Maybe<Scalars['String']>;
  payment_type?: Maybe<Scalars['String']>;
  plan?: Maybe<Plan>;
  plan_applied_at?: Maybe<Scalars['Date']>;
  properties?: Maybe<Scalars['Properties']>;
  shared_resources?: Maybe<Array<Maybe<Shareable>>>;
  stripe_id?: Maybe<Scalars['String']>;
  subscription?: Maybe<Scalars['JSON']>;
  tags: Array<Tag>;
  texts: Array<Text>;
  trial_ends_at?: Maybe<Scalars['DateTime']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  user_limit?: Maybe<Scalars['Int']>;
  users: Array<User>;
};

/** A paginated list of Domain items. */
export type DomainPaginator = {
  __typename?: 'DomainPaginator';
  /** A list of Domain items. */
  data: Array<Domain>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type DomainQuotaItem = {
  __typename?: 'DomainQuotaItem';
  available: Scalars['Int'];
  quota: Scalars['Int'];
  type: Scalars['String'];
};

export type DomainQuotas = {
  __typename?: 'DomainQuotas';
  courses: DomainQuotaItem;
  roles: Array<DomainQuotaItem>;
  run_participants: DomainQuotaItem;
  runs: DomainQuotaItem;
  storage: DomainQuotaItem;
};

export type DomainRestricted = {
  __typename?: 'DomainRestricted';
  features: Array<Feature>;
  identifier: Scalars['String'];
  is_demo?: Maybe<Scalars['Boolean']>;
  markets?: Maybe<Scalars['Boolean']>;
  namespace?: Maybe<Scalars['String']>;
  parent?: Maybe<DomainRestricted>;
  payment_status?: Maybe<Scalars['String']>;
  plan?: Maybe<Plan>;
  plan_applied_at?: Maybe<Scalars['Date']>;
  properties?: Maybe<Scalars['Properties']>;
  tags: Array<Tag>;
  texts: Array<Text>;
  trial_ends_at?: Maybe<Scalars['DateTime']>;
};

export type ExportContentInput = {
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type ExtendDemoDomainInput = {
  days: Scalars['Int'];
  id: Scalars['ID'];
};

export type Feature = {
  __typename?: 'Feature';
  active?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  identifier: Scalars['String'];
  properties?: Maybe<Scalars['Properties']>;
  texts: Array<Text>;
};

/** A paginated list of Feature items. */
export type FeaturePaginator = {
  __typename?: 'FeaturePaginator';
  /** A list of Feature items. */
  data: Array<Feature>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type File = {
  __typename?: 'File';
  crc: Scalars['String'];
  created_at?: Maybe<Scalars['DateTime']>;
  domain?: Maybe<Domain>;
  filename: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  languagecode?: Maybe<Scalars['String']>;
  mime_type: Scalars['String'];
  name: Scalars['String'];
  owner?: Maybe<User>;
  path: Scalars['String'];
  size: Scalars['Int'];
  slug: Scalars['String'];
  thumbnail?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

/** A paginated list of File items. */
export type FilePaginator = {
  __typename?: 'FilePaginator';
  /** A list of File items. */
  data: Array<File>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type FilterColumn = {
  between?: Maybe<Array<Maybe<Scalars['Identity']>>>;
  isNull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  not?: Maybe<Array<Maybe<Scalars['Identity']>>>;
  notBetween?: Maybe<Array<Maybe<Scalars['Identity']>>>;
  notNull?: Maybe<Scalars['Boolean']>;
  values?: Maybe<Array<Maybe<Scalars['Identity']>>>;
};

export type FilterInput = {
  filterBy?: Maybe<Array<Maybe<FilterColumn>>>;
  search?: Maybe<SearchInput>;
  textsBy?: Maybe<FilterTextsInput>;
};

export type FilterTextsInput = {
  identifier?: Maybe<Scalars['String']>;
  languagecode?: Maybe<Scalars['String']>;
};

export type ForgotPasswordInput = {
  email: Scalars['String'];
};

export type GenericAuthResponse = {
  __typename?: 'GenericAuthResponse';
  access_token?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  user?: Maybe<User>;
};

export type Group = {
  __typename?: 'Group';
  created_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  identifier: Scalars['String'];
  participant_count: Scalars['Int'];
  participant_max: Scalars['Int'];
  properties?: Maybe<Scalars['Properties']>;
  run?: Maybe<Run>;
  texts: Array<Text>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

/** A paginated list of Group items. */
export type GroupPaginator = {
  __typename?: 'GroupPaginator';
  /** A list of Group items. */
  data: Array<Group>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};


export type ImpersonateInput = {
  user_id: Scalars['ID'];
};

export type ImpersonatePayload = {
  __typename?: 'ImpersonatePayload';
  auth_payload: AuthPayload;
  two_factor_token?: Maybe<Scalars['String']>;
};

export type ImportContentInput = {
  data: Scalars['String'];
  type: Scalars['String'];
};

export type ImportContentTextsResult = {
  __typename?: 'ImportContentTextsResult';
  errors: Array<Maybe<Scalars['String']>>;
  imports: Array<Maybe<Scalars['String']>>;
  status: Scalars['String'];
  warnings: Array<Maybe<Scalars['String']>>;
};

export type ImportUsersInput = {
  invite_now: Scalars['Boolean'];
  languagecode: Scalars['String'];
  role_id: Scalars['ID'];
  tag_ids: Array<Scalars['ID']>;
  users: Array<NewUserInput>;
};

export type ImportUsersReport = {
  __typename?: 'ImportUsersReport';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type InviteInput = {
  user_id: Scalars['ID'];
};

export type InviteResponse = {
  __typename?: 'InviteResponse';
  email: Scalars['String'];
  id: Scalars['ID'];
  invitation_sent: Scalars['DateTime'];
  message?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};


export type LearningPath = {
  __typename?: 'LearningPath';
  created_at?: Maybe<Scalars['DateTime']>;
  domain: Domain;
  duration?: Maybe<Scalars['Int']>;
  files: Array<File>;
  id: Scalars['ID'];
  identifier: Scalars['String'];
  languagecode?: Maybe<Scalars['String']>;
  mandatory_level?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Properties']>;
  runs: Array<Run>;
  tags: Array<Tag>;
  texts: Array<Text>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

/** A paginated list of LearningPath items. */
export type LearningPathPaginator = {
  __typename?: 'LearningPathPaginator';
  /** A list of LearningPath items. */
  data: Array<LearningPath>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type LoginInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};


export type Module = {
  __typename?: 'Module';
  activates_at?: Maybe<Scalars['DateTime']>;
  active?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['DateTime']>;
  domain?: Maybe<Domain>;
  downloads: Array<ModuleFile>;
  duration?: Maybe<Scalars['Int']>;
  expires_at?: Maybe<Scalars['DateTime']>;
  files: Array<File>;
  id: Scalars['ID'];
  identifier: Scalars['String'];
  keyvisual?: Maybe<File>;
  languagecode?: Maybe<Scalars['String']>;
  links: Array<ModuleLink>;
  owner?: Maybe<User>;
  packages: Array<ModulePackage>;
  properties?: Maybe<Scalars['Properties']>;
  run_pivot?: Maybe<ModuleRunPivot>;
  runs: Array<Run>;
  runs_count?: Maybe<Scalars['Int']>;
  sorting?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  sticky?: Maybe<Scalars['Boolean']>;
  tags: Array<Tag>;
  texts: Array<Text>;
  tracking_type?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
  version?: Maybe<Scalars['String']>;
};

export type ModuleBasic = {
  __typename?: 'ModuleBasic';
  active?: Maybe<Scalars['Boolean']>;
  duration?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  identifier: Scalars['String'];
  keyvisual?: Maybe<File>;
  languagecode?: Maybe<Scalars['String']>;
  properties?: Maybe<Scalars['Properties']>;
  sorting?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  sticky?: Maybe<Scalars['Boolean']>;
  texts: Array<Text>;
  tracking_type?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  version?: Maybe<Scalars['String']>;
};

export type ModuleFile = {
  __typename?: 'ModuleFile';
  files: Array<File>;
  id: Scalars['ID'];
  masterFile?: Maybe<File>;
  owner?: Maybe<User>;
  properties?: Maybe<Scalars['JSON']>;
  sorting: Scalars['Int'];
  type: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type ModuleLink = {
  __typename?: 'ModuleLink';
  id: Scalars['ID'];
  is_master: Scalars['Boolean'];
  languagecode?: Maybe<Scalars['String']>;
  owner?: Maybe<User>;
  properties?: Maybe<Scalars['JSON']>;
  sorting: Scalars['Int'];
  type: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

export type ModulePackage = {
  __typename?: 'ModulePackage';
  id: Scalars['ID'];
  is_master: Scalars['Boolean'];
  languagecode?: Maybe<Scalars['String']>;
  owner?: Maybe<User>;
  path?: Maybe<Scalars['String']>;
  properties?: Maybe<Scalars['JSON']>;
  sorting: Scalars['Int'];
  starter_file?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type ModulePackageVerification = {
  __typename?: 'ModulePackageVerification';
  message: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  starter_file?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  type: Scalars['String'];
  xsd_file_validations?: Maybe<Scalars['Int']>;
  xsd_files_found?: Maybe<Scalars['Int']>;
};

/** A paginated list of Module items. */
export type ModulePaginator = {
  __typename?: 'ModulePaginator';
  /** A list of Module items. */
  data: Array<Module>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type ModuleRunPivot = {
  __typename?: 'ModuleRunPivot';
  properties?: Maybe<Scalars['Properties']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Accept invitation and set password from invitation mail link */
  acceptInvitation: AuthPayload;
  /** Allocate participants to run */
  allocateParticipants?: Maybe<AllocationResult>;
  /** Append content */
  appendContent?: Maybe<Scalars['Boolean']>;
  approvePendingUsers?: Maybe<Array<User>>;
  /** Archive session */
  archiveSessionAndRestart?: Maybe<Session>;
  /** Checkin participant to run */
  checkinParticipant?: Maybe<Participant>;
  /** Delete sessions and all trackings */
  clearSessionTrackings?: Maybe<Scalars['Boolean']>;
  /** Create account */
  createAccount?: Maybe<User>;
  createAndMergeContent?: Maybe<Scalars['Boolean']>;
  /** Create content */
  createContent?: Maybe<Content>;
  /** Create contentPreset */
  createContentPreset?: Maybe<ContentPreset>;
  /** Create domain */
  createDomain?: Maybe<Domain>;
  /** Create group */
  createGroup?: Maybe<Group>;
  /** Create learningPath */
  createLearningPath?: Maybe<LearningPath>;
  /** Create module */
  createModule?: Maybe<Module>;
  /** Create permission */
  createPermission?: Maybe<Permission>;
  /** Create plan */
  createPlan?: Maybe<Plan>;
  /** Create role */
  createRole?: Maybe<Role>;
  /** Create run */
  createRun?: Maybe<Run>;
  /** Create runEvaluation */
  createRunEvaluation?: Maybe<RunEvaluation>;
  /** Create tag */
  createTag?: Maybe<Tag>;
  /** Create tagCategory */
  createTagCategory?: Maybe<TagCategory>;
  /** Create trackingVisualization */
  createTrackingVisualization?: Maybe<TrackingVisualization>;
  /** Create trackingVisualizationItem */
  createTrackingVisualizationItem?: Maybe<TrackingVisualizationItem>;
  /** Create user */
  createUser?: Maybe<User>;
  /** Create wave */
  createWave?: Maybe<Wave>;
  deallocateAssets?: Maybe<Scalars['Boolean']>;
  /** Delete content */
  deleteContent?: Maybe<Content>;
  /** Delete contentPreset */
  deleteContentPreset?: Maybe<ContentPreset>;
  /** Delete content texts */
  deleteContentTexts?: Maybe<Scalars['Boolean']>;
  /** Delete domain */
  deleteDomain?: Maybe<Domain>;
  /** Delete a file */
  deleteFeature?: Maybe<Feature>;
  /** Delete a file */
  deleteFile?: Maybe<File>;
  /** Delete group */
  deleteGroup?: Maybe<Group>;
  /** Delete learningPath */
  deleteLearningPath?: Maybe<LearningPath>;
  /** Delete module */
  deleteModule?: Maybe<Module>;
  /** Delete userPending */
  deletePendingUser?: Maybe<PendingUser>;
  /** Delete permission */
  deletePermission?: Maybe<Permission>;
  /** Delete plan */
  deletePlan?: Maybe<Plan>;
  /** Delete role */
  deleteRole?: Maybe<Role>;
  /** Delete run */
  deleteRun?: Maybe<Run>;
  /** Delete runEvaluation */
  deleteRunEvaluation?: Maybe<RunEvaluation>;
  /** Delete tag */
  deleteTag?: Maybe<Tag>;
  /** Delete tagCategory */
  deleteTagCategory?: Maybe<TagCategory>;
  /** Deletes a theme */
  deleteTheme?: Maybe<Theme>;
  /** Delete trackingVisualization */
  deleteTrackingVisualization?: Maybe<TrackingVisualization>;
  /** Delete trackingVisualizationItem */
  deleteTrackingVisualizationItem?: Maybe<TrackingVisualizationItem>;
  /** Delete user */
  deleteUser?: Maybe<User>;
  /** Delete wave */
  deleteWave?: Maybe<Wave>;
  /** Detach or delete user */
  detachOrDeleteUser?: Maybe<User>;
  /** Duplicate run */
  duplicateRun?: Maybe<Run>;
  /** Delete domain */
  eraseDomainWithDependencies?: Maybe<Domain>;
  /** Export content */
  exportContent: Scalars['String'];
  extendDemoDomain?: Maybe<Domain>;
  /** Receive email to send password reset mail */
  forgotPassword: GenericAuthResponse;
  /** Impersonate as another user (only allowed for special user roles!) */
  impersonate: ImpersonatePayload;
  /** Import content */
  importContent: Scalars['Boolean'];
  /** Import content texts */
  importContentTexts?: Maybe<ImportContentTextsResult>;
  /** Import multiple users at once */
  importUsers?: Maybe<ImportUsersReport>;
  /** Insert content after sibling node */
  insertContentAfter: Content;
  /** Insert content before sibling node */
  insertContentBefore: Content;
  /** Invite user to portal */
  invite: InviteResponse;
  /** Login user */
  login: AuthPayload;
  /** Login using QR Badge */
  loginBadge?: Maybe<BadgeAuthPayload>;
  /** Login using client credentials */
  loginClient?: Maybe<AuthPayload>;
  /** Login user after redirect from another domain */
  loginRedirect: AuthPayload;
  /** Logout user */
  logout: GenericAuthResponse;
  /** Inform user about allocation */
  notifyAllocated: InviteResponse;
  /** Opt-Out */
  optOut?: Maybe<Scalars['Boolean']>;
  /** Refresh user token */
  refreshToken: AuthPayload;
  /** Register participant to run */
  registerParticipant?: Maybe<Participant>;
  /** Register as user */
  registerUser?: Maybe<User>;
  /** Reject invitation, deletes user completely */
  rejectInvitation: Scalars['Boolean'];
  /** Request preview token */
  requestPreviewToken?: Maybe<Scalars['String']>;
  /** Send support notification */
  requestSupport?: Maybe<Scalars['JSON']>;
  /** Update domain */
  restoreDomain?: Maybe<Domain>;
  /** Revoke session */
  revokeSession?: Maybe<Session>;
  /** Send notification to participants */
  sendNotification?: Maybe<Scalars['Boolean']>;
  /** Set domain as active domain */
  setActiveDomain?: Maybe<Scalars['Boolean']>;
  /** Set username without session */
  setAnonUsername?: Maybe<AnonUsernameResult>;
  /** Soft delete content */
  softDeleteContent?: Maybe<Scalars['Boolean']>;
  /** Start session */
  startSession?: Maybe<Session>;
  /** Store tracking data */
  storeSessionTracking?: Maybe<Session>;
  /** Create or update theme */
  storeTheme: Theme;
  subscribeDomain?: Maybe<Domain>;
  /** Checkin participant to run */
  terminalCheckinParticipant?: Maybe<Participant>;
  testUpload?: Maybe<File>;
  /** Two factor activate */
  twoFactorActivate: TwoFactorPayload;
  /** Two factor challenge */
  twoFactorChallenge: TwoFactorPayload;
  /** Two factor deactivate */
  twoFactorDeactivate: User;
  unsubscribeDomain?: Maybe<Domain>;
  /** Update relations regarding access control package of user model */
  updateAccessControlUserRelations?: Maybe<User>;
  /** Update content */
  updateContent?: Maybe<Content>;
  /** Update contentPreset */
  updateContentPreset?: Maybe<ContentPreset>;
  /** Update content from array */
  updateContentTree?: Maybe<Scalars['ContentTree']>;
  /** Update domain */
  updateDomain?: Maybe<Domain>;
  /** Update information for a feature */
  updateFeature?: Maybe<Feature>;
  /** Update information for a file */
  updateFile?: Maybe<File>;
  /** Set new password from password reset mail link */
  updateForgottenPassword: AuthPayload;
  /** Update group */
  updateGroup?: Maybe<Group>;
  /** Update learningPath */
  updateLearningPath?: Maybe<LearningPath>;
  /** Update me */
  updateMe?: Maybe<User>;
  /** Update module */
  updateModule?: Maybe<Module>;
  /** Update permission */
  updatePermission?: Maybe<Permission>;
  /** Update plan */
  updatePlan?: Maybe<Plan>;
  /** Update role */
  updateRole?: Maybe<Role>;
  /** Update run */
  updateRun?: Maybe<Run>;
  /** Update runEvaluation */
  updateRunEvaluation?: Maybe<RunEvaluation>;
  /** Update session data */
  updateSession?: Maybe<Session>;
  /** Update tag */
  updateTag?: Maybe<Tag>;
  /** Update tagCategory */
  updateTagCategory?: Maybe<TagCategory>;
  /** Update trackingVisualization */
  updateTrackingVisualization?: Maybe<TrackingVisualization>;
  /** Update trackingVisualizationItem */
  updateTrackingVisualizationItem?: Maybe<TrackingVisualizationItem>;
  /** Update user */
  updateUser?: Maybe<User>;
  /** Update wave */
  updateWave?: Maybe<Wave>;
  uploadAsset?: Maybe<File>;
  /** Upload files */
  uploadFiles?: Maybe<Array<Maybe<File>>>;
  /** Upload module package */
  uploadModulePackage?: Maybe<ModulePackageVerification>;
  /** Verify pending user email */
  verifyPendingUser?: Maybe<PendingUser>;
  /** Verify user email */
  verifyUser?: Maybe<User>;
};


export type MutationAcceptInvitationArgs = {
  data: AcceptInvitationWithPasswordAndCodeInput;
};


export type MutationAllocateParticipantsArgs = {
  data: AllocateParticipantsInput;
};


export type MutationAppendContentArgs = {
  data: NewContentInput;
  id: Scalars['ID'];
};


export type MutationApprovePendingUsersArgs = {
  data: ApprovePendingUsersInput;
};


export type MutationArchiveSessionAndRestartArgs = {
  token: Scalars['String'];
};


export type MutationCheckinParticipantArgs = {
  data: ParticipantCheckInInput;
};


export type MutationClearSessionTrackingsArgs = {
  data: ClearSessionTrackingsInput;
};


export type MutationCreateAccountArgs = {
  data: NewAccountInput;
};


export type MutationCreateAndMergeContentArgs = {
  before?: Maybe<Scalars['Boolean']>;
  content: NewContentInputPartial;
  origin_id: Scalars['ID'];
  wrapper: NewContentInputPartial;
};


export type MutationCreateContentArgs = {
  data: NewContentInput;
};


export type MutationCreateContentPresetArgs = {
  data: NewContentPresetInput;
};


export type MutationCreateDomainArgs = {
  data: NewDomainInput;
};


export type MutationCreateGroupArgs = {
  data: NewGroupInput;
};


export type MutationCreateLearningPathArgs = {
  data: NewLearningPathInput;
};


export type MutationCreateModuleArgs = {
  data: NewModuleInput;
};


export type MutationCreatePermissionArgs = {
  data: NewPermissionInput;
};


export type MutationCreatePlanArgs = {
  data: NewPlanInput;
};


export type MutationCreateRoleArgs = {
  data: NewRoleInput;
};


export type MutationCreateRunArgs = {
  data: NewRunInput;
};


export type MutationCreateRunEvaluationArgs = {
  data: NewRunEvaluationInput;
};


export type MutationCreateTagArgs = {
  data: NewTagInput;
};


export type MutationCreateTagCategoryArgs = {
  data: NewTagCategoryInput;
};


export type MutationCreateTrackingVisualizationArgs = {
  data: NewTrackingVisualizationInput;
};


export type MutationCreateTrackingVisualizationItemArgs = {
  data: NewTrackingVisualizationItemInput;
};


export type MutationCreateUserArgs = {
  data: NewUserInput;
};


export type MutationCreateWaveArgs = {
  data: NewWaveInput;
};


export type MutationDeallocateAssetsArgs = {
  asset_ids: Array<Maybe<Scalars['ID']>>;
  module_id: Scalars['ID'];
};


export type MutationDeleteContentArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteContentPresetArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteContentTextsArgs = {
  data: DeleteContentTextsInput;
};


export type MutationDeleteDomainArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteFeatureArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteFileArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteGroupArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteLearningPathArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteModuleArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePendingUserArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePermissionArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePlanArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteRoleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteRunArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteRunEvaluationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTagArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTagCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteThemeArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTrackingVisualizationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTrackingVisualizationItemArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteWaveArgs = {
  id: Scalars['ID'];
};


export type MutationDetachOrDeleteUserArgs = {
  force?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};


export type MutationDuplicateRunArgs = {
  id: Scalars['ID'];
};


export type MutationEraseDomainWithDependenciesArgs = {
  id: Scalars['ID'];
};


export type MutationExportContentArgs = {
  data: ExportContentInput;
};


export type MutationExtendDemoDomainArgs = {
  data: ExtendDemoDomainInput;
};


export type MutationForgotPasswordArgs = {
  data: ForgotPasswordInput;
};


export type MutationImpersonateArgs = {
  data: ImpersonateInput;
};


export type MutationImportContentArgs = {
  data: ImportContentInput;
};


export type MutationImportContentTextsArgs = {
  module_id: Scalars['ID'];
  package: UploadFileInput;
};


export type MutationImportUsersArgs = {
  data: ImportUsersInput;
};


export type MutationInsertContentAfterArgs = {
  id: Scalars['ID'];
  siblingId: Scalars['ID'];
};


export type MutationInsertContentBeforeArgs = {
  id: Scalars['ID'];
  siblingId: Scalars['ID'];
};


export type MutationInviteArgs = {
  data: InviteInput;
};


export type MutationLoginArgs = {
  data: LoginInput;
};


export type MutationLoginBadgeArgs = {
  data: BadgeLoginInput;
};


export type MutationLoginClientArgs = {
  data: ClientLoginInput;
};


export type MutationLoginRedirectArgs = {
  token: Scalars['String'];
};


export type MutationNotifyAllocatedArgs = {
  data: InviteInput;
};


export type MutationOptOutArgs = {
  data: OptOutInput;
};


export type MutationRefreshTokenArgs = {
  data: RefreshTokenInput;
};


export type MutationRegisterParticipantArgs = {
  data: ParticipantRegistrationInput;
};


export type MutationRegisterUserArgs = {
  data: RegisterUserInput;
};


export type MutationRejectInvitationArgs = {
  data: RejectInvitationInput;
};


export type MutationRequestPreviewTokenArgs = {
  module_id: Scalars['ID'];
};


export type MutationRequestSupportArgs = {
  data: RequestSupportInput;
};


export type MutationRestoreDomainArgs = {
  id: Scalars['ID'];
};


export type MutationRevokeSessionArgs = {
  token: Scalars['String'];
};


export type MutationSendNotificationArgs = {
  data: SendNotificationInput;
};


export type MutationSetActiveDomainArgs = {
  domain_id: Scalars['ID'];
};


export type MutationSetAnonUsernameArgs = {
  data: SetAnonUsernameInput;
};


export type MutationSoftDeleteContentArgs = {
  node_id: Scalars['ID'];
};


export type MutationStartSessionArgs = {
  data: StartSessionInput;
};


export type MutationStoreSessionTrackingArgs = {
  data: StoreSessionTrackingInput;
};


export type MutationStoreThemeArgs = {
  input: StoreThemeInput;
};


export type MutationSubscribeDomainArgs = {
  data: SubscribeDomainInput;
};


export type MutationTerminalCheckinParticipantArgs = {
  data: ParticipantCheckInInput;
};


export type MutationTestUploadArgs = {
  file?: Maybe<Scalars['Upload']>;
};


export type MutationTwoFactorActivateArgs = {
  data: TwoFactorChallengeInput;
};


export type MutationTwoFactorChallengeArgs = {
  data: TwoFactorChallengeInput;
};


export type MutationUnsubscribeDomainArgs = {
  data: UnsubscribeDomainInput;
};


export type MutationUpdateAccessControlUserRelationsArgs = {
  data: AccessControlUserRelationsInput;
};


export type MutationUpdateContentArgs = {
  data: UpdateContentInput;
};


export type MutationUpdateContentPresetArgs = {
  data: UpdateContentPresetInput;
};


export type MutationUpdateContentTreeArgs = {
  delete?: Maybe<Array<Maybe<Scalars['ID']>>>;
  parent_id: Scalars['ID'];
  trees?: Maybe<Array<Maybe<UpdateContentTreeInput>>>;
};


export type MutationUpdateDomainArgs = {
  data: UpdateDomainInput;
};


export type MutationUpdateFeatureArgs = {
  data: UpdateFeatureInput;
};


export type MutationUpdateFileArgs = {
  data: UpdateFileInput;
};


export type MutationUpdateForgottenPasswordArgs = {
  data: NewPasswordWithCodeInput;
};


export type MutationUpdateGroupArgs = {
  data: UpdateGroupInput;
};


export type MutationUpdateLearningPathArgs = {
  data: UpdateLearningPathInput;
};


export type MutationUpdateMeArgs = {
  data: UpdateMeInput;
};


export type MutationUpdateModuleArgs = {
  data: UpdateModuleInput;
};


export type MutationUpdatePermissionArgs = {
  data: UpdatePermissionInput;
};


export type MutationUpdatePlanArgs = {
  data: UpdatePlanInput;
};


export type MutationUpdateRoleArgs = {
  data: UpdateRoleInput;
};


export type MutationUpdateRunArgs = {
  data: UpdateRunInput;
};


export type MutationUpdateRunEvaluationArgs = {
  data: UpdateRunEvaluationInput;
};


export type MutationUpdateSessionArgs = {
  data: UpdateSessionInput;
};


export type MutationUpdateTagArgs = {
  data: UpdateTagInput;
};


export type MutationUpdateTagCategoryArgs = {
  data: UpdateTagCategoryInput;
};


export type MutationUpdateTrackingVisualizationArgs = {
  data: UpdateTrackingVisualizationInput;
};


export type MutationUpdateTrackingVisualizationItemArgs = {
  data: UpdateTrackingVisualizationItemInput;
};


export type MutationUpdateUserArgs = {
  data: UpdateUserInput;
};


export type MutationUpdateWaveArgs = {
  data: UpdateWaveInput;
};


export type MutationUploadAssetArgs = {
  data: UploadAssetInput;
  module_id: Scalars['ID'];
};


export type MutationUploadFilesArgs = {
  data: Array<Maybe<UploadFileInput>>;
};


export type MutationUploadModulePackageArgs = {
  package: UploadFileInput;
};


export type MutationVerifyPendingUserArgs = {
  id: Scalars['String'];
};


export type MutationVerifyUserArgs = {
  id: Scalars['ID'];
};

export type NewAccountInput = {
  email: Scalars['String'];
  firstname?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  languagecode?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  texts?: Maybe<CreateTextsRelation>;
  username?: Maybe<Scalars['String']>;
};

export type NewContentInput = {
  data?: Maybe<Scalars['String']>;
  files?: Maybe<CreateFilesRelation>;
  module?: Maybe<CreateModuleRelation>;
  parent?: Maybe<CreateParentRelation>;
  properties?: Maybe<Scalars['String']>;
  texts?: Maybe<CreateTextsRelation>;
  type?: Maybe<Scalars['String']>;
};

export type NewContentInputPartial = {
  data?: Maybe<Scalars['String']>;
  files?: Maybe<CreateFilesRelation>;
  properties?: Maybe<Scalars['String']>;
  texts?: Maybe<CreateTextsRelation>;
  type?: Maybe<Scalars['String']>;
};

export type NewContentPresetInput = {
  created_at?: Maybe<Scalars['DateTime']>;
  data?: Maybe<Scalars['String']>;
  domain_id?: Maybe<Scalars['ID']>;
  module_id?: Maybe<Scalars['ID']>;
  properties?: Maybe<Scalars['String']>;
  tags?: Maybe<CreateTagsRelation>;
  texts?: Maybe<CreateTextsRelation>;
  thumb_desktop?: Maybe<Scalars['String']>;
  thumb_mobile?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type NewDomainInput = {
  config?: Maybe<CreateDomainConfig>;
  features?: Maybe<CreateFeaturesRelation>;
  identifier: Scalars['String'];
  markets?: Maybe<Scalars['Boolean']>;
  namespace?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['ID']>;
  payment_status: Scalars['String'];
  payment_type: Scalars['String'];
  plan?: Maybe<CreatePlanRelation>;
  plan_applied_at?: Maybe<Scalars['Date']>;
  properties?: Maybe<Scalars['Properties']>;
  shared_resources?: Maybe<CreateSharedResourceRelation>;
  tags?: Maybe<CreateTagsRelation>;
  texts?: Maybe<CreateTextsRelation>;
  trial_ends_at?: Maybe<Scalars['DateTime']>;
  user_limit: Scalars['Int'];
  users?: Maybe<CreateUsersRelation>;
};

export type NewGroupInput = {
  identifier: Scalars['String'];
  participant_count: Scalars['Int'];
  participant_max: Scalars['Int'];
  properties?: Maybe<Scalars['Properties']>;
  run: CreateRunRelation;
  texts?: Maybe<CreateTextsRelation>;
};

export type NewLearningPathInput = {
  files?: Maybe<CreateFilesRelation>;
  identifier?: Maybe<Scalars['String']>;
  languagecode?: Maybe<Scalars['String']>;
  mandatory_level?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Properties']>;
  runs?: Maybe<CreateRunsRelation>;
  tags?: Maybe<CreateTagsRelation>;
  texts?: Maybe<CreateTextsRelation>;
};

export type NewModuleInput = {
  activates_at?: Maybe<Scalars['DateTime']>;
  active?: Maybe<Scalars['Boolean']>;
  downloads?: Maybe<CreateModuleFilesRelation>;
  duration?: Maybe<Scalars['Int']>;
  expires_at?: Maybe<Scalars['DateTime']>;
  identifier: Scalars['String'];
  keyvisual_id?: Maybe<Scalars['ID']>;
  languagecode?: Maybe<Scalars['String']>;
  links?: Maybe<CreateModuleLinksRelation>;
  packages?: Maybe<CreateModulePackagesRelation>;
  properties?: Maybe<Scalars['Properties']>;
  sorting?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  sticky?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<CreateTagsRelation>;
  texts?: Maybe<CreateTextsRelation>;
  tracking_type?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  version?: Maybe<Scalars['String']>;
};

export type NewPasswordWithCodeInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  password_confirmation: Scalars['String'];
  token: Scalars['String'];
};

export type NewPermissionInput = {
  ability: Scalars['String'];
  feature?: Maybe<CreateFeatureRelation>;
  properties?: Maybe<Scalars['Properties']>;
  roles?: Maybe<CreateRolesRelation>;
  texts?: Maybe<CreateTextsRelation>;
  type: Scalars['String'];
};

export type NewPlanInput = {
  admin_quota?: Maybe<Scalars['Int']>;
  course_quota?: Maybe<Scalars['Int']>;
  creator_quota?: Maybe<Scalars['Int']>;
  feature_set?: Maybe<Array<Scalars['String']>>;
  head?: Maybe<Scalars['Boolean']>;
  identifier?: Maybe<Scalars['String']>;
  participant_quota?: Maybe<Scalars['Int']>;
  payment_interval?: Maybe<Scalars['Int']>;
  run_participant_quota?: Maybe<Scalars['Int']>;
  run_quota?: Maybe<Scalars['Int']>;
  sorting?: Maybe<Scalars['Int']>;
  storage_quota?: Maybe<Scalars['Int']>;
  texts?: Maybe<CreateTextsRelation>;
};

export type NewRoleInput = {
  domain?: Maybe<CreateDomainRelation>;
  identifier: Scalars['String'];
  parent?: Maybe<CreateRoleRelation>;
  permissions?: Maybe<CreatePermissionsRelation>;
  properties?: Maybe<Scalars['Properties']>;
  texts?: Maybe<CreateTextsRelation>;
  users?: Maybe<CreateUsersRelation>;
};

export type NewRunEvaluationInput = {
  identifier?: Maybe<Scalars['String']>;
  is_public?: Maybe<Scalars['Boolean']>;
  properties?: Maybe<Scalars['Properties']>;
  run?: Maybe<CreateRunRelation>;
  target?: Maybe<Scalars['String']>;
  template?: Maybe<Scalars['String']>;
  texts?: Maybe<CreateTextsRelation>;
  trackingVisualizations?: Maybe<CreateTrackingVisualizationsRelation>;
  type?: Maybe<Scalars['String']>;
};

export type NewRunInput = {
  active?: Maybe<Scalars['Boolean']>;
  checkin_type: Scalars['String'];
  dedicated_waves?: Maybe<Scalars['Boolean']>;
  files?: Maybe<CreateFilesRelation>;
  flow_config?: Maybe<Scalars['JSON']>;
  groupflow?: Maybe<Scalars['Boolean']>;
  identifier: Scalars['String'];
  is_public?: Maybe<Scalars['Boolean']>;
  keyvisual_id?: Maybe<Scalars['ID']>;
  languagecode?: Maybe<Scalars['String']>;
  modules?: Maybe<CreateModulesRelation>;
  periodic?: Maybe<Scalars['Boolean']>;
  properties?: Maybe<Scalars['Properties']>;
  remember_periodic_at?: Maybe<Scalars['Date']>;
  shareable?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  sticky?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<CreateTagsRelation>;
  texts?: Maybe<CreateTextsRelation>;
  type?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type NewTagCategoryInput = {
  domain?: Maybe<CreateDomainRelation>;
  grants_access?: Maybe<Scalars['Boolean']>;
  identifier: Scalars['String'];
  readonly?: Maybe<Scalars['Boolean']>;
  texts?: Maybe<CreateTextsRelation>;
};

export type NewTagInput = {
  category?: Maybe<CreateCategoryRelation>;
  identifier: Scalars['String'];
  texts?: Maybe<CreateTextsRelation>;
};

export type NewTrackingVisualizationInput = {
  identifier?: Maybe<Scalars['String']>;
  items?: Maybe<CreateTrackingVisualizationItemRelation>;
  properties?: Maybe<Scalars['Properties']>;
  texts?: Maybe<CreateTextsRelation>;
  type?: Maybe<Scalars['String']>;
};

export type NewTrackingVisualizationItemInput = {
  component_tag?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  module: CreateModuleRelation;
  objective?: Maybe<Scalars['String']>;
  properties?: Maybe<Scalars['Properties']>;
  run?: Maybe<CreateRunRelation>;
  site?: Maybe<Scalars['String']>;
  texts?: Maybe<CreateTextsRelation>;
  tracking_visualizations?: Maybe<CreateTrackingVisualizationRelation>;
};

export type NewUserInput = {
  active?: Maybe<Scalars['Boolean']>;
  department?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  expires_at?: Maybe<Scalars['DateTime']>;
  firstname?: Maybe<Scalars['String']>;
  is_super?: Maybe<Scalars['Boolean']>;
  languagecode?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  properties?: Maybe<Scalars['Properties']>;
  roles?: Maybe<CreateRolesRelation>;
  settings?: Maybe<CreateUserSettingsRelation>;
  tags?: Maybe<CreateTagsRelation>;
  username?: Maybe<Scalars['String']>;
};

export type NewWaveInput = {
  ends_at: Scalars['Date'];
  identifier: Scalars['String'];
  participant_count?: Maybe<Scalars['Int']>;
  participant_max: Scalars['Int'];
  properties?: Maybe<Scalars['Properties']>;
  run?: Maybe<CreateRunRelation>;
  starts_at: Scalars['Date'];
  texts?: Maybe<CreateTextsRelation>;
};

export type NotificationTextsInput = {
  action_button?: Maybe<Scalars['String']>;
  intro_lines: Array<Maybe<Scalars['String']>>;
  outro_lines?: Maybe<Array<Maybe<Scalars['String']>>>;
  subject: Scalars['String'];
};

export type OptOutInput = {
  type: Scalars['String'];
};

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum OrderByRelationAggregateFunction {
  /** Amount of items. */
  Count = 'COUNT'
}

export type OrderByRelationClause = {
  column?: Maybe<Scalars['String']>;
  conditions?: Maybe<Array<Maybe<OrderByRelationCondition>>>;
  order?: Maybe<SortOrder>;
  relation?: Maybe<Scalars['String']>;
};

export type OrderByRelationCondition = {
  column: Scalars['String'];
  value: Scalars['String'];
};

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum OrderByRelationWithColumnAggregateFunction {
  /** Average. */
  Avg = 'AVG',
  /** Amount of items. */
  Count = 'COUNT',
  /** Maximum. */
  Max = 'MAX',
  /** Minimum. */
  Min = 'MIN',
  /** Sum. */
  Sum = 'SUM'
}

/** Information about pagination using a Relay style cursor connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** Number of nodes in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** The cursor to continue paginating forwards. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** The cursor to continue paginating backwards. */
  startCursor?: Maybe<Scalars['String']>;
  /** Total number of nodes in the paginated connection. */
  total: Scalars['Int'];
};

/** Information about pagination using a fully featured paginator. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** Number of items per page. */
  perPage: Scalars['Int'];
  /** Number of total available items. */
  total: Scalars['Int'];
};

export type Participant = {
  __typename?: 'Participant';
  badge_url?: Maybe<Scalars['String']>;
  run: Run;
  run_evaluation?: Maybe<RunUserPivot>;
  user: User;
};

export type ParticipantCheckInInput = {
  checkin_validation: Scalars['String'];
  run_id: Scalars['ID'];
};

export type ParticipantDasboardCourses = {
  __typename?: 'ParticipantDasboardCourses';
  attending?: Maybe<Array<Maybe<RunDashboard>>>;
  available?: Maybe<Array<Maybe<RunDashboard>>>;
  completed?: Maybe<Array<Maybe<RunDashboard>>>;
  expired?: Maybe<Array<Maybe<RunDashboard>>>;
  invited?: Maybe<Array<Maybe<RunDashboard>>>;
  upcoming?: Maybe<Array<Maybe<RunDashboard>>>;
};

export type ParticipantDasboardEvents = {
  __typename?: 'ParticipantDasboardEvents';
  attending?: Maybe<Array<Maybe<RunDashboard>>>;
  available?: Maybe<Array<Maybe<RunDashboard>>>;
  completed?: Maybe<Array<Maybe<RunDashboard>>>;
  expired?: Maybe<Array<Maybe<RunDashboard>>>;
  invited?: Maybe<Array<Maybe<RunDashboard>>>;
  upcoming?: Maybe<Array<Maybe<RunDashboard>>>;
};

export type ParticipantDashboard = {
  __typename?: 'ParticipantDashboard';
  configuration: ParticipantDashboardConfiguration;
  courses?: Maybe<ParticipantDasboardCourses>;
  events?: Maybe<ParticipantDasboardEvents>;
  learning_paths: Array<ParticipantDashboardLearningPath>;
  status: Scalars['String'];
};

export type ParticipantDashboardConfiguration = {
  __typename?: 'ParticipantDashboardConfiguration';
  alerts: Array<Scalars['String']>;
  status: Scalars['String'];
};

export type ParticipantDashboardLearningPath = {
  __typename?: 'ParticipantDashboardLearningPath';
  learning_path: LearningPath;
  run_dashboards: Array<RunDashboard>;
};

export type ParticipantRegistrationInput = {
  checkin_validation?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  group_id?: Maybe<Scalars['ID']>;
  new_user?: Maybe<RegisterParticipantInput>;
  run_id: Scalars['ID'];
  wave_id?: Maybe<Scalars['ID']>;
};

export type PendingUser = {
  __typename?: 'PendingUser';
  created_at?: Maybe<Scalars['DateTime']>;
  domain?: Maybe<Domain>;
  email: Scalars['String'];
  email_verified_at?: Maybe<Scalars['DateTime']>;
  firstname: Scalars['String'];
  id: Scalars['ID'];
  languagecode?: Maybe<Scalars['String']>;
  lastname: Scalars['String'];
  market?: Maybe<Tag>;
  password: Scalars['String'];
  role?: Maybe<Role>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

/** A paginated list of PendingUser items. */
export type PendingUserPaginator = {
  __typename?: 'PendingUserPaginator';
  /** A list of PendingUser items. */
  data: Array<PendingUser>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type Permission = {
  __typename?: 'Permission';
  ability: Scalars['String'];
  created_at?: Maybe<Scalars['DateTime']>;
  feature?: Maybe<Feature>;
  id: Scalars['ID'];
  properties?: Maybe<Scalars['Properties']>;
  roles: Array<Role>;
  texts: Array<Text>;
  type: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
};

/** A paginated list of Permission items. */
export type PermissionPaginator = {
  __typename?: 'PermissionPaginator';
  /** A list of Permission items. */
  data: Array<Permission>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type Plan = {
  __typename?: 'Plan';
  admin_quota: Scalars['Int'];
  course_quota: Scalars['Int'];
  created_at?: Maybe<Scalars['DateTime']>;
  creator_quota: Scalars['Int'];
  feature_set: Array<Scalars['String']>;
  head?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  identifier: Scalars['String'];
  participant_quota: Scalars['Int'];
  payment_interval: Scalars['Int'];
  run_participant_quota: Scalars['Int'];
  run_quota: Scalars['Int'];
  sorting?: Maybe<Scalars['Int']>;
  storage_quota: Scalars['Int'];
  texts: Array<Text>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

/** A paginated list of Plan items. */
export type PlanPaginator = {
  __typename?: 'PlanPaginator';
  /** A list of Plan items. */
  data: Array<Plan>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type PreviewResult = {
  __typename?: 'PreviewResult';
  content?: Maybe<Scalars['ContentTree']>;
  expired?: Maybe<Scalars['Boolean']>;
};


export type Query = {
  __typename?: 'Query';
  /** Ancestors */
  ancestors?: Maybe<Scalars['ContentTree']>;
  /** Ancestors and Self */
  ancestorsAndSelf?: Maybe<Scalars['ContentTree']>;
  /** Show assets by current active domain or, if given, by module id */
  assets?: Maybe<Array<Maybe<File>>>;
  /** List all available languages */
  availableLanguages: Array<Tag>;
  /** List all available markets */
  availableMarkets: Array<Tag>;
  /** Show by ID */
  content?: Maybe<Content>;
  /** Contents by module as flattened tree */
  contentList?: Maybe<Array<Content>>;
  /** Show by ID */
  contentPreset?: Maybe<ContentPreset>;
  /** List all */
  contentPresets?: Maybe<ContentPresetPaginator>;
  /** Contents by module as tree */
  contentPreview?: Maybe<PreviewResult>;
  /** Contents by module as tree */
  contentTree?: Maybe<Scalars['ContentTree']>;
  /** List all */
  contents?: Maybe<ContentPaginator>;
  /** List all courses */
  courses?: Maybe<RunPaginator>;
  /** Descendants */
  descendants?: Maybe<Scalars['ContentTree']>;
  /** Descendants and Self */
  descendantsAndSelf?: Maybe<Scalars['ContentTree']>;
  /** Show by ID */
  domain?: Maybe<Domain>;
  /** Domain by namespace */
  domainByNamespace?: Maybe<DomainRestricted>;
  /** Shows available quotas for domain of logged in user */
  domainQuotas: DomainQuotas;
  /** List all */
  domains?: Maybe<DomainPaginator>;
  /** List all events */
  events?: Maybe<RunPaginator>;
  /** Show file by ID */
  feature?: Maybe<Feature>;
  /** List all features */
  features?: Maybe<FeaturePaginator>;
  /** Show file by ID */
  file?: Maybe<File>;
  /** List all files */
  files?: Maybe<FilePaginator>;
  /** Show by ID */
  group?: Maybe<Group>;
  /** List all */
  groups?: Maybe<GroupPaginator>;
  /** Checks if a cms component has trackings stored allocated to it */
  hasTrackings?: Maybe<Scalars['Boolean']>;
  /** Issues a certificate if user is qualified */
  issueCertificate: Scalars['String'];
  /** Show by ID */
  learningPath?: Maybe<LearningPath>;
  /** List all */
  learningPaths?: Maybe<LearningPathPaginator>;
  /** Show me */
  me: User;
  /** Show by ID */
  module?: Maybe<Module>;
  /** Show basic public information of module by identifier */
  moduleBasic?: Maybe<ModuleBasic>;
  /** Check if identifier exists */
  moduleByIdentifier?: Maybe<Module>;
  /** List all */
  modules?: Maybe<ModulePaginator>;
  /** List modules by usage and not paginated */
  modulesByUsage: Array<Module>;
  /** Returns if a given domain namespace already exists in database */
  namespaceExists: Scalars['Boolean'];
  /** Next Sibling */
  nextSibling?: Maybe<Content>;
  /** Next Siblings */
  nextSiblings?: Maybe<Array<Maybe<Content>>>;
  /** Configuration for logged in user dashboard */
  participantDashboard: ParticipantDashboard;
  /** List all users with role participant */
  participants?: Maybe<UserPaginator>;
  /** List all */
  pendingUsers?: Maybe<PendingUserPaginator>;
  /** Show by ID */
  permission?: Maybe<Permission>;
  /** List all */
  permissions?: Maybe<PermissionPaginator>;
  /** Show by ID */
  plan?: Maybe<Plan>;
  /** List all */
  plans?: Maybe<PlanPaginator>;
  /** Previous Sibling */
  prevSibling?: Maybe<Content>;
  /** Previous Siblings */
  prevSiblings?: Maybe<Array<Maybe<Content>>>;
  /** Show by ID */
  role?: Maybe<Role>;
  /** List all */
  roles?: Maybe<RolePaginator>;
  /** Show a run (course, event) by ID */
  run?: Maybe<Run>;
  /** Show basic public information of run by identifier */
  runBasic?: Maybe<RunBasic>;
  /** Show run dashboard */
  runDashboard?: Maybe<RunDashboard>;
  /** Show by Run and Module for authed user */
  runDashboardSessions?: Maybe<Array<Maybe<Session>>>;
  /** Show by ID */
  runEvaluation?: Maybe<RunEvaluation>;
  /** List all */
  runEvaluations?: Maybe<RunEvaluationPaginator>;
  /** Show potential users of a run */
  runPotentialUsers?: Maybe<UserPaginator>;
  /** Show by Run and Module for the given user */
  runSessions?: Maybe<Array<Maybe<Session>>>;
  /** Show tracking history of a run */
  runTrackingHistory?: Maybe<UserPaginator>;
  /** List all runs (courses and events etc.) */
  runs?: Maybe<RunPaginator>;
  /** Show by ID */
  session?: Maybe<Session>;
  /** List all */
  sessions?: Maybe<SessionPaginator>;
  /** Siblings */
  siblings?: Maybe<Array<Maybe<Content>>>;
  /** Show API status information */
  status?: Maybe<Status>;
  /** Subtree */
  subtree?: Maybe<Scalars['ContentTree']>;
  /** Users to be used as support notification targets */
  supportNotifiables?: Maybe<UserPaginator>;
  /** Show by ID */
  tag?: Maybe<Tag>;
  /** List all */
  tagCategories?: Maybe<TagCategoryPaginator>;
  /** Show by ID */
  tagCategory?: Maybe<TagCategory>;
  /** List all */
  tags?: Maybe<TagPaginator>;
  /** List runs for check-in terminal usage */
  terminalRuns?: Maybe<Array<Maybe<Run>>>;
  /** Show text history */
  textHistory?: Maybe<Array<Maybe<Text>>>;
  /** List all themes for domain */
  themes?: Maybe<Array<Theme>>;
  /** Show by ID */
  trackingVisualization?: Maybe<TrackingVisualization>;
  /** Show by ID */
  trackingVisualizationItem?: Maybe<TrackingVisualizationItem>;
  /** List all */
  trackingVisualizationItems?: Maybe<TrackingVisualizationItemPaginator>;
  /** List all */
  trackingVisualizations?: Maybe<TrackingVisualizationPaginator>;
  /** Get trackings by... */
  trackings?: Maybe<Array<Maybe<TrackingDataCollection>>>;
  /** Initializes two factor authentication with generating user secret */
  twoFactorInitialize: TwoFactorInitializeResponse;
  /** Show by ID */
  user?: Maybe<User>;
  /** Returns if a given username or email already exists in database */
  userExists: UserExists;
  /** Show tracking history of a user */
  userTrackingHistory?: Maybe<RunUserPivotPaginator>;
  /** List all */
  users?: Maybe<UserPaginator>;
  /** Contents by module as tree for viewer - needs session token authentication at least */
  viewContentTree?: Maybe<Scalars['ContentTree']>;
  /** Show by ID */
  wave?: Maybe<Wave>;
  /** List all */
  waves?: Maybe<WavePaginator>;
};


export type QueryAncestorsArgs = {
  node_id: Scalars['ID'];
};


export type QueryAncestorsAndSelfArgs = {
  node_id: Scalars['ID'];
};


export type QueryAssetsArgs = {
  module_id?: Maybe<Scalars['ID']>;
};


export type QueryAvailableLanguagesArgs = {
  domain_id: Scalars['ID'];
};


export type QueryAvailableMarketsArgs = {
  domain_id: Scalars['ID'];
};


export type QueryContentArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryContentListArgs = {
  module_id: Scalars['ID'];
};


export type QueryContentPresetArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryContentPresetsArgs = {
  created_at?: Maybe<DateRange>;
  filter: FilterInput;
  first: Scalars['Int'];
  orderBy?: Maybe<Array<OrderByClause>>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryContentPreviewArgs = {
  token: Scalars['String'];
};


export type QueryContentTreeArgs = {
  module_id: Scalars['ID'];
};


export type QueryContentsArgs = {
  created_at?: Maybe<DateRange>;
  filter: FilterInput;
  first: Scalars['Int'];
  orderBy?: Maybe<Array<OrderByClause>>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryCoursesArgs = {
  created_at?: Maybe<DateRange>;
  filter: FilterInput;
  first: Scalars['Int'];
  orderBy?: Maybe<Array<OrderByClause>>;
  orderByRelation?: Maybe<OrderByRelationClause>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryDescendantsArgs = {
  node_id: Scalars['ID'];
};


export type QueryDescendantsAndSelfArgs = {
  node_id: Scalars['ID'];
};


export type QueryDomainArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryDomainByNamespaceArgs = {
  namespace: Scalars['String'];
};


export type QueryDomainsArgs = {
  created_at?: Maybe<DateRange>;
  filter: FilterInput;
  first: Scalars['Int'];
  orderBy?: Maybe<Array<OrderByClause>>;
  orderByRelation?: Maybe<OrderByRelationClause>;
  page?: Maybe<Scalars['Int']>;
  trashed?: Maybe<Trashed>;
};


export type QueryEventsArgs = {
  created_at?: Maybe<DateRange>;
  filter: FilterInput;
  first: Scalars['Int'];
  orderBy?: Maybe<Array<OrderByClause>>;
  orderByRelation?: Maybe<OrderByRelationClause>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryFeatureArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryFeaturesArgs = {
  active?: Maybe<Scalars['Boolean']>;
  filter: FilterInput;
  first: Scalars['Int'];
  orderBy?: Maybe<Array<OrderByClause>>;
  orderByRelation?: Maybe<OrderByRelationClause>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryFileArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryFilesArgs = {
  created_at?: Maybe<DateRange>;
  filter: FilterInput;
  first: Scalars['Int'];
  orderBy?: Maybe<Array<OrderByClause>>;
  page?: Maybe<Scalars['Int']>;
  target_type?: Maybe<Scalars['String']>;
};


export type QueryGroupArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryGroupsArgs = {
  created_at?: Maybe<DateRange>;
  filter: FilterInput;
  first: Scalars['Int'];
  orderBy?: Maybe<Array<OrderByClause>>;
  orderByRelation?: Maybe<OrderByRelationClause>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryHasTrackingsArgs = {
  key: Scalars['ID'];
};


export type QueryIssueCertificateArgs = {
  run_id: Scalars['ID'];
  user_id: Scalars['ID'];
};


export type QueryLearningPathArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryLearningPathsArgs = {
  created_at?: Maybe<DateRange>;
  filter: FilterInput;
  first: Scalars['Int'];
  orderBy?: Maybe<Array<OrderByClause>>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryModuleArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryModuleBasicArgs = {
  slug?: Maybe<Scalars['String']>;
};


export type QueryModuleByIdentifierArgs = {
  identifier?: Maybe<Scalars['String']>;
};


export type QueryModulesArgs = {
  created_at?: Maybe<DateRange>;
  filter: FilterInput;
  first: Scalars['Int'];
  orderBy?: Maybe<Array<OrderByClause>>;
  orderByRelation?: Maybe<OrderByRelationClause>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryModulesByUsageArgs = {
  first?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryNamespaceExistsArgs = {
  query: Scalars['String'];
};


export type QueryNextSiblingArgs = {
  node_id: Scalars['ID'];
};


export type QueryNextSiblingsArgs = {
  node_id: Scalars['ID'];
};


export type QueryParticipantsArgs = {
  active?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<DateRange>;
  expires_at?: Maybe<DateRange>;
  filter: FilterInput;
  first: Scalars['Int'];
  invitation_sent?: Maybe<DateRange>;
  last_login?: Maybe<DateRange>;
  orderBy?: Maybe<Array<OrderByClause>>;
  orderByRelation?: Maybe<OrderByRelationClause>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryPendingUsersArgs = {
  created_at?: Maybe<DateRange>;
  filter: FilterInput;
  first: Scalars['Int'];
  orderBy?: Maybe<Array<OrderByClause>>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryPermissionArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryPermissionsArgs = {
  created_at?: Maybe<DateRange>;
  filter: FilterInput;
  first: Scalars['Int'];
  orderBy?: Maybe<Array<OrderByClause>>;
  orderByRelation?: Maybe<OrderByRelationClause>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryPlanArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryPlansArgs = {
  created_at?: Maybe<DateRange>;
  filter: FilterInput;
  first: Scalars['Int'];
  orderBy?: Maybe<Array<OrderByClause>>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryPrevSiblingArgs = {
  node_id: Scalars['ID'];
};


export type QueryPrevSiblingsArgs = {
  node_id: Scalars['ID'];
};


export type QueryRoleArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryRolesArgs = {
  created_at?: Maybe<DateRange>;
  filter: FilterInput;
  first: Scalars['Int'];
  orderBy?: Maybe<Array<OrderByClause>>;
  orderByRelation?: Maybe<OrderByRelationClause>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryRunArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryRunBasicArgs = {
  slug?: Maybe<Scalars['String']>;
};


export type QueryRunDashboardArgs = {
  slug: Scalars['String'];
};


export type QueryRunDashboardSessionsArgs = {
  module_id?: Maybe<Scalars['ID']>;
  run_id: Scalars['ID'];
};


export type QueryRunEvaluationArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryRunEvaluationsArgs = {
  created_at?: Maybe<DateRange>;
  filter: FilterInput;
  first: Scalars['Int'];
  orderBy?: Maybe<Array<OrderByClause>>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryRunPotentialUsersArgs = {
  created_at?: Maybe<DateRange>;
  filter: FilterInput;
  first: Scalars['Int'];
  orderBy?: Maybe<Array<OrderByClause>>;
  orderByRelation?: Maybe<OrderByRelationClause>;
  page?: Maybe<Scalars['Int']>;
  run_id: Scalars['ID'];
};


export type QueryRunSessionsArgs = {
  run_id: Scalars['ID'];
  user_id: Scalars['ID'];
};


export type QueryRunTrackingHistoryArgs = {
  created_at?: Maybe<DateRange>;
  filter: FilterInput;
  first: Scalars['Int'];
  orderBy?: Maybe<Array<OrderByClause>>;
  orderByRelation?: Maybe<OrderByRelationClause>;
  page?: Maybe<Scalars['Int']>;
  run_id: Scalars['ID'];
};


export type QueryRunsArgs = {
  created_at?: Maybe<DateRange>;
  filter: FilterInput;
  first: Scalars['Int'];
  orderBy?: Maybe<Array<OrderByClause>>;
  orderByRelation?: Maybe<OrderByRelationClause>;
  page?: Maybe<Scalars['Int']>;
};


export type QuerySessionArgs = {
  token: Scalars['String'];
};


export type QuerySessionsArgs = {
  created_at?: Maybe<DateRange>;
  filter: FilterInput;
  first: Scalars['Int'];
  hasTrackingStatus?: Maybe<WhereConditions>;
  orderBy?: Maybe<Array<OrderByClause>>;
  orderByRelation?: Maybe<OrderByRelationClause>;
  page?: Maybe<Scalars['Int']>;
  run_id: Scalars['ID'];
};


export type QuerySiblingsArgs = {
  node_id: Scalars['ID'];
};


export type QuerySubtreeArgs = {
  node_id: Scalars['ID'];
};


export type QuerySupportNotifiablesArgs = {
  filter: FilterInput;
  first: Scalars['Int'];
  orderBy?: Maybe<Array<OrderByClause>>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryTagArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryTagCategoriesArgs = {
  created_at?: Maybe<DateRange>;
  filter: FilterInput;
  first: Scalars['Int'];
  orderBy?: Maybe<Array<OrderByClause>>;
  orderByRelation?: Maybe<OrderByRelationClause>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryTagCategoryArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryTagsArgs = {
  created_at?: Maybe<DateRange>;
  filter: FilterInput;
  first: Scalars['Int'];
  orderBy?: Maybe<Array<OrderByClause>>;
  orderByRelation?: Maybe<OrderByRelationClause>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryTextHistoryArgs = {
  identifier: Scalars['String'];
  languagecode?: Maybe<Scalars['String']>;
  res_id: Scalars['ID'];
  res_type: Scalars['String'];
};


export type QueryThemesArgs = {
  domain_id: Scalars['ID'];
};


export type QueryTrackingVisualizationArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryTrackingVisualizationItemArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryTrackingVisualizationItemsArgs = {
  created_at?: Maybe<DateRange>;
  filter: FilterInput;
  first: Scalars['Int'];
  orderBy?: Maybe<Array<OrderByClause>>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryTrackingVisualizationsArgs = {
  created_at?: Maybe<DateRange>;
  filter: FilterInput;
  first: Scalars['Int'];
  module_ids?: Maybe<Array<Scalars['ID']>>;
  orderBy?: Maybe<Array<OrderByClause>>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryTrackingsArgs = {
  data?: Maybe<Array<TrackingQueryInput>>;
};


export type QueryUserArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryUserExistsArgs = {
  query: Scalars['String'];
  type: Scalars['String'];
};


export type QueryUserTrackingHistoryArgs = {
  created_at?: Maybe<DateRange>;
  filter: FilterInput;
  first: Scalars['Int'];
  orderBy?: Maybe<Array<OrderByClause>>;
  orderByRelation?: Maybe<OrderByRelationClause>;
  page?: Maybe<Scalars['Int']>;
  user_id: Scalars['ID'];
};


export type QueryUsersArgs = {
  active?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<DateRange>;
  expires_at?: Maybe<DateRange>;
  filter: FilterInput;
  first: Scalars['Int'];
  invitation_sent?: Maybe<DateRange>;
  last_login?: Maybe<DateRange>;
  orderBy?: Maybe<Array<OrderByClause>>;
  orderByRelation?: Maybe<OrderByRelationClause>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryViewContentTreeArgs = {
  module_id: Scalars['ID'];
};


export type QueryWaveArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryWavesArgs = {
  created_at?: Maybe<DateRange>;
  filter: FilterInput;
  first: Scalars['Int'];
  orderBy?: Maybe<Array<OrderByClause>>;
  orderByRelation?: Maybe<OrderByRelationClause>;
  page?: Maybe<Scalars['Int']>;
};

export type RefreshTokenInput = {
  refresh_token: Scalars['String'];
  username: Scalars['String'];
};

export type RegisterParticipantInput = {
  department?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstname: Scalars['String'];
  languagecode?: Maybe<Scalars['String']>;
  lastname: Scalars['String'];
  market_id?: Maybe<Scalars['ID']>;
  password: Scalars['String'];
  settings?: Maybe<CreateUserSettingsRelation>;
  username?: Maybe<Scalars['String']>;
};

export type RegisterUserInput = {
  department?: Maybe<Scalars['String']>;
  domain_id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  firstname: Scalars['String'];
  languagecode?: Maybe<Scalars['String']>;
  lastname: Scalars['String'];
  market_id?: Maybe<Scalars['ID']>;
  password: Scalars['String'];
  settings?: Maybe<CreateUserSettingsRelation>;
  username?: Maybe<Scalars['String']>;
};

export type RejectInvitationInput = {
  email: Scalars['String'];
  token: Scalars['String'];
};

export type RequestSupportInput = {
  email: Scalars['String'];
  market_id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
};

export type Role = {
  __typename?: 'Role';
  created_at?: Maybe<Scalars['DateTime']>;
  domain?: Maybe<Domain>;
  id: Scalars['ID'];
  identifier: Scalars['String'];
  parent?: Maybe<Role>;
  permissions: Array<Permission>;
  properties?: Maybe<Scalars['Properties']>;
  texts: Array<Text>;
  updated_at?: Maybe<Scalars['DateTime']>;
  users: Array<User>;
};

/** A paginated list of Role items. */
export type RolePaginator = {
  __typename?: 'RolePaginator';
  /** A list of Role items. */
  data: Array<Role>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type Run = {
  __typename?: 'Run';
  active?: Maybe<Scalars['Boolean']>;
  checkin_type: Scalars['String'];
  created_at?: Maybe<Scalars['DateTime']>;
  dedicated_waves?: Maybe<Scalars['Boolean']>;
  domain?: Maybe<Domain>;
  ends_at?: Maybe<Scalars['Date']>;
  files: Array<File>;
  flow_config?: Maybe<Scalars['JSON']>;
  groupflow?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  identifier: Scalars['String'];
  is_public?: Maybe<Scalars['Boolean']>;
  keyvisual?: Maybe<File>;
  languagecode?: Maybe<Scalars['String']>;
  modules: Array<Module>;
  owner?: Maybe<User>;
  periodic?: Maybe<Scalars['Boolean']>;
  properties?: Maybe<Scalars['Properties']>;
  remember_periodic_at?: Maybe<Scalars['Date']>;
  shareable?: Maybe<Scalars['Boolean']>;
  shared_with: Array<Domain>;
  starts_at?: Maybe<Scalars['Date']>;
  status: Scalars['String'];
  sticky?: Maybe<Scalars['Boolean']>;
  tags: Array<Tag>;
  texts: Array<Text>;
  type: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
  user_count?: Maybe<Scalars['Int']>;
  users: Array<User>;
  version?: Maybe<Scalars['String']>;
  waves: Array<Wave>;
};

export type RunBasic = {
  __typename?: 'RunBasic';
  ends_at?: Maybe<Scalars['Date']>;
  files: Array<File>;
  id: Scalars['ID'];
  identifier: Scalars['String'];
  keyvisual?: Maybe<File>;
  languagecode?: Maybe<Scalars['String']>;
  properties?: Maybe<Scalars['Properties']>;
  starts_at?: Maybe<Scalars['Date']>;
  texts: Array<Text>;
  type: Scalars['String'];
  version?: Maybe<Scalars['String']>;
  waves: Array<Wave>;
};

export type RunDashboard = {
  __typename?: 'RunDashboard';
  configuration?: Maybe<RunDashboardConfiguration>;
  run?: Maybe<RunRestricted>;
  status: Scalars['String'];
};

export type RunDashboardConfiguration = {
  __typename?: 'RunDashboardConfiguration';
  alerts: Array<Maybe<Scalars['String']>>;
  modules: Scalars['String'];
  show_registration: Scalars['Boolean'];
  status: Scalars['String'];
  user: RunDashboardConfigurationUser;
};

export type RunDashboardConfigurationUser = {
  __typename?: 'RunDashboardConfigurationUser';
  progress?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  wave?: Maybe<Wave>;
};

export type RunEvaluation = {
  __typename?: 'RunEvaluation';
  created_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  identifier: Scalars['String'];
  is_public?: Maybe<Scalars['Boolean']>;
  properties?: Maybe<Scalars['Properties']>;
  run?: Maybe<Run>;
  target: Scalars['String'];
  template: Scalars['String'];
  texts: Array<Text>;
  trackingVisualizations: Array<TrackingVisualization>;
  type: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
};

/** A paginated list of RunEvaluation items. */
export type RunEvaluationPaginator = {
  __typename?: 'RunEvaluationPaginator';
  /** A list of RunEvaluation items. */
  data: Array<RunEvaluation>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** A paginated list of Run items. */
export type RunPaginator = {
  __typename?: 'RunPaginator';
  /** A list of Run items. */
  data: Array<Run>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type RunRestricted = {
  __typename?: 'RunRestricted';
  active?: Maybe<Scalars['Boolean']>;
  checkin_type: Scalars['String'];
  created_at?: Maybe<Scalars['DateTime']>;
  dedicated_waves?: Maybe<Scalars['Boolean']>;
  ends_at?: Maybe<Scalars['Date']>;
  files: Array<File>;
  flow_config?: Maybe<Scalars['JSON']>;
  groupflow?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  identifier: Scalars['String'];
  is_public?: Maybe<Scalars['Boolean']>;
  keyvisual?: Maybe<File>;
  languagecode?: Maybe<Scalars['String']>;
  modules: Array<Module>;
  owner?: Maybe<User>;
  properties?: Maybe<Scalars['Properties']>;
  shareable?: Maybe<Scalars['Boolean']>;
  starts_at?: Maybe<Scalars['Date']>;
  status: Scalars['String'];
  sticky?: Maybe<Scalars['Boolean']>;
  tags: Array<Tag>;
  texts: Array<Text>;
  type: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
  version?: Maybe<Scalars['String']>;
  waves: Array<Wave>;
};

export type RunUserPivot = {
  __typename?: 'RunUserPivot';
  completed_at?: Maybe<Scalars['DateTime']>;
  group_id?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  progress?: Maybe<Scalars['Int']>;
  run?: Maybe<Run>;
  run_id: Scalars['ID'];
  score?: Maybe<Scalars['Int']>;
  started_at?: Maybe<Scalars['DateTime']>;
  status: Scalars['String'];
  user?: Maybe<User>;
  user_id: Scalars['ID'];
  wave_id?: Maybe<Scalars['ID']>;
};

/** A paginated list of RunUserPivot items. */
export type RunUserPivotPaginator = {
  __typename?: 'RunUserPivotPaginator';
  /** A list of RunUserPivot items. */
  data: Array<RunUserPivot>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** The available SQL operators that are used to filter query results. */
export enum SqlOperator {
  /** Whether a value is within a range of values (`BETWEEN`) */
  Between = 'BETWEEN',
  /** Equal operator (`=`) */
  Eq = 'EQ',
  /** Greater than operator (`>`) */
  Gt = 'GT',
  /** Greater than or equal operator (`>=`) */
  Gte = 'GTE',
  /** Whether a value is within a set of values (`IN`) */
  In = 'IN',
  /** Whether a value is not null (`IS NOT NULL`) */
  IsNotNull = 'IS_NOT_NULL',
  /** Whether a value is null (`IS NULL`) */
  IsNull = 'IS_NULL',
  /** Simple pattern matching (`LIKE`) */
  Like = 'LIKE',
  /** Less than operator (`<`) */
  Lt = 'LT',
  /** Less than or equal operator (`<=`) */
  Lte = 'LTE',
  /** Not equal operator (`!=`) */
  Neq = 'NEQ',
  /** Whether a value is not within a range of values (`NOT BETWEEN`) */
  NotBetween = 'NOT_BETWEEN',
  /** Whether a value is not within a set of values (`NOT IN`) */
  NotIn = 'NOT_IN',
  /** Negation of simple pattern matching (`NOT LIKE`) */
  NotLike = 'NOT_LIKE'
}

export type SearchInput = {
  columns?: Maybe<Array<Maybe<Scalars['String']>>>;
  query?: Maybe<Scalars['String']>;
};

export type SendNotificationInput = {
  run_id: Scalars['ID'];
  texts: NotificationTextsInput;
  to_distinct?: Maybe<Array<Scalars['ID']>>;
  to_status?: Maybe<Scalars['String']>;
};

export type Session = {
  __typename?: 'Session';
  created_at?: Maybe<Scalars['DateTime']>;
  data?: Maybe<Scalars['JSON']>;
  languagecode?: Maybe<Scalars['String']>;
  metadata: Array<TrackingMetadata>;
  module?: Maybe<Module>;
  run?: Maybe<Run>;
  status?: Maybe<Scalars['String']>;
  team?: Maybe<Scalars['Int']>;
  token: Scalars['String'];
  tracking_status?: Maybe<SessionTrackingStatus>;
  trackings: Array<TrackingData>;
  updated_at?: Maybe<Scalars['DateTime']>;
  users: Array<User>;
};

/** A paginated list of Session items. */
export type SessionPaginator = {
  __typename?: 'SessionPaginator';
  /** A list of Session items. */
  data: Array<Session>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type SessionTrackingInput = {
  data?: Maybe<Scalars['JSON']>;
  files?: Maybe<CreateFilesRelation>;
  key?: Maybe<Scalars['String']>;
  objective: Scalars['String'];
  progress?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['JSON']>;
  score?: Maybe<Scalars['Float']>;
  site?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  texts?: Maybe<CreateTextsRelation>;
  type: Scalars['String'];
};

export type SessionTrackingStatus = {
  __typename?: 'SessionTrackingStatus';
  bookmark?: Maybe<Scalars['String']>;
  completed_at?: Maybe<Scalars['DateTime']>;
  created_at?: Maybe<Scalars['DateTime']>;
  data?: Maybe<Scalars['JSON']>;
  objectives?: Maybe<Scalars['JSON']>;
  progress?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Float']>;
  session?: Maybe<Session>;
  started_at?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type SessionTrackingStatusInput = {
  bookmark?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['JSON']>;
  objectives?: Maybe<Scalars['JSON']>;
  progress?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
};

export type SetAnonUsernameInput = {
  old_username?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  username: Scalars['String'];
};

export type Shareable = {
  __typename?: 'Shareable';
  id: Scalars['ID'];
  identifier?: Maybe<Scalars['String']>;
};

/** Information about pagination using a simple paginator. */
export type SimplePaginatorInfo = {
  __typename?: 'SimplePaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Number of items per page. */
  perPage: Scalars['Int'];
};

/** Directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

export type StartSessionInput = {
  data?: Maybe<Scalars['JSON']>;
  languagecode?: Maybe<Scalars['String']>;
  module_id: Scalars['ID'];
  run_id: Scalars['ID'];
};

export type Status = {
  __typename?: 'Status';
  debug?: Maybe<Scalars['Boolean']>;
  environment?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  packages?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  token_expired?: Maybe<Scalars['Boolean']>;
  user?: Maybe<User>;
  version?: Maybe<Scalars['String']>;
};

export type StoreSessionTrackingInput = {
  data?: Maybe<Scalars['JSON']>;
  token: Scalars['String'];
  tracking_status?: Maybe<SessionTrackingStatusInput>;
  trackings?: Maybe<Array<Maybe<SessionTrackingInput>>>;
};

export type StoreThemeInput = {
  active?: Maybe<Scalars['Boolean']>;
  app_bar_logo_id?: Maybe<Scalars['ID']>;
  app_modules?: Maybe<Array<AppModuleThemeInput>>;
  colors?: Maybe<ThemeColorsInput>;
  dark: Scalars['Boolean'];
  dashboard_image_id?: Maybe<Scalars['ID']>;
  domain_id: Scalars['ID'];
  favicon_id?: Maybe<Scalars['ID']>;
  font_headings_id?: Maybe<Scalars['ID']>;
  font_id?: Maybe<Scalars['ID']>;
  logo_id: Scalars['ID'];
  preferred: Scalars['Boolean'];
  variables: ThemeVariablesInput;
  welcome_image_id?: Maybe<Scalars['ID']>;
};

export type SubscribeDomainInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  payment_type: Scalars['String'];
  plans?: Maybe<Array<Scalars['String']>>;
};

export type Subscription = {
  __typename?: 'Subscription';
  contentTreeUpdated?: Maybe<Scalars['ContentTree']>;
  runUpdated?: Maybe<RunRestricted>;
  sessionRevoked?: Maybe<Session>;
  sessionStarted?: Maybe<Session>;
  sessionTrackingStored?: Maybe<Session>;
  sessionUpdated?: Maybe<Session>;
  /** Triggers when user is updated and only is broadcasted for logged in user */
  userUpdated?: Maybe<User>;
};


export type SubscriptionContentTreeUpdatedArgs = {
  module_id: Scalars['ID'];
  user_id?: Maybe<Scalars['ID']>;
};


export type SubscriptionRunUpdatedArgs = {
  run_id: Scalars['ID'];
};


export type SubscriptionSessionRevokedArgs = {
  module_id?: Maybe<Scalars['ID']>;
  run_id: Scalars['ID'];
};


export type SubscriptionSessionStartedArgs = {
  module_id?: Maybe<Scalars['ID']>;
  run_id: Scalars['ID'];
};


export type SubscriptionSessionTrackingStoredArgs = {
  module_id?: Maybe<Scalars['ID']>;
  run_id: Scalars['ID'];
};


export type SubscriptionSessionUpdatedArgs = {
  module_id?: Maybe<Scalars['ID']>;
  run_id: Scalars['ID'];
};


export type SubscriptionUserUpdatedArgs = {
  user_id: Scalars['ID'];
};

export type Tag = {
  __typename?: 'Tag';
  category?: Maybe<TagCategory>;
  created_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  identifier: Scalars['String'];
  texts: Array<Text>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type TagCategory = {
  __typename?: 'TagCategory';
  created_at?: Maybe<Scalars['DateTime']>;
  domain?: Maybe<Domain>;
  grants_access?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  identifier: Scalars['String'];
  readonly?: Maybe<Scalars['Boolean']>;
  tags: Array<Tag>;
  texts: Array<Text>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

/** A paginated list of TagCategory items. */
export type TagCategoryPaginator = {
  __typename?: 'TagCategoryPaginator';
  /** A list of TagCategory items. */
  data: Array<TagCategory>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** A paginated list of Tag items. */
export type TagPaginator = {
  __typename?: 'TagPaginator';
  /** A list of Tag items. */
  data: Array<Tag>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};


export type Text = {
  __typename?: 'Text';
  created_at?: Maybe<Scalars['DateTime']>;
  head?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  identifier: Scalars['String'];
  languagecode?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
};

/**
 * Domain namespace based theme.
 * One domain can have up to 2 themes (dark/light)
 */
export type Theme = {
  __typename?: 'Theme';
  active?: Maybe<Scalars['Boolean']>;
  /**
   * File relation to customers app-bar logo
   * Implementation should fall back to **logo** if no value is set
   */
  app_bar_logo?: Maybe<File>;
  /** App module images */
  app_modules: Array<AppModuleTheme>;
  colors: ThemeColors;
  /** Indicates if theme is dark (true) or light (false) */
  dark?: Maybe<Scalars['Boolean']>;
  /** File relation to customers dashboard background image */
  dashboard_image?: Maybe<File>;
  domain: Domain;
  /** File relation to customers favicon */
  favicon?: Maybe<File>;
  /** File relation to customers font */
  font?: Maybe<File>;
  /**
   * File relation to customers font for headings
   * Implementation should fall back to **font** if no value is set
   */
  font_headings?: Maybe<File>;
  id: Scalars['ID'];
  /** File relation to customers logo */
  logo: File;
  /** Indicates the preferred theme (dark/light) */
  preferred?: Maybe<Scalars['Boolean']>;
  /** Custom SCSS Variables */
  variables: ThemeVariables;
  /** File relation to customers welcome image */
  welcome_image?: Maybe<File>;
};

export type ThemeColors = {
  __typename?: 'ThemeColors';
  accent?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  info?: Maybe<Scalars['String']>;
  primary?: Maybe<Scalars['String']>;
  secondary?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['String']>;
  warning?: Maybe<Scalars['String']>;
};

export type ThemeColorsInput = {
  accent: Scalars['String'];
  error: Scalars['String'];
  info: Scalars['String'];
  primary: Scalars['String'];
  secondary: Scalars['String'];
  success: Scalars['String'];
  warning: Scalars['String'];
};

export type ThemeVariables = {
  __typename?: 'ThemeVariables';
  background?: Maybe<Scalars['String']>;
  sheetBackground?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type ThemeVariablesInput = {
  background: Scalars['String'];
  sheetBackground?: Maybe<Scalars['String']>;
  text: Scalars['String'];
};

export type TrackingData = {
  __typename?: 'TrackingData';
  created_at?: Maybe<Scalars['DateTime']>;
  data?: Maybe<Scalars['JSON']>;
  files: Array<File>;
  id: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  objective: Scalars['String'];
  properties?: Maybe<Scalars['JSON']>;
  score?: Maybe<Scalars['Float']>;
  session?: Maybe<Session>;
  session_token: Scalars['String'];
  site?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  texts: Array<Text>;
  type: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type TrackingDataCollection = {
  __typename?: 'TrackingDataCollection';
  key: Scalars['String'];
  module_id: Scalars['ID'];
  objective: Scalars['String'];
  properties: Scalars['JSON'];
  run_id: Scalars['ID'];
  site: Scalars['String'];
  tracking_data: Array<TrackingData>;
};

export type TrackingMetadata = {
  __typename?: 'TrackingMetadata';
  created_at?: Maybe<Scalars['DateTime']>;
  key?: Maybe<Scalars['String']>;
  module?: Maybe<Module>;
  properties?: Maybe<Scalars['Properties']>;
  run?: Maybe<Run>;
  updated_at?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
};

export type TrackingQueryInput = {
  key: Scalars['String'];
  module_id: Scalars['ID'];
  objective: Scalars['String'];
  run_id: Scalars['ID'];
  site: Scalars['String'];
};

export type TrackingVisualization = {
  __typename?: 'TrackingVisualization';
  created_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  identifier: Scalars['String'];
  items: Array<TrackingVisualizationItem>;
  properties?: Maybe<Scalars['Properties']>;
  texts: Array<Text>;
  type: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type TrackingVisualizationItem = {
  __typename?: 'TrackingVisualizationItem';
  component_tag: Scalars['String'];
  content?: Maybe<Content>;
  content_headline?: Maybe<Content>;
  created_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  key: Scalars['String'];
  module?: Maybe<Module>;
  objective: Scalars['String'];
  properties?: Maybe<Scalars['Properties']>;
  run?: Maybe<Run>;
  site: Scalars['String'];
  texts: Array<Text>;
  tracking_visualizations?: Maybe<TrackingVisualization>;
  trackings: Array<TrackingData>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

/** A paginated list of TrackingVisualizationItem items. */
export type TrackingVisualizationItemPaginator = {
  __typename?: 'TrackingVisualizationItemPaginator';
  /** A list of TrackingVisualizationItem items. */
  data: Array<TrackingVisualizationItem>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** A paginated list of TrackingVisualization items. */
export type TrackingVisualizationPaginator = {
  __typename?: 'TrackingVisualizationPaginator';
  /** A list of TrackingVisualization items. */
  data: Array<TrackingVisualization>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}

export type TwoFactorChallengeInput = {
  code: Scalars['String'];
};

export type TwoFactorInitializeResponse = {
  __typename?: 'TwoFactorInitializeResponse';
  secret: Scalars['String'];
  url: Scalars['String'];
};

export type TwoFactorPayload = {
  __typename?: 'TwoFactorPayload';
  two_factor_token: Scalars['String'];
  user?: Maybe<User>;
};

export type UnsubscribeDomainInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateContentInput = {
  data?: Maybe<Scalars['String']>;
  files?: Maybe<CreateFilesRelation>;
  id: Scalars['ID'];
  module?: Maybe<CreateModuleRelation>;
  parent?: Maybe<CreateParentRelation>;
  properties?: Maybe<Scalars['String']>;
  texts?: Maybe<CreateTextsRelation>;
  type?: Maybe<Scalars['String']>;
};

export type UpdateContentPresetInput = {
  created_at?: Maybe<Scalars['DateTime']>;
  data?: Maybe<Scalars['String']>;
  domain_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  module_id?: Maybe<Scalars['ID']>;
  properties?: Maybe<Scalars['String']>;
  tags?: Maybe<CreateTagsRelation>;
  texts?: Maybe<CreateTextsRelation>;
  thumb_desktop?: Maybe<Scalars['String']>;
  thumb_mobile?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type UpdateContentTreeInput = {
  children?: Maybe<Array<UpdateContentTreeInput>>;
  data?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  deletionId?: Maybe<Scalars['ID']>;
  files?: Maybe<CreateFilesRelation>;
  id?: Maybe<Scalars['ID']>;
  properties?: Maybe<Scalars['String']>;
  texts?: Maybe<CreateTextsRelation>;
  type?: Maybe<Scalars['String']>;
};

export type UpdateDomainInput = {
  deleted_at?: Maybe<Scalars['DateTime']>;
  features?: Maybe<UpdateFeaturesRelation>;
  id: Scalars['ID'];
  identifier?: Maybe<Scalars['String']>;
  markets?: Maybe<Scalars['Boolean']>;
  namespace?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['ID']>;
  payment_status?: Maybe<Scalars['String']>;
  payment_type?: Maybe<Scalars['String']>;
  plan?: Maybe<CreatePlanRelation>;
  plan_applied_at?: Maybe<Scalars['Date']>;
  properties?: Maybe<Scalars['Properties']>;
  shared_resources?: Maybe<CreateSharedResourceRelation>;
  tags?: Maybe<CreateTagsRelation>;
  texts?: Maybe<CreateTextsRelation>;
  trial_ends_at?: Maybe<Scalars['DateTime']>;
  user_limit?: Maybe<Scalars['Int']>;
  users?: Maybe<CreateUsersRelation>;
};

export type UpdateFeatureInput = {
  active?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  properties?: Maybe<Scalars['Properties']>;
  texts?: Maybe<CreateTextsRelation>;
};

export type UpdateFeaturesRelation = {
  sync?: Maybe<Array<Scalars['ID']>>;
};

export type UpdateFileInput = {
  id: Scalars['ID'];
  languagecode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  properties?: Maybe<Scalars['Properties']>;
};

export type UpdateGroupInput = {
  id: Scalars['ID'];
  identifier?: Maybe<Scalars['String']>;
  participant_count?: Maybe<Scalars['Int']>;
  participant_max?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Properties']>;
  run?: Maybe<CreateRunRelation>;
  texts?: Maybe<CreateTextsRelation>;
};

export type UpdateLearningPathInput = {
  domain?: Maybe<CreateDomainRelation>;
  files?: Maybe<CreateFilesRelation>;
  id: Scalars['ID'];
  identifier?: Maybe<Scalars['String']>;
  languagecode?: Maybe<Scalars['String']>;
  mandatory_level?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Properties']>;
  runs?: Maybe<CreateRunsRelation>;
  tags?: Maybe<CreateTagsRelation>;
  texts?: Maybe<CreateTextsRelation>;
};

export type UpdateMeInput = {
  department?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  languagecode?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  password_confirmation?: Maybe<Scalars['String']>;
  password_new?: Maybe<Scalars['String']>;
  password_old?: Maybe<Scalars['String']>;
  settings?: Maybe<UpdateUserSettingsRelation>;
  username?: Maybe<Scalars['String']>;
};

export type UpdateModuleFileInput = {
  files?: Maybe<CreateFilesRelation>;
  id: Scalars['ID'];
  master_file_id?: Maybe<Scalars['ID']>;
  properties?: Maybe<Scalars['Properties']>;
  sorting?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
};

export type UpdateModuleInput = {
  activates_at?: Maybe<Scalars['DateTime']>;
  active?: Maybe<Scalars['Boolean']>;
  domain?: Maybe<CreateDomainRelation>;
  downloads?: Maybe<CreateModuleFilesRelation>;
  duration?: Maybe<Scalars['Int']>;
  expires_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  identifier?: Maybe<Scalars['String']>;
  keyvisual_id?: Maybe<Scalars['ID']>;
  languagecode?: Maybe<Scalars['String']>;
  links?: Maybe<CreateModuleLinksRelation>;
  packages?: Maybe<CreateModulePackagesRelation>;
  properties?: Maybe<Scalars['Properties']>;
  sorting?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  sticky?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<CreateTagsRelation>;
  texts?: Maybe<CreateTextsRelation>;
  tracking_type?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type UpdateModuleLinkInput = {
  id: Scalars['ID'];
  is_master?: Maybe<Scalars['Boolean']>;
  languagecode?: Maybe<Scalars['String']>;
  properties?: Maybe<Scalars['Properties']>;
  sorting?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  url: Scalars['String'];
};

export type UpdateModulePackageInput = {
  id: Scalars['ID'];
  is_master?: Maybe<Scalars['Boolean']>;
  languagecode?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  properties?: Maybe<Scalars['Properties']>;
  sorting?: Maybe<Scalars['Int']>;
  starter_file?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type UpdatePermissionInput = {
  ability?: Maybe<Scalars['String']>;
  feature?: Maybe<CreateFeatureRelation>;
  id: Scalars['ID'];
  properties?: Maybe<Scalars['Properties']>;
  roles?: Maybe<CreateRolesRelation>;
  texts?: Maybe<CreateTextsRelation>;
  type?: Maybe<Scalars['String']>;
};

export type UpdatePlanInput = {
  admin_quota?: Maybe<Scalars['Int']>;
  course_quota?: Maybe<Scalars['Int']>;
  creator_quota?: Maybe<Scalars['Int']>;
  feature_set?: Maybe<Array<Scalars['String']>>;
  head?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  identifier?: Maybe<Scalars['String']>;
  participant_quota?: Maybe<Scalars['Int']>;
  payment_interval?: Maybe<Scalars['Int']>;
  run_participant_quota?: Maybe<Scalars['Int']>;
  run_quota?: Maybe<Scalars['Int']>;
  sorting?: Maybe<Scalars['Int']>;
  storage_quota?: Maybe<Scalars['Int']>;
  texts?: Maybe<CreateTextsRelation>;
};

export type UpdateRoleInput = {
  domain?: Maybe<CreateDomainRelation>;
  id: Scalars['ID'];
  identifier?: Maybe<Scalars['String']>;
  parent?: Maybe<CreateRoleRelation>;
  permissions?: Maybe<CreatePermissionsRelation>;
  properties?: Maybe<Scalars['Properties']>;
  texts?: Maybe<CreateTextsRelation>;
  users?: Maybe<CreateUsersRelation>;
};

export type UpdateRunEvaluationInput = {
  id: Scalars['ID'];
  identifier?: Maybe<Scalars['String']>;
  is_public?: Maybe<Scalars['Boolean']>;
  properties?: Maybe<Scalars['Properties']>;
  run?: Maybe<CreateRunRelation>;
  target?: Maybe<Scalars['String']>;
  template?: Maybe<Scalars['String']>;
  texts?: Maybe<CreateTextsRelation>;
  trackingVisualizations?: Maybe<CreateTrackingVisualizationsRelation>;
  type?: Maybe<Scalars['String']>;
};

export type UpdateRunInput = {
  active?: Maybe<Scalars['Boolean']>;
  checkin_type?: Maybe<Scalars['String']>;
  dedicated_waves?: Maybe<Scalars['Boolean']>;
  domain?: Maybe<CreateDomainRelation>;
  files?: Maybe<CreateFilesRelation>;
  flow_config?: Maybe<Scalars['JSON']>;
  groupflow?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  identifier?: Maybe<Scalars['String']>;
  is_public?: Maybe<Scalars['Boolean']>;
  keyvisual_id?: Maybe<Scalars['ID']>;
  languagecode?: Maybe<Scalars['String']>;
  modules?: Maybe<CreateModulesRelation>;
  periodic?: Maybe<Scalars['Boolean']>;
  properties?: Maybe<Scalars['Properties']>;
  remember_periodic_at?: Maybe<Scalars['Date']>;
  shareable?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  sticky?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<CreateTagsRelation>;
  texts?: Maybe<CreateTextsRelation>;
  type?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type UpdateSessionInput = {
  data?: Maybe<Scalars['JSON']>;
  languagecode?: Maybe<Scalars['String']>;
  token: Scalars['String'];
  users?: Maybe<Array<Maybe<CreateUsersRelation>>>;
};

export type UpdateTagCategoryInput = {
  domain?: Maybe<CreateDomainRelation>;
  grants_access?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  identifier?: Maybe<Scalars['String']>;
  readonly?: Maybe<Scalars['Boolean']>;
  texts?: Maybe<CreateTextsRelation>;
};

export type UpdateTagInput = {
  category?: Maybe<CreateCategoryRelation>;
  id: Scalars['ID'];
  identifier?: Maybe<Scalars['String']>;
  texts?: Maybe<CreateTextsRelation>;
};

export type UpdateTrackingVisualizationInput = {
  id: Scalars['ID'];
  identifier?: Maybe<Scalars['String']>;
  items?: Maybe<UpdateTrackingVisualizationItemRelation>;
  properties?: Maybe<Scalars['Properties']>;
  texts?: Maybe<CreateTextsRelation>;
  type?: Maybe<Scalars['String']>;
};

export type UpdateTrackingVisualizationItemInput = {
  component_tag?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  module?: Maybe<CreateModuleRelation>;
  objective?: Maybe<Scalars['String']>;
  properties?: Maybe<Scalars['Properties']>;
  run?: Maybe<CreateRunRelation>;
  site?: Maybe<Scalars['String']>;
  texts?: Maybe<CreateTextsRelation>;
  tracking_visualizations?: Maybe<CreateTrackingVisualizationRelation>;
};

export type UpdateTrackingVisualizationItemRelation = {
  connect?: Maybe<Array<Scalars['ID']>>;
  create?: Maybe<Array<Maybe<NewTrackingVisualizationItemInput>>>;
  sync?: Maybe<Array<Scalars['ID']>>;
};

export type UpdateUserInput = {
  active?: Maybe<Scalars['Boolean']>;
  department?: Maybe<Scalars['String']>;
  domains?: Maybe<CreateUserDomainsRelation>;
  email?: Maybe<Scalars['String']>;
  expires_at?: Maybe<Scalars['DateTime']>;
  firstname?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  is_super?: Maybe<Scalars['Boolean']>;
  languagecode?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  properties?: Maybe<Scalars['Properties']>;
  roles?: Maybe<CreateRolesRelation>;
  settings?: Maybe<CreateUserSettingsRelation>;
  tags?: Maybe<CreateTagsRelation>;
  tfa_enabled?: Maybe<Scalars['Boolean']>;
  username?: Maybe<Scalars['String']>;
};

export type UpdateUserSettingsRelation = {
  create_or_update?: Maybe<Scalars['JSON']>;
};

export type UpdateWaveInput = {
  ends_at?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  identifier?: Maybe<Scalars['String']>;
  participant_count?: Maybe<Scalars['Int']>;
  participant_max?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Properties']>;
  run?: Maybe<CreateRunRelation>;
  starts_at?: Maybe<Scalars['Date']>;
  texts?: Maybe<CreateTextsRelation>;
};


export type UploadAssetInput = {
  languagecode?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  properties?: Maybe<Scalars['Properties']>;
  scope?: Maybe<Scalars['String']>;
  selected_file?: Maybe<Scalars['Upload']>;
};

export type UploadFileInput = {
  check_for_doublets?: Maybe<Scalars['Boolean']>;
  languagecode?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  no_database_store?: Maybe<Scalars['Boolean']>;
  properties?: Maybe<Scalars['Properties']>;
  selected_file?: Maybe<Scalars['Upload']>;
  target_type: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  active?: Maybe<Scalars['Boolean']>;
  active_domain?: Maybe<Domain>;
  created_at?: Maybe<Scalars['DateTime']>;
  department?: Maybe<Scalars['String']>;
  domains: Array<Domain>;
  email?: Maybe<Scalars['String']>;
  email_verified_at?: Maybe<Scalars['DateTime']>;
  expires_at?: Maybe<Scalars['DateTime']>;
  firstname: Scalars['String'];
  id: Scalars['ID'];
  invitation_sent?: Maybe<Scalars['DateTime']>;
  is_super?: Maybe<Scalars['Boolean']>;
  languagecode?: Maybe<Scalars['String']>;
  last_login?: Maybe<Scalars['DateTime']>;
  lastname: Scalars['String'];
  properties?: Maybe<Scalars['Properties']>;
  roles: Array<Role>;
  run_evaluation?: Maybe<RunUserPivot>;
  sessions: Array<Session>;
  settings?: Maybe<Scalars['UserSettings']>;
  tags: Array<Tag>;
  tfa_enabled?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type UserExists = {
  __typename?: 'UserExists';
  exists: Scalars['Boolean'];
  status?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

/** A paginated list of User items. */
export type UserPaginator = {
  __typename?: 'UserPaginator';
  /** A list of User items. */
  data: Array<User>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type UserSetting = {
  __typename?: 'UserSetting';
  expires_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  key: Scalars['String'];
  properties?: Maybe<Scalars['Properties']>;
  res_id?: Maybe<Scalars['ID']>;
  res_type?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};


export type UserTrackingHistory = {
  __typename?: 'UserTrackingHistory';
  modules?: Maybe<Array<Maybe<SessionTrackingStatus>>>;
  overall?: Maybe<RunUserPivot>;
  run: Run;
};

export type Wave = {
  __typename?: 'Wave';
  created_at?: Maybe<Scalars['DateTime']>;
  ends_at: Scalars['Date'];
  id: Scalars['ID'];
  identifier: Scalars['String'];
  participant_count: Scalars['Int'];
  participant_max: Scalars['Int'];
  properties?: Maybe<Scalars['Properties']>;
  run?: Maybe<Run>;
  starts_at: Scalars['Date'];
  texts: Array<Text>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

/** A paginated list of Wave items. */
export type WavePaginator = {
  __typename?: 'WavePaginator';
  /** A list of Wave items. */
  data: Array<Wave>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** Dynamic WHERE conditions for queries. */
export type WhereConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: Maybe<Array<WhereConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: Maybe<WhereConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: Maybe<Array<WhereConditions>>;
  /** The column that is used for the condition. */
  column?: Maybe<Scalars['String']>;
  /** The operator that is used for the condition. */
  operator?: Maybe<SqlOperator>;
  /** The value that is used for the condition. */
  value?: Maybe<Scalars['Mixed']>;
};

/** Dynamic HAS conditions for WHERE condition queries. */
export type WhereConditionsRelation = {
  /** The amount to test. */
  amount?: Maybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: Maybe<WhereConditions>;
  /** The comparison operator to test against the amount. */
  operator?: Maybe<SqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};


      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    