






















































import FileUploader from '@simpl/core/components/uploader/FileUploader.vue'
import mixins from 'vue-typed-mixins'
import ImportExportContent from '@simpl/core/mixins/utils/ImportExportContent'

export default mixins(
  ImportExportContent
).extend({
  name: 'ImportContentDialog',

  model: {},

  components: {
    FileUploader
  },

  props: {
    value: Boolean,
    type: String
  },

  data () {
    return {
      step: 1,
      data: '',
      fileDetails: null! as any,
      fileVerificationErrors: null as string[] | null
    }
  },

  computed: {
    show: {
      get (): boolean {
        return this.value
      },
      set (v: boolean) {
        this.$emit('input', v)
      }
    }
  },

  methods: {

    async onInput (file: File) {
      this.fileDetails = {
        name: file.name,
        size: file.size
      }
      this.data = await file.text()
    },
    async prepareImportContent () {
      const result = await this.importContent(this.type, this.data)
      this.data = ''
      this.fileDetails = null
      this.show = false
      this.$emit('success')
    }
  }
})
