

































































































































import {
  RUN_POTENTIAL_USERS,
  SEND_NOTIFICATION
} from '../graphql'
import { getTextForTemporaryUserLanguage } from '@simpl/core/utils'
import Vue from 'vue'
import { User, Wave } from '@simpl/core/types/graphql'
import { EditableRun } from '../types'

export default Vue.extend({
  name: 'RunEditNotifyParticipants',

  props: {
    run: Object as () => EditableRun
  },

  data () {
    const texts = {
      subject: this.$t('run.notifyParticipants.templates.default.subject', { eventName: this.run.name }) as string,
      introLine: this.$t('run.notifyParticipants.templates.default.introLine') as string,
      outroLine: this.$t('run.notifyParticipants.templates.default.outroLine') as string,
      actionButton: this.$t('run.notifyParticipants.templates.default.actionButton', { eventName: this.run.name }) as string
    }
    const templates = [
      {
        id: 'default',
        name: this.$t('run.notifyParticipants.templates.default.name')
      }, {
        id: 'invite',
        name: this.$t('run.notifyParticipants.templates.invite.name')
      }
    ]
    return {
      loading: false,
      waveSelection: '',
      statusSelection: '',
      texts,
      potentialsPage: 1,
      potentialsItemsPerPage: 10,
      potentialsFilterBy: [] as any[],
      potentialsCount: 1,
      potentials: [] as User[],
      sendToCount: 0 as Number | String,
      templateSelection: 'default',
      templates,
      lastNotification: null! as object,
      rules: {
        required: (value: string) => !!value || this.$t('core.global.required')
      }
    }
  },

  computed: {
    statusList (): any {
      const countAttending = this.run.users.filter((user: User) => user!.run_evaluation!.status === 'attending').length
      const countCompleted = this.run.users.filter((user: User) => user!.run_evaluation!.status === 'completed').length
      const countInvited = this.run.users.filter((user: User) => user!.run_evaluation!.status === 'invited').length
      const countRegistered = this.run.users.filter((user: User) => user!.run_evaluation!.status === 'registered').length
      const countAll = countAttending + countCompleted + countInvited + countRegistered + this.potentialsCount
      if (this.run.type === 'course') {
        return [
          { key: 'all', text: `${this.$t('run.categories.all')} (${countAll})`, count: countAll },
          { key: 'none', text: `${this.$t('run.categories.none')} (${this.potentialsCount})`, count: this.potentialsCount },
          { key: 'attending', text: `${this.$t('run.categories.attending')} (${countAttending})`, count: countAttending },
          { key: 'completed', text: `${this.$t('run.categories.completed')} (${countCompleted})`, count: countCompleted }
        ]
      } else {
        return [
          { key: 'all', text: `${this.$t('run.categories.all')} (${countAll})`, count: countAll },
          { key: 'none', text: `${this.$t('run.categories.none')} (${this.potentialsCount})`, count: this.potentialsCount },
          { key: 'invited', text: `${this.$t('run.categories.invited')} (${countInvited})`, count: countInvited },
          { key: 'registered', text: `${this.$t('run.categories.registered')} (${countRegistered})`, count: countRegistered },
          { key: 'attending', text: `${this.$t('run.categories.attending')} (${countAttending})`, count: countAttending },
          { key: 'completed', text: `${this.$t('run.categories.completed')} (${countCompleted})`, count: countCompleted }
        ]
      }
    },
    waveList (): any[] {
      if (this.run.waves.length) {
        return this.run.waves.map(this.remapWaveEntry)
      }
      return []
    },
    canSubmit (): Boolean {
      return !!this.texts.subject &&
        !!this.texts.introLine &&
        !!this.texts.actionButton &&
        !!this.statusSelection
    }
  },

  watch: {
    statusSelection (v: any): void {
      if (v.length) {
        this.sendToCount = this.statusList.filter((status: any) => status.key === v)[0].count
      } else {
        this.sendToCount = 0
      }
    },
    templateSelection (v) {
      if (v === 'last') {
        this.texts = JSON.parse(JSON.stringify(this.lastNotification))
      } else {
        this.texts = {
          subject: this.$t(`run.notifyParticipants.templates.${v}.subject`, { eventName: this.run.name }) as string,
          introLine: this.$t(`run.notifyParticipants.templates.${v}.introLine`, { eventName: this.run.name }) as string,
          outroLine: this.$t(`run.notifyParticipants.templates.${v}.outroLine`, { eventName: this.run.name }) as string,
          actionButton: this.$t(`run.notifyParticipants.templates.${v}.actionButton`, { eventName: this.run.name }) as string
        }
      }
    }
  },

  methods: {
    async sendNotification () {
      this.loading = true
      let hasErrors = false
      if (this.texts.outroLine.trim() === '') {
        this.texts.outroLine = '&nbsp;'
      }
      try {
        await this.$apollo.mutate({
          mutation: SEND_NOTIFICATION,
          variables: {
            data: {
              run_id: this.run.id,
              texts: {
                subject: this.texts.subject,
                intro_lines: [this.texts.introLine],
                outro_lines: [this.texts.outroLine],
                action_button: this.texts.actionButton
              },
              to_status: this.statusSelection
            }
          }
        })
      } catch (err) {
        this.$notification.publish('bottom', {
          message: this.$t(err.message),
          borderColor: 'error'
        })
        hasErrors = true
      }
      this.loading = false
      if (!hasErrors) {
        this.$notification.publish('bottom', {
          message: this.$t('run.notifications.messageSent'),
          borderColor: 'success'
        })
        if (!this.lastNotification) {
          this.templates.push({
            name: this.$t('run.notifyParticipants.lastNotification'),
            id: 'last'
          })
        }
        this.lastNotification = { ...this.texts }
        this.templateSelection = 'last'
        this.statusSelection = ''
        this.waveSelection = ''
      }
    },
    async sendTestNotification () {
      this.loading = true
      let hasErrors = false
      try {
        await this.$apollo.mutate({
          mutation: SEND_NOTIFICATION,
          variables: {
            data: {
              run_id: this.run.id,
              texts: {
                subject: this.texts.subject,
                intro_lines: [this.texts.introLine],
                outro_lines: [this.texts.outroLine],
                action_button: this.texts.actionButton
              },
              to_distinct: [this.$store.state.auth.user.id]
            }
          }
        })
      } catch (err) {
        this.$notification.publish('bottom', {
          message: this.$t(err.message),
          borderColor: 'error'
        })
        hasErrors = true
      }
      this.loading = false
      if (!hasErrors) {
        this.$notification.publish('bottom', {
          message: this.$t('run.notifications.messageSent'),
          borderColor: 'success'
        })
      }
    },
    remapWaveEntry (entry: Wave): any {
      let name = getTextForTemporaryUserLanguage(entry)
      if (name === 'display_name') {
        name = entry.identifier
      }
      return {
        ...entry,
        name: name,
        nameExtended: `${name} (${entry.starts_at})`
      }
    }
  },

  apollo: {
    potentials: {
      query: RUN_POTENTIAL_USERS,

      fetchPolicy: 'cache-and-network',

      variables (): object {
        return {
          run_id: this.run.id,
          filter: {},
          page: this.potentialsPage,
          first: this.potentialsItemsPerPage
        }
      },

      update (result) {
        const { total, currentPage, perPage } = result.runPotentialUsers.paginatorInfo
        this.potentialsCount = total
        this.potentialsPage = currentPage
        this.potentialsItemsPerPage = perPage

        return result.runPotentialUsers.data
      },

      loadingKey: 'loading'
    }
  }
})
