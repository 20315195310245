export type VisualizationFormat = {
  icon: string,
  key: string,
  color: string,
  iconOffset?: string,
  textColor?: string
}

export default [{
  icon: 'mdi-chart-bar',
  iconOffset: '-4px',
  key: 'graph',
  color: '#fff',
  textColor: '#333'
}, {
  icon: 'mdi-trophy-outline',
  key: 'ranking',
  color: '#64c646'
}, {
  icon: 'mdi-blur',
  key: 'wordcloud',
  color: '#00bcd4'
}] as VisualizationFormat[]
