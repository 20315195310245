var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',[_c('v-card-text',{staticClass:"flex-grow-1 overflow-y-auto"},[_c('SearchFilterBar',{staticClass:"flex-grow-0 mb-6 mr-1",attrs:{"search-value":_vm.searchValue},on:{"update:searchValue":function($event){_vm.searchValue=$event},"update:search-value":function($event){_vm.searchValue=$event}}}),_c('v-data-table',{attrs:{"loading":_vm.loading > 0,"headers":_vm.headers,"items":_vm.presentedData,"page":_vm.page,"items-per-page":_vm.itemsPerPage},on:{"update:page":function($event){_vm.page=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"current-items":_vm.setCurrentItems},scopedSlots:_vm._u([{key:"header.selected",fn:function(){return [_c('v-checkbox',{on:{"change":_vm.selectAll},model:{value:(_vm.isSelected),callback:function ($$v) {_vm.isSelected=$$v},expression:"isSelected"}})]},proxy:true},{key:"item.tag",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"white--text",attrs:{"small":"","color":_vm.getTagOfFirstItem(item) ? _vm.stringToColor(_vm.getTagOfFirstItem(item)) : 'grey'},domProps:{"innerHTML":_vm._s(item.tag)}}),(item.items.length > 1)?_c('v-chip',{staticStyle:{"margin-left":"-10px"},attrs:{"small":""}},[_vm._v(" +"+_vm._s(item.items.length - 1)+" ")]):_vm._e()]}},{key:"item.selected",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{directives:[{name:"show",rawName:"v-show",value:(_vm.getStatus(item) !== 'none'),expression:"getStatus(item) !== 'none'"}],attrs:{"input-value":"true","disabled":""}}),_c('v-checkbox',{directives:[{name:"show",rawName:"v-show",value:(_vm.getStatus(item) === 'none'),expression:"getStatus(item) === 'none'"}],attrs:{"value":item.id},model:{value:(_vm.selectedTrackingVisualizationIds),callback:function ($$v) {_vm.selectedTrackingVisualizationIds=$$v},expression:"selectedTrackingVisualizationIds"}})]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap"}},[_vm._v(" "+_vm._s(item.type)+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.created_at ? _vm.$d(item.created_at, 'short') : ''))])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.updated_at ? _vm.$d(item.updated_at, 'short') : ''))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"actions"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-3",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.remove(item.id)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('trackingVisualization.action.deleteVisualization')))])])],1)]}}])})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","outlined":"","disabled":_vm.loading > 0},on:{"click":_vm.cancelAllocations}},[_vm._v(" "+_vm._s(_vm.$t('core.action.cancel'))+" ")]),_c('v-btn',{staticClass:"mx-4",attrs:{"color":"primary","disabled":_vm.cannotAllocate},on:{"click":_vm.applyAllocations}},[_vm._v(" "+_vm._s(_vm.$t('core.action.apply'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }