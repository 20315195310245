
































































































































































import mixins from 'vue-typed-mixins'

import { LIST_PARTICIPANT_EVENTS } from '../graphql'
import RunTile from '../components/RunTile.vue'

import { getTextForUserLanguage, getTextForTemporaryUserLanguage } from '@simpl/core/utils/text'
import UserSettings from '@simpl/core/mixins/utils/UserSettings'
import { MUTATIONS } from '@simpl/auth/store/consts'
import { DataTableHeader } from '@simpl/core/types/table'
import {
  ParticipantDasboardEvents,
  Tag,
  RunDashboard,
  Query,
  RunDashboardConfigurationUser,
  Run
} from '@simpl/core/types/graphql'
import { TranslateResult } from 'vue-i18n'
import { getLocaleDateTimeFormat } from '@simpl/core/utils/date-time-format'

export type RunDashboardExtended = RunDashboard & {
  name: TranslateResult | string,
  // eslint-disable-next-line
  starts_at_date: string,
  // eslint-disable-next-line
  ends_at_date: string,
  score?: number | null,
  userData?: RunDashboardConfigurationUser
}

function remapEntry (entry: RunDashboard): RunDashboardExtended | undefined {
  const { run } = entry

  if (!run) return

  let startsAtDate = run.starts_at ? getLocaleDateTimeFormat(run.starts_at) : null
  if (entry.configuration?.user.wave) {
    startsAtDate = getLocaleDateTimeFormat(entry.configuration.user.wave.starts_at)
  }
  let endsAtDate = run.ends_at ? run.ends_at : null
  if (entry.configuration?.user.wave) {
    endsAtDate = getLocaleDateTimeFormat(entry.configuration.user.wave.ends_at)
  }

  return {
    ...run,
    configuration: entry.configuration,
    name: run.version ? `${getTextForTemporaryUserLanguage(run)} (${run.version})` : getTextForTemporaryUserLanguage(run),
    starts_at_date: startsAtDate,
    ends_at_date: endsAtDate,
    score: entry.configuration?.user && entry.configuration.user.score,
    userData: entry.configuration?.user
  } as RunDashboardExtended
}

export default mixins(UserSettings('participantEventList', [
  'sortBy',
  'sortDesc',
  'selectedView',
  'itemsPerPage',
  'temporaryLanguage'
])).extend({
  name: 'ParticipantEventList',

  components: { RunTile },

  data () {
    return {
      selectedView: 'list',
      type: 'all',
      temporaryLanguage: '',

      /** Displayed data */
      events: [],
      loading: 0,

      /** Dialog control flow */
      dialog: false,
      selected: null,

      /** Search and filter */
      searchValue: '',
      sortBy: [],
      sortDesc: false,
      count: 1,
      page: 1,
      itemsPerPage: 10
    }
  },

  computed: {
    headers (): DataTableHeader[] {
      return [{
        text: this.$t('core.global.name'),
        value: 'name'
      }, {
        text: this.$t('core.global.status'),
        value: 'status'
      }, {
        /* text: this.$t('core.global.checkInType'),
        value: 'checkin_type'
      }, { */
        text: this.$t('core.global.start'),
        value: 'starts_at'
      }, {
        text: this.$t('core.global.end'),
        value: 'ends_at'
      }, {
        text: '',
        value: 'actions',
        class: 'separate-left',
        width: 50,
        sortable: false
      }]
    },
    presentedData (): RunDashboardExtended[] {
      return (this.events as any)?.[this.type]?.map(remapEntry).filter(Boolean) as RunDashboardExtended[]
    },
    languageTags (): Tag[] {
      return this.$store.state.auth.user.tags
        .filter((tag: Tag) => tag.category!.identifier === 'language')
        .map((tag: Tag) => { return { ...tag, name: getTextForUserLanguage(tag) } })
    }
  },

  watch: {
    temporaryLanguage (v) {
      this.$store.commit(`auth/${MUTATIONS.SET_TEMPORARY_LANGUAGE}`, { temporaryLanguage: v })
    }
  },

  apollo: {
    events: {
      query: LIST_PARTICIPANT_EVENTS,

      fetchPolicy: 'cache-and-network',

      update (result: Query): ParticipantDasboardEvents {
        const events = { ...result.participantDashboard.events } as ParticipantDasboardEvents
        delete events.__typename

        const allEvents = Object.keys(events).reduce((acc: RunDashboard[], key: string) => {
          return [
            ...acc,
            ...((events as any)[key] || [])
          ]
        }, []).filter((evt: RunDashboard, index: number, arr: RunDashboard[]) => {
          return arr.findIndex(e => evt.run && e.run && e.run.id === evt.run.id) === index
        })

        const preparedEvents = {
          all: allEvents,
          ...events
        } as ParticipantDasboardEvents

        if ((preparedEvents as any)[this.type]?.length) {
          if (!preparedEvents.available?.length) {
            this.type = Object.keys(preparedEvents)[0] || 'attending'
          } else {
            this.type = 'all'
          }
        }

        return preparedEvents
      },

      error (error) {
        console.error(error)
      },

      loadingKey: 'loading'
    }
  },

  methods: {
    updateSearchValue (v: string) {
      this.searchValue = v
    },
    handleRowClick (item: Run) {
      this.$router.push(`course/${item.identifier}`)
    }
  }
})
