import getPropertyValue from './get-property-value'

export default function getOrder (array: Record<string, any>[], key: string, hasGaps: Boolean = true): number[] {
  const order = []
  const arrayLength = array.length
  let pointer = 0

  for (let index = 0; index < arrayLength; index++) {
    const currentItem = array[index]
    if (index > 0) {
      const previousItem = array[index - 1]

      const currentValue = getPropertyValue(currentItem, key) || 0
      const previousValue = getPropertyValue(previousItem, key) || 0

      if (currentValue! < previousValue!) {
        pointer = hasGaps ? index : pointer + 1
      }
    }
    order.push(pointer)
  }
  return order
}
