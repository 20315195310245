import { app } from '@simpl/core/init-app'
import { getDateTime, XLSX_MAX_TITLE_LENGTH, SECONDARY_COLOR, META_COLOR } from '@simpl/core/utils/export'
import { getTextForUserLanguage } from '@simpl/core/utils'
import { truncateIfNecessary } from '@simpl/core/utils/text'
import { Content, User } from '@simpl/core/types/graphql'
import { DropZone } from '@simpl/cms-components/types'
import { parseToTable } from '@simpl/tracking-evaluation/utils/parser'
import getOrder from '@simpl/tracking-evaluation/utils/order'
import { SHARED_WITH_GAPS, UNIQUE } from '@simpl/tracking-evaluation/views/config/defaultProperties'

export function createTable (
  items: any,
  index: number,
  properties: Record<string, any>,
  trackingVisualizationTitle: string,
  runTitle: string,
  evaluationTitle: string,
  runType: string
) {
  const content = items[0]?.content as Content

  switch (content.data?.tag) {
    case 'CDragAndDrop':
      return createDragAndDropTable(
        items,
        index,
        properties,
        content,
        trackingVisualizationTitle,
        runTitle,
        evaluationTitle,
        runType
      )

    default:
      return createDefaultTable(
        items,
        index,
        properties,
        content,
        trackingVisualizationTitle,
        runTitle,
        evaluationTitle,
        runType
      )
  }
}

export function createDefaultTable (
  items: any,
  index: number,
  properties: Record<string, any>,
  content: Content,
  trackingVisualizationTitle: string,
  runTitle: string,
  evaluationTitle: string,
  runType: string
) {
  const sheets = parseToTable(items, content, properties?.categories)
  if (sheets.length) {
    const firstSheet = sheets[0]
    return {
      title: getSheetTitle(trackingVisualizationTitle, index),
      headline: trackingVisualizationTitle,
      headers: [{
        title: app.$t('trackingVisualization.settings.category')
      }, {
        title: app.$t('trackingEvaluation.export.count'),
        alignment: { horizontal: 'right' }
      }, {
        title: app.$t('trackingEvaluation.export.percent'),
        alignment: { horizontal: 'right' },
        numFmt: '0 %'
      }],
      data: firstSheet.sheet,
      // prevents %NAME error in sum when there is only one value
      ...(firstSheet.sheet?.length > 1 && {
        results: [
          {
            text: app.$t('trackingEvaluation.export.total')
          },
          {
            alignment: { horizontal: 'right' },
            formula: 'sum'
          },
          {
            alignment: { horizontal: 'right' },
            formula: 'sum'
          }
        ]
      }),
      average: firstSheet.average ? createAverageData(firstSheet.average) : null,
      meta: createMetaData(runType, runTitle, evaluationTitle)
    }
  }
}

export function getDropZonesTexts (content: Content): string[] {
  const exerciseSettings = content.data.properties.exerciseSettings
  const dropZones = exerciseSettings.dropZones

  return dropZones.map((dropZone: DropZone) => getTextForUserLanguage(content, dropZone.identifier))
}
export function createDragAndDropHeaders (texts: string[]): Record<string, any>[] {
  const headers = [{ title: app.$t('trackingEvaluation.headers.dragItem') }] as Record<string, any>[]

  texts.forEach((text: string) => {
    headers.push({
      title: text
    })
  })
  return headers
}

export function createDragAndDropTable (
  items: any,
  index: number,
  properties: Record<string, any>,
  content: Content,
  trackingVisualizationTitle: string,
  runTitle: string,
  evaluationTitle: string,
  runType: string
) {
  const sheets = parseToTable(items, content, properties.categories)

  if (sheets.length) {
    const firstSheet = sheets[0]
    const texts = properties.legend ? properties.legend : getDropZonesTexts(content)
    return {
      title: getSheetTitle(trackingVisualizationTitle, index),
      headline: trackingVisualizationTitle,
      headers: createDragAndDropHeaders(texts),
      data: firstSheet.sheet,
      meta: createMetaData(runType, runTitle, evaluationTitle)
    }
  }
}

function createTitleWithoutSpecialChars (trackingVisualizationTitle:string) {
  return truncateIfNecessary(
    trackingVisualizationTitle.replace(/[&/\\#,+()$~%.'":*?<>{}]/g, ''),
    XLSX_MAX_TITLE_LENGTH
  )
}

function createAverageData (average: number): Record<string, any>[][] {
  return [
    [],
    [{
      text: app.$t('trackingVisualization.chart.average'),
      font: {
        size: 10,
        color: { argb: SECONDARY_COLOR }
      }
    }, {
      text: parseFloat(average.toFixed(2)),
      font: {
        size: 10,
        color: { argb: SECONDARY_COLOR }
      }
    }]
  ]
}

function createMetaData (runType: string, runTitle:string, evaluationTitle:string): Record<string, any>[][] {
  return [
    [],
    [{
      text: app.$t(`run.types.${runType}`),
      font: {
        size: 8,
        color: { argb: META_COLOR }
      }
    }, {
      text: runTitle,
      font: {
        size: 8,
        color: { argb: META_COLOR }
      }
    }], [{
      text: app.$t('trackingEvaluation.global.evaluation'),
      font: {
        size: 8,
        color: { argb: META_COLOR }
      }
    }, {
      text: evaluationTitle,
      font: {
        size: 8,
        color: { argb: META_COLOR }
      }
    }], [{
      text: app.$t('trackingEvaluation.export.exportDate'),
      font: {
        size: 8,
        color: { argb: META_COLOR }
      }
    }, {
      text: getDateTime(),
      font: {
        size: 8,
        color: { argb: META_COLOR }
      }
    }]
  ]
}

export function createRankingTable (
  users: User[],
  index: number,
  properties: Record<string, any>,
  trackingVisualizationTitle: string,
  runTitle: string,
  evaluationTitle: string,
  runType: string
) {
  return {
    title: getSheetTitle(trackingVisualizationTitle, index),
    headline: trackingVisualizationTitle,
    headers: parseUsersToHeaders(users, properties),
    data: parseUsersToTable(users, properties),
    meta: createMetaData(runType, runTitle, evaluationTitle)
  }
}

function getSheetTitle (title: string, index: number) {
  return `${index + 1}. ${createTitleWithoutSpecialChars(title)}`
}

function parseUsersToHeaders (users:User[], properties: Record<string, any>): Record<string, any>[] {
  let headers = [{
    key: 'rank',
    static: true,
    title: app.$t('trackingVisualization.ranking.rank')
  }, {
    key: 'firstName',
    title: app.$t('core.global.firstName')
  }, {
    key: 'lastName',
    title: app.$t('core.global.lastName')
  }, {
    key: 'username',
    title: app.$t('core.global.username')
  }, {
    key: 'score',
    title: app.$t('core.global.score'),
    alignment: { horizontal: 'right' }
  }]
  if (properties) {
    headers = headers.map(header => {
      if (!header.static && properties[header.key]) {
        return properties[header.key]
      }
      return header
    })
  }
  return headers
}

function parseUsersToTable (users:User[], properties: Record<string, any>): Record<string, any> {
  const hasGaps = properties.rankProperty ? properties.rankProperty === SHARED_WITH_GAPS : false
  const order = getOrder(users, 'run_evaluation.score', hasGaps)
  const usersData = []

  const usersLength = users.length
  for (let index = 0; index < usersLength; index++) {
    const placementText: number = properties.rankProperty === UNIQUE ? index : order[index]
    const user = users[index]

    const completeUserData = [{
      key: 'placement',
      static: true,
      text: `${placementText + 1}.`
    }, {
      key: 'firstName',
      text: user.firstname || ''
    }, {
      key: 'lastName',
      text: user.lastname || ''
    }, {
      key: 'username',
      text: user.username || ''
    }, {
      key: 'score',
      text: user.run_evaluation?.score || 0
    }]
    let userData = completeUserData

    if (properties) {
      userData = completeUserData.map(data => {
        if (!data.static && properties[data.key]) {
          data.text = properties[data.key]
        }
        return data
      })
    }
    const userText = userData.map(data => data.text)
    usersData.push(userText)
  }
  return usersData
}
