var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height content-padding"},[_c('Headline',{staticClass:"headline",attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('trackingEvaluation.global.headline'))+" ")]),_c('div',{staticClass:"d-flex mb-4",class:_vm.$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'},[_c('v-spacer'),_c('SearchFilterBar',{staticClass:"flex-grow-0 mb-6 mr-1",attrs:{"view-selection":"","search-value":_vm.searchValue,"view":_vm.selectedView},on:{"update:searchValue":function($event){_vm.searchValue=$event},"update:search-value":function($event){_vm.searchValue=$event},"update:view":function($event){_vm.selectedView=$event}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"can",rawName:"v-can",value:('evaluation-create'),expression:"'evaluation-create'"}],attrs:{"color":"primary","fab":"","small":""},on:{"click":_vm.add}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('trackingEvaluation.action.createEvaluation')))])])]},proxy:true}])})],1),_c('v-slide-x-transition',{attrs:{"mode":"out-in"}},[_c('v-data-table',{key:"table-view",attrs:{"loading":_vm.loading > 0,"headers":_vm.computedHeaders,"items":_vm.presentedData,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.count,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:page":function($event){_vm.page=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row justify-space-between align-center"},[_c('div',{staticStyle:{"overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap"}},[_vm._v(" "+_vm._s(item.name)+" ")]),(_vm.$vuetify.breakpoint.xs)?_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"dark":_vm.$vuetify.theme.dark,"icon":""}},on),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-btn',{attrs:{"icon":"","to":("/run/" + (_vm.run.id) + "/evaluation/" + (item.id) + "/visualizations")}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-television-play ")])],1)],1),_c('v-list-item',[_c('v-btn',{directives:[{name:"can",rawName:"v-can",value:('evaluation-create'),expression:"'evaluation-create'"}],attrs:{"icon":""},on:{"click":function($event){return _vm.edit(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)],1),_c('v-list-item',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.remove(item.id, item.name)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)],1)],1)],1):_vm._e()],1)]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.$t(("trackingEvaluation.evaluationTypes." + (item.type))))+" ")])]}},{key:"item.template",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.$t(("trackingEvaluation.templates." + (item.template))))+" ")])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.updated_at ? _vm.$d(item.updated_at, 'short') : ''))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"actions"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-3",attrs:{"small":"","icon":"","href":("/run/" + (_vm.run.id) + "/evaluation/" + (item.id) + "/present"),"target":"_blank","disabled":!_vm.hasTrackingVisulizations(item)}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-television-play ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('core.action.present')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-3",attrs:{"small":"","icon":"","to":("/run/" + (_vm.run.id) + "/evaluation/" + (item.id) + "/visualizations")}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chart-bar ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('trackingVisualization.action.showVisualizations')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"can",rawName:"v-can",value:('evaluation-create'),expression:"'evaluation-create'"}],staticClass:"mr-2",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.edit(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('trackingEvaluation.action.editEvaluation')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-3",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.remove(item.id, item.name)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('trackingEvaluation.action.deleteEvaluation')))])])],1)]}}])},[(_vm.selectedView !== 'list')?_c('template',{slot:"body"},[_c('tr',[_c('td',{attrs:{"colspan":_vm.computedHeaders.length}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',_vm._l((_vm.presentedData),function(module,index){return _c('v-col',{key:index,attrs:{"cols":"12","sm":"6","md":"3","lg":"3","xl":"2"}},[_c('EvaluationTile',{attrs:{"item":module},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":"","href":("/run/" + (_vm.run.id) + "/evaluation/" + (item.id) + "/present"),"target":"_blank"}},on),[_c('v-icon',{attrs:{"small":_vm.$vuetify.breakpoint.xs}},[_vm._v(" mdi-television-play ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('core.action.present')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":"","to":("/run/" + (_vm.run.id) + "/evaluation/" + (item.id) + "/visualizations")}},on),[_c('v-icon',{attrs:{"small":_vm.$vuetify.breakpoint.xs}},[_vm._v(" mdi-chart-bar ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('trackingVisualization.action.showVisualizations')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"can",rawName:"v-can",value:('evaluation-create'),expression:"'evaluation-create'"}],attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.edit(item)}}},on),[_c('v-icon',{attrs:{"small":_vm.$vuetify.breakpoint.xs}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('trackingEvaluation.action.editEvaluation')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"can",rawName:"v-can",value:('evaluation-delete'),expression:"'evaluation-delete'"}],attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.remove(item.id, item.name)}}},on),[_c('v-icon',{attrs:{"small":_vm.$vuetify.breakpoint.xs}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('trackingEvaluation.action.deleteEvaluation')))])])]}}],null,true)})],1)}),1)],1)],1)])]):_vm._e()],2)],1),_c('CreateRunEvaluationDialog',{attrs:{"preset":_vm.selected,"run-id":_vm.run.id},on:{"saved":_vm.refetchRunEvaluations},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }