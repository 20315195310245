import { getTextForTemporaryUserLanguage } from '@simpl/core/utils'
import htmlDecode from '../utils/html-decode'
import { TrackingVisualizationItem } from '@simpl/core/types/graphql'
import Vue from 'vue'

export default function parseTrackingVisualizationItem (item: TrackingVisualizationItem): Record<string, any> {
  const content = item.content
  const data = content?.data
  const tag = data.tag
  const componentConstructor = Vue.component(tag)
  const parsed = {} as Record<string, any>

  // hasOwn because of boolean that could be false
  if (Object.hasOwn(componentConstructor.options.cms?.visualization, 'distributable')) {
    parsed.distributable = componentConstructor.options.cms?.visualization?.distributable
    parsed.distributed = componentConstructor.options.cms?.visualization?.distributable
  }
  if (componentConstructor.options.cms?.visualization?.hasNumericCategories) {
    parsed.hasNumericCategories = componentConstructor.options.cms?.visualization?.hasNumericCategories
  }
  if (componentConstructor.options.cms?.visualization?.diagramTypes) {
    parsed.diagramTypes = componentConstructor.options.cms?.visualization?.diagramTypes
  }
  if (componentConstructor.options.cms?.visualization?.getTextForCategories) {
    parsed.categories = componentConstructor.options.cms?.visualization?.getTextForCategories(content)
  }
  if (componentConstructor.options.cms?.visualization?.getTextForLegend) {
    parsed.legend = componentConstructor.options.cms?.visualization?.getTextForLegend(content)
  }
  if (componentConstructor.options.cms?.visualization?.getTickAmount) {
    parsed.tickAmountData = componentConstructor.options.cms?.visualization?.getTickAmount(content)
  }

  const contentHeadline = item.content_headline
  if (contentHeadline) {
    const headlineText = getTextForTemporaryUserLanguage(contentHeadline, 'text')
    parsed.title = htmlDecode(headlineText)
  }
  // TODO: update text for solution when text of related category is changed

  return parsed
}
