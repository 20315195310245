import { findParentByTag, unwrap } from './helper'

export function applyListFormat (
  document: Document,
  range: Range,
  el: HTMLElement,
  root: HTMLElement,
  format: any
) {
  if (!range.toString().length) {
    const rowParent = findParentByTag(el, 'div')
    const rangeContainer = el.nodeName.toLowerCase() === 'div' ? el : rowParent || root

    const range = document.createRange()
    range.selectNodeContents(rangeContainer)

    applyListFormatToRange(document, range, format.tag, rangeContainer)
  } else {
    applyListFormatToRange(document, range, format.tag)
  }
}

export function removeListFormat (
  range: Range,
  el: HTMLElement,
  root: HTMLElement,
  format: any
) {
  if (!range.toString().length) {
    const rowParent = findParentByTag(el, 'div')
    const parent = rowParent || root

    removeElementFormat(parent, format.tag)
  } else {
    removeElementFormat(el, format.tag)
  }
}

function applyListFormatToRange (document: Document, range: Range, tag: string, el?: HTMLElement) {
  const newElement = document.createElement(tag)
  const listItem = document.createElement('li')
  newElement.append(listItem)

  if (el) {
    [...el.getElementsByTagName('*')].forEach((child: Element) => {
      const childTag = child.nodeName.toLowerCase()

      if (['ul', 'ol', 'li'].includes(childTag)) {
        unwrap(child)
      }
    })

    ;[...el.childNodes].forEach((node: Node) => {
      listItem.append(node)
    })
  } else {
    const rangeContent = document.createTextNode(range.toString())
    listItem.append(rangeContent)
  }

  range.deleteContents()
  range.insertNode(newElement)
}

function removeElementFormat (el: HTMLElement, tag: string) {
  if (el.nodeName.toLowerCase() === tag) {
    removeListItems(el)
    unwrap(el)
  } else {
    const parent = findParentByTag(el, tag)
    if (parent) {
      removeListItems(parent)
      unwrap(parent)
    }
  }
}

function removeListItems (el: HTMLElement) {
  const listItems = [...el.getElementsByTagName('li')]
  listItems.forEach((item: HTMLElement) => {
    unwrap(item)
  })
}
