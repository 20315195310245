import {
  findParentByTag,
  hasSelectedFormat,
  hasParentWithFormat,
  hasFormattedParents,
  hasFormattedChildren,
  unwrap, isFormatted, isTextNode,
  getNodeTextContent,
  getSelectionText,
  getUnselectedTexts, restoreSelection
} from './helper'

// add text formats

const originalSelection = {} as Record<string, any>

export function applyTextFormat (
  document: Document,
  range: Range,
  el: HTMLElement,
  format: any,
  marker: number,
  selectionLength: number
) {
  if (!range.toString() || !el) return

  if (!isFormatted(el) || (hasFormattedParents(el) && !hasFormattedChildren(el))) {
    applyFormatToRange(document, range, format.tag)
  } else {
    marker = range.startOffset
    selectionLength = range.toString().length

    editChildElements(document, range, [...el.childNodes], format, marker, selectionLength)
  }
}

export function removeTextFormat (
  document: Document,
  range: Range,
  el: HTMLElement,
  format: any,
  marker: number,
  selectionLength: number
) {
  if (!range.toString() || !el) return

  marker = range.startOffset
  selectionLength = range!.toString().length

  const parentTextLength = el.innerText.length

  if (hasParentWithFormat(el, format.tag) || hasSelectedFormat(el, format.tag)) {
    if (selectionLength < parentTextLength) {
      removeSelectionFormat(document, range, [...el.childNodes], format, marker, selectionLength)
    }
    removeElementFormat(el, format.tag)
  } else {
    removeChildElementsFormat(range, el, format)
  }
}

function applyFormatToRange (document: Document, range: Range, tag: string) {
  const rangeContent = document.createTextNode(range.toString())
  const newElement = document.createElement(tag)
  newElement.append(rangeContent)
  range.deleteContents()
  range.insertNode(newElement)
}

function editChildElements (
  document: Document,
  range: Range,
  children: any[],
  format: any,
  marker: number,
  selectionLength: number
) {
  const childrenInRange = children.filter((child: any) => range.intersectsNode(child))

  childrenInRange.forEach((child: any, index: number) => {
    if (hasFormattedChildren(child)) {
      editChildElements(document, range, [...child.childNodes], format, marker, selectionLength)
    }

    const nodeText = getNodeTextContent(child)
    const selection = getSelectionText(nodeText!, marker, selectionLength)

    const rangeContainer = isTextNode(child) ? child : child.firstChild

    if (rangeContainer.length) {
      const endOffset = rangeContainer.length - marker < selectionLength
        ? rangeContainer.length
        : selectionLength

      const range = document.createRange()
      range.setStart(rangeContainer, marker)
      range.setEnd(rangeContainer, endOffset)

      applyFormatToRange(document, range, format.tag)

      if (index === 0) {
        originalSelection.startContainer = range.startContainer
        originalSelection.startOffset = range.startOffset
      }

      if (index === childrenInRange.length - 1) {
        originalSelection.endContainer = range.endContainer
        originalSelection.endOffset = range.endOffset
      }

      marker = 0
      selectionLength = selectionLength - selection.length
    }
  })

  restoreSelection(originalSelection)
}

// remove text formats

function removeElementFormat (el: HTMLElement, tag: string) {
  if (el.nodeName.toLowerCase() === tag) {
    unwrap(el)
  } else {
    const parent = findParentByTag(el, tag)
    unwrap(parent)
  }
}

function removeSelectionFormat (
  document: Document,
  range: Range,
  children: any[],
  format: any,
  marker: number,
  selectionLength: number
) {
  const childrenInRange = children.filter((child: any) => range.intersectsNode(child))

  children.forEach((child: any, index: number) => {
    const nodeText = getNodeTextContent(child)

    let rangeContainer = isTextNode(child) ? child : child.firstChild

    if (range?.intersectsNode(child)) {
      if (hasFormattedChildren(child)) {
        removeSelectionFormat(document, range, [...child.childNodes], format, marker, selectionLength)
      }

      const selection = getSelectionText(nodeText!, marker, selectionLength)
      const parentRangeContainer = rangeContainer.parentElement!

      if (selection.length < nodeText!.length) {
        const unselectedTexts = getUnselectedTexts(nodeText!, marker, selectionLength)

        unselectedTexts.forEach((unselectedText: string) => {
          if (!rangeContainer.length) {
            const childNodes = [...parentRangeContainer.childNodes].filter((node: HTMLElement) => !!node.nodeValue)
            rangeContainer = childNodes[0]
          }

          const startOffset = marker > 0 ? 0 : selection.length
          const endOffset = marker > 0 ? unselectedText.length : rangeContainer.length

          const range = document.createRange()
          range.setStart(rangeContainer, startOffset)
          range.setEnd(rangeContainer, endOffset)

          if (index === 0) {
            originalSelection.startContainer = range.startContainer
            originalSelection.startOffset = range.startOffset
          }

          if (index === childrenInRange.length - 1) {
            originalSelection.endContainer = range.endContainer
            originalSelection.endOffset = range.endOffset
          }

          applyFormatToRange(document, range, format.tag)

          marker = 0
        })
        selectionLength = selectionLength - selection.length
      }
    } else {
      const range = document.createRange()
      range.setStart(rangeContainer, 0)
      range.setEnd(rangeContainer, rangeContainer.nodeValue.length)

      applyFormatToRange(document, range, format.tag)
    }
  })

  restoreSelection(originalSelection)
}

function removeChildElementsFormat (range: Range, el: any, format: any) {
  if (range?.intersectsNode(el)) {
    const element = !isTextNode(el) ? el : el.parentElement

    const allElements = [...element!.getElementsByTagName(format.tag)].filter((el: any) => range?.intersectsNode(el))

    allElements.forEach((el: any) => {
      unwrap(el)
    })
  }
}
