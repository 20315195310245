


















































































import Vue from 'vue'
import { Module, Run } from '@simpl/core/types/graphql'
import { getTextForUserLanguage } from '@simpl/core/utils'

export default Vue.extend({
  name: 'ShareModuleLinkDialog',

  model: {},

  props: {
    value: Boolean,
    run: Object as () => Run,
    module: Object as () => Module
  },

  data: () => ({
    apiUrl: process.env.VUE_APP_API_URL || '',
    originUrl: window.location.origin
  }),

  computed: {
    show: {
      get (): boolean {
        return this.value
      },
      set (v: boolean) {
        this.$emit('input', v)
      }
    },
    moduleTitle (): string {
      return getTextForUserLanguage(this.module)
    },
    qrCodeSrc (): string {
      return this.module.run_pivot?.properties?.code ? `${this.apiUrl}/qr/generate/code/${this.module.run_pivot.properties.code}` : ''
    }
  },

  methods: {
    openQrLink () {
      window.open(`${window.location.origin}/qr/content/${this.run.identifier}/${this.module.identifier}/${this.module!.run_pivot!.properties.code}`, '_blank')
    },

    downloadQr () {
      const code = this.module.run_pivot?.properties?.code ? this.module.run_pivot.properties.code : ''
      window.open(`${this.apiUrl}/qr/generate/code/${code}`, '_blank')
    },

    async copyQr () {
    //  TODO: TBI
    },

    openRunDashboardLink () {
      window.open(`${window.location.origin}/content/${this.run.identifier}/${this.module.identifier}`, '_blank')
    },

    copyRunDashboardLink () {
      const dashboardLinkField = document.getElementById('dashboard-link-input') as any
      if (dashboardLinkField) {
        dashboardLinkField.select()
        dashboardLinkField.setSelectionRange(0, 99999)
        document.execCommand('copy')
      }
    }
  }
})
