















































import Vue from 'vue'
import { getTextForTemporaryUserLanguage, getTextForLanguage } from '@simpl/core/utils'
import { Run } from '@simpl/core/types/graphql'
import RunDescription from '../../components/RunDescription.vue'

export default Vue.extend({
  name: 'CourseHeadline',

  components: { RunDescription },

  props: {
    run: Object as () => Run
  },

  computed: {
    contactPerson (): string {
      const contactPerson = this.run?.properties.contactPerson
      return contactPerson
        ? this.$t('core.global.fullname', [contactPerson.firstname, contactPerson.lastname]) as string
        : ''
    }
  },

  methods: {
    getTextForTemporaryUserLanguage,
    getTextForLanguage,

    openEmail () {
      window.open('mailto:' + this.run!.owner!.email)
    }
  }
})
