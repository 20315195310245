


























































import Vue from 'vue'
import { unsavedChanges } from '@simpl/core/utils/message'
import { Module, Run } from '@simpl/core/types/graphql'
import { UPDATE_MODULE } from '../../../modules/graphql'
import { UPDATE_RUN } from '../../graphql'

export default Vue.extend({
  name: 'LockModuleDialog',

  model: {},

  props: {
    value: Boolean,
    module: Object as () => Module,
    run: Object as () => Run
  },

  data: () => ({
    code: '',
    initialCode: '',
    loading: false
  }),

  computed: {
    show: {
      get (): boolean {
        return this.value
      },
      set (v: boolean) {
        this.$emit('input', v)
      }
    },
    hasChanged (): boolean {
      return this.initialCode !== this.code
    }
  },

  watch: {
    module (v) {
      this.code = v?.run_pivot?.properties?.code || ''
      this.initialCode = this.code
    }
  },

  methods: {
    async cancel () {
      if (!this.hasChanged) {
        this.show = false
        return
      }

      this.show = await unsavedChanges(this)
    },
    async save () {
      this.loading = true

      try {
        await this.$apollo.mutate({
          mutation: UPDATE_RUN,
          variables: {
            data: {
              id: this.run.id,
              modules: {
                sync: [
                  ...this.run.modules.map((module: Module) => ({ id: module.id })),
                  {
                    id: this.module.id,
                    properties: JSON.stringify({ code: this.code })
                  }
                ]
              }
            }
          }
        })
      } catch (e: any) {
        console.log(e)
      }

      this.loading = false
      this.show = false
      this.code = ''
    }
  }
})
